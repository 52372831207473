import {
    UPDATE_CALCULATOR_STATE
} from '../actions/types';

//async storage
// import AsyncStorage from '@react-native-async-storage/async-storage';

const initialState = {
    isCalculator: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case UPDATE_CALCULATOR_STATE:
            return {
                ...state,
                isCalculator: action.payload
            }
        default:
            return {
                ...state
            }
    }
}