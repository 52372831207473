const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

export const EmptyPickupState = {


  payment: [],
  totalAmount: 0,

  //check
  agreeToPay: false,

  //basic info
  gender: '',
  firstName: '',
  middleName: '',
  lastName: '',
  sin: '',
  dob: new Date(),
  address: '',
  city: '',
  postalCode: '',
  province: '',

  //contact info
  preferredContactMobile: '',
  preferredContactEmail: '',
  preferredContactMethod: '',
  bestTimeToCall: new Date(),
  alternateMobileNumber: '',
  preferredReviewMethod: '',

  //marital info
  maritalStatus: '',

  //spouse info
  fillingForSpouse: false,
  spouseInfo: {
    firstName: '',
    lastName: '',
    dob: new Date(),
    netIncome: '',
    sin: '',
    postSecondaryStudent: '',
    disabled: ''
  },

  //dependants
  //array of objects like {relation: '', firstName: '', lastName: '', dob: '', netIncome: '', postSecondaryStudent: '', disabled: ''}
  dependants: [],
  //temp dependants info
  tempDependantsInfo: {
    relation: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dob: new Date(),
    netIncome: '',
    postSecondaryStudent: '',
    disabled: ''
  },

  //questions
  questions: [{
    questions: [{
      question: 'Are you a Canadian Citizen?',
    },
      // {
      //   question: 'Do you meet the Green Card Test (Lawful Permanent Resident of U.S.)?',
      // },
      // {
      //   question: 'Were you PHYSICALLY PRESENT in U.S. at least 183 days during current year?'
      // }
    ],
    checked: ''
  },
  {
    questions: [{
      question: 'Do you have an incorporated business?'
    }],
    checked: ''
  },
  {
    questions: [{
      question: 'Do you need to complete an Estate Return or file for a deceased person\'s return?'
    }],
    checked: ''
  },
  {
    questions: [{
      question: 'Are you self-employed, did you own your own business or did you work for a placement agency?'
    }],
    checked: ''
  },
  {
    questions: [{
      question: 'Are you currently in Bankruptcy Status?'
    }],
    checked: ''
  },
  {
    questions: [{
      question: 'Did you work outside Canada or have foreign employment or foreign pension income?'
    }],
    checked: ''
  },
  {
    questions: [{
      question: 'Excluding RRSPs, do you have any investments, own any rental properties or sell your principal residence last year?'
    }],
    checked: ''
  },
  {
    questions: [{
      question: 'Are you claiming employment expenses (did your employer reimburse you for office or vehicle expenses)?'
    }],
    checked: ''
  },
  {
    questions: [{
      question: 'Did you move to Canada last year or switch provinces?'
    }],
    checked: ''
  },
  {
    questions: [{
      question: 'Are we preparing more than one return for you today?'
    }],
    checked: ''
  },
  {
    questions: [{
      question: 'Are you eligible for the disability tax Credit?'
    }],
    checked: ''
  },
  {
    questions: [{
      question: 'Have you bought a house in 2020 ?'
    }],
    checked: '',
    conditional: true
  }
  ],

  //checklist details
  spouseSlipsChecklist: [{
    title: 'T4 slips (Employment income)',
    checked: false,
    image: false,
    images: [],
    inputs: [],
    type: 'images'
  },
  {
    title: 'Employment insurance benefits (T4E)',
    checked: false,
    image: false,
    images: [],
    inputs: [],
    type: 'images'
  },
  {
    title: 'Interest, dividends, mutual funds (T3, T5, T5008)',
    checked: false,
    image: false,
    inputs: [],
    images: [],
    type: 'images'
  },
  {
    title: 'Old Age Security and CPP benefits (T4A-OAS,T4AP)',
    checked: false,
    image: false,
    inputs: [],
    images: [],
    type: 'images'
  },
  {
    title: 'Other pensions and annuities (T4A)',
    checked: false,
    image: false,
    images: [],
    inputs: [],
    type: 'images'
  },
  {
    title: 'Social assitsance payments/wsib (T5007)',
    checked: false,
    image: false,
    inputs: [],
    images: [],
    type: 'images'
  },
  {
    title: 'T4RIF slips',
    checked: false,
    image: false,
    images: [],
    inputs: [],
    type: 'images'
  },
  {
    title: 'T4RSP slips',
    checked: false,
    image: false,
    images: [],
    inputs: [],
    type: 'images'
  },
  {
    title: 'T4PS slips',
    checked: false,
    image: false,
    images: [],
    inputs: [],
    type: 'images'
  },
  {
    title: 'T4RCA slips',
    checked: false,
    image: false,
    images: [],
    inputs: [],
    type: 'images'
  },
  {
    title: 'T5013 slips',
    checked: false,
    image: false,
    images: [],
    inputs: [],
    type: 'images'
  },
  {
    title: 'Support Payments Received',
    checked: false,
    inputs: [{
      placeholder: 'Spousal Amount',
      value: '',
      name: 'Spousal Amount'
    }, {
      placeholder: 'Child Amount',
      value: '',
      name: 'Child Amount'
    }],
    type: 'inputs'
  },
  {
    title: 'No Income Slips',
    checked: false,
    image: false,
    inputs: [],
    images: [],
    type: null
  },
  {
    title: 'All other information slips',
    checked: false,
    image: false,
    inputs: [],
    images: [],
    type: 'images'
  },
  ],

  slipsChecklist: [{
    title: 'T4 slips (Employment income)',
    checked: false,
    image: false,
    images: [],
    inputs: [],
    type: 'images'
  },
  {
    title: 'Employment insurance benefits (T4E)',
    checked: false,
    image: false,
    images: [],
    inputs: [],
    type: 'images'
  },
  {
    title: 'Interest, dividends, mutual funds (T3, T5, T5008)',
    checked: false,
    image: false,
    inputs: [],
    images: [],
    type: 'images'
  },
  {
    title: 'Old Age Security and CPP benefits (T4A-OAS,T4AP)',
    checked: false,
    image: false,
    inputs: [],
    images: [],
    type: 'images'
  },
  {
    title: 'Other pensions and annuities (T4A)',
    checked: false,
    image: false,
    images: [],
    inputs: [],
    type: 'images'
  },
  {
    title: 'Social assitsance payments/wsib (T5007)',
    checked: false,
    image: false,
    inputs: [],
    images: [],
    type: 'images'
  },
  {
    title: 'T4RIF slips',
    checked: false,
    image: false,
    images: [],
    inputs: [],
    type: 'images'
  },
  {
    title: 'T4RSP slips',
    checked: false,
    image: false,
    images: [],
    inputs: [],
    type: 'images'
  },
  {
    title: 'T4PS slips',
    checked: false,
    image: false,
    images: [],
    inputs: [],
    type: 'images'
  },
  {
    title: 'T4RCA slips',
    checked: false,
    image: false,
    images: [],
    inputs: [],
    type: 'images'
  },
  {
    title: 'T5013 slips',
    checked: false,
    image: false,
    images: [],
    inputs: [],
    type: 'images'
  },
  {
    title: 'Support Payments Received',
    checked: false,
    inputs: [{
      placeholder: 'Spousal Amount',
      value: '',
      name: 'Spousal Amount'
    }, {
      placeholder: 'Child Amount',
      value: '',
      name: 'Child Amount'
    }],
    type: 'inputs'
  },
  {
    title: 'No Income Slips',
    checked: false,
    image: false,
    inputs: [],
    images: [],
    type: null
  },
  {
    title: 'All other information slips',
    checked: false,
    image: false,
    inputs: [],
    images: [],
    type: 'images'
  },
  ],
  spouseReceiptsChecklist: [{
    title: 'RRSP contribution receipts',
    checked: false,
    images: [],
    type: 'images'
  },
  {
    title: 'Support for a child, spouse or common-law partner',
    checked: false,
    type: 'inputs',
    inputs: [{
      placeholder: 'Child Support Paid',
      value: '',
      name: 'Child Support Paid'
    },
    {
      placeholder: 'Spouse Support Paid',
      value: '',
      name: 'Spouse Support Paid'
    }
    ]
  },
  {
    title: 'Profesional or union dues',
    checked: false,
    images: [],
    type: 'images'
  },
  {
    title: 'Tool expenses (Tradespersons & apprentice mechanics)',
    checked: false,
    inputs: [{
      placeholder: 'Enter Amount',
      value: '',
      name: 'Tool Expenses Amount'
    }],
    type: 'inputs'
  },
  {
    title: 'Other employment expenses (T2200)',
    checked: false,
    images: [],
    type: 'images'
  },
  {
    title: 'Teacher\'s school supplies',
    checked: false,
    inputs: [{
      placeholder: 'Enter Amount',
      value: '',
      name: 'Teacher Supplies Amount'
    }],
    type: 'inputs'
  },
  {
    title: 'Medical expenses',
    checked: false,
    inputs: [{
      placeholder: 'Enter Amount',
      value: '',
      name: 'Medical Amount'
    }],
    type: 'inputs'
  },
  {
    title: 'Charitable donations',
    checked: false,
    inputs: [{
      placeholder: 'Enter Amount',
      value: '',
      name: 'Charitable Amount'
    }],
    type: 'inputs'
  },
  {
    title: 'T2202 Tution and Enrollment Certificate',
    checked: false,
    inputs: [],
    images: [],
    type: 'images'
  },
  {
    title: 'Political contributions',
    checked: false,
    type: 'political',
    typeOfContribution: [
      {
        title: 'Provincial Contribution',
        amount: '',
        checked: false
      },
      {
        title: 'Fedral Contribution',
        amount: '',
        checked: false
      }
    ]
  },
  {
    title: 'Child Care Expenses',
    checked: false,
    type: 'child',
    childs: []
  },
  {
    title: 'Rent Tax',
    checked: false,
    inputs: [{
      placeholder: 'Enter Amount',
      value: '',
      name: 'Tax Amount'
    }],
    type: 'inputs'
  },
  {
    title: 'Property Tax',
    checked: false,
    inputs: [{
      placeholder: 'Enter Amount',
      value: '',
      name: 'Tax Amount'
    }],
    type: 'inputs'
  },
  ],

  receiptsChecklist: [{
    title: 'RRSP contribution receipts',
    checked: false,
    images: [],
    type: 'images'
  },
  {
    title: 'Support for a child, spouse or common-law partner',
    checked: false,
    type: 'inputs',
    inputs: [{
      placeholder: 'Amount of Child Support',
      value: '',
      name: 'Child Support Paid'
    },
    {
      placeholder: 'Spouse Support Paid',
      value: '',
      name: 'Spouse Support Paid'
    }
    ]
  },
  {
    title: 'Profesional or union dues',
    checked: false,
    images: [],
    type: 'images'
  },
  {
    title: 'Tool expenses (Tradespersons & apprentice mechanics)',
    checked: false,
    inputs: [{
      placeholder: 'Enter Amount',
      value: '',
      name: 'Tool Expenses Amount'
    }],
    type: 'inputs'
  },
  {
    title: 'Other employment expenses (T2200)',
    checked: false,
    images: [],
    type: 'images'
  },
  {
    title: 'Teacher\'s school supplies',
    checked: false,
    inputs: [{
      placeholder: 'Enter Amount',
      value: '',
      name: 'Teacher Supplies Amount'
    }],
    type: 'inputs'
  },
  {
    title: 'Medical expenses',
    checked: false,
    inputs: [{
      placeholder: 'Enter Amount',
      value: '',
      name: 'Medical Amount'
    }],
    type: 'inputs'
  },
  {
    title: 'Charitable donations',
    checked: false,
    inputs: [{
      placeholder: 'Enter Amount',
      value: '',
      name: 'Charitable Amount'
    }],
    type: 'inputs'
  },
  {
    title: 'T2202 Tution and Enrollment Certificate',
    checked: false,
    inputs: [],
    images: [],
    type: 'images'
  },
  {
    title: 'Political contributions',
    checked: false,
    type: 'political',
    typeOfContribution: [
      {
        title: 'Provincial Contribution',
        amount: '',
        checked: false
      },
      {
        title: 'Fedral Contribution',
        amount: '',
        checked: false
      }
    ]
  },
  {
    title: 'Child Care Expenses',
    checked: false,
    type: 'child',
    childs: []
  },
  {
    title: 'Rent Tax',
    checked: false,
    inputs: [{
      placeholder: 'Enter Amount',
      value: '',
      name: 'Tax Amount'
    }],
    type: 'inputs'
  },
  {
    title: 'Property Tax',
    checked: false,
    inputs: [{
      placeholder: 'Enter Amount',
      value: '',
      name: 'Tax Amount'
    }],
    type: 'inputs'
  },
  ],

  spouseOtherDocChecklist: [{
    title: 'Notice of Assessment/ Reassessment',
    checked: false,
    images: [],
    type: 'images',
    imageValidation: 'none',
  },
  {
    title: 'Any home buyers/lifelong learning plan repayment.',
    checked: false,
    type: 'inputs',
    inputs: [{
      placeholder: 'Homebuyer Amount',
      value: '',
      name: 'Homebuyer'
    },
    {
      placeholder: 'Lifelong Amount',
      value: '',
      name: 'Lifelong'
    }],
  },
  {
    title: 'Sale of principal residence',
    checked: false,
    type: 'prompt',
    message: 'Somebody from the office will contact you regarding this!!'
  },
  {
    title: 'Sale or deemed sale of stocks, bonds or real estate',
    checked: false,
    type: 'prompt',
    message: 'Somebody from the office will contact you regarding this!!',
  },
  {
    title: 'Rental income and expense receipts',
    checked: false,
    type: 'inputs',
    inputs: [{
      placeholder: 'No. Properties',
      value: '',
      name: 'Number of Properties'
    }],
    showEmailButton: true,
    documentType: 3,
    emailButtonTitle: 'Click here to Fill T776'
  },
  {
    title: 'Business, farm or fishing income/expenses',
    checked: false,
    type: 'inputs',
    inputs: [{
      placeholder: 'No. Businesses',
      value: '',
      name: 'Number of Businesses'
    }],
    showEmailButton: true,
    documentType: 1,
    emailButtonTitle: 'Click here to Fill T2125'
  },
  {
    title: 'Automobile / Travel logbook and expenses',
    checked: false,
    showEmailButton: true,
    documentType: 2,
    emailButtonTitle: 'Click here to Fill T777'
  },
  ],
  otherDocChecklist: [{
    title: 'Notice of Assessment/ Reassessment',
    checked: false,
    images: [],
    type: 'images',
    imageValidation: 'none',
  },
  {
    title: 'Any home buyers/lifelong learning plan repayment.',
    checked: false,
    type: 'inputs',
    inputs: [{
      placeholder: 'Homebuyer Amount',
      value: '',
      name: 'Homebuyer'
    },
    {
      placeholder: 'Lifelong Amount',
      value: '',
      name: 'Lifelong'
    }],
  },
  {
    title: 'Sale of principal residence',
    checked: false,
    type: 'prompt',
    message: 'Somebody from the office will contact you regarding this!!'
  },
  {
    title: 'Sale or deemed sale of stocks, bonds or real estate',
    checked: false,
    type: 'prompt',
    message: 'Somebody from the office will contact you regarding this!!',
  },
  {
    title: 'Rental income and expense receipts',
    checked: false,
    type: 'inputs',
    inputs: [{
      placeholder: 'No. Properties',
      value: '',
      name: 'Number of Properties'
    }],
    showEmailButton: true,
    documentType: 3,
    emailButtonTitle: 'Email Blank T776'
  },
  {
    title: 'Business, farm or fishing income/expenses',
    checked: false,
    type: 'inputs',
    inputs: [{
      placeholder: 'No. Businesses',
      value: '',
      name: 'Number of Businesses'
    }],
    showEmailButton: true,
    documentType: 1,
    emailButtonTitle: 'Email Blank T2125'
  },
  {
    title: 'Automobile / Travel logbook and expenses',
    checked: false,
    showEmailButton: true,
    documentType: 2,
    emailButtonTitle: 'Email Blank T777'
  },
  ],

  //pickup request
  timeSlot: '',
  preferredDate: tomorrow,

  //any other info
  // preferredTaxProfessional: '',
  anyOtherInfo: ''
}

//new Date()
//tomorrow