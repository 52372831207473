import React from "react";
import { Row, Col, Card, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

//Redux imports
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

function StepTwo(props) {
  const { setCurrent } = props;
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const history = useHistory();
  function saveDetails() {
    toast.success("Data Saved!");
    history.push("/");
  }

  return (
    <>
      <Row
        style={{
          marginTop: "50px",
          marginBottom: "50px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Col ld={{ span: 16 }} md={{ span: 20 }}>
          <Card
            title={
              <div>
                <Button
                  className="mr-2 protax-back-btn white-text"
                  size={"large"}
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => {
                    setCurrent((previousCurrent) => previousCurrent - 1);
                  }}
                />
                Appointment Schedule
              </div>
            }
            headStyle={{
              background: "#b0000b",
              color: "#fff",
              borderRadius: "12px 12px 0px 0px",
              fontSize: "22px",
            }}
            style={{ borderRadius: "12px" }}
          >
            <h5>You can book your appointment once the Payment is completed</h5>
            <h6 style={{ marginBottom: "24px" }}>
              Selection of Preferred Date and Time to finalize your return can
              be selected after the Payment Process.
            </h6>

            <Row gutter={24}>
              <Col xs={{ span: 24 }}>
                <Button
                  type="dashed"
                  className="mr-2 protax-back-btn"
                  onClick={() => {
                    setCurrent((previousCurrent) => previousCurrent - 1);
                  }}
                >
                  Back
                </Button>
                {props?.isEdit ? null : (
                  <Button
                    onClick={saveDetails}
                    type="dashed"
                    className=" mr-3"
                    htmlType="button"
                  >
                    Save and Exit
                  </Button>
                )}
                <Button
                  type="primary"
                  className="protax-btn"
                  htmlType="button"
                  onClick={() => {
                    setCurrent((previousCurrent) => previousCurrent + 1);
                  }}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = () => (state) => ({
  timeSlot: state.Pickup.timeSlot,
  preferredDate: state.Pickup.preferredDate,
  anyOtherInfo: state.Pickup.anyOtherInfo,
  isEdit: state?.Pickup?.isEdit,
});

export default connect(mapStateToProps)(StepTwo);
