import React from 'react';
import { Modal } from 'react-responsive-modal';

export default function DropOffModal(props) {
    return (
        <Modal
            open={props.modalVisible}
            onClose={() => { props.setModalVisible(false) }}
            center
            classNames={{
                modal: 'dropOffModal',
              }}
        >
            <div id="dropOffForm" style={{padding: '20px'}}>
                <div className="modal-content">
                    <div className="row">
                        <div className="quotebox">
                            <div className="sub_box">
                                {/* heading */}
                                {/* <div className="row"> */}

                                <h5 className="center"> <span style={{ borderBottom: '2px solid #00aa00' }}>Select Form</span>
                                </h5>
                                {/* </div> */}
                                {/* heading endzz */}

                                {/* main heading button starts here */}
                                <div className="row center">
                                    <a href="/tax.pdf" target="_blank" className=" modal-trigger btn boxes-mine-color-black-bg">DROP OFF FORM</a>
                                </div>
                                <div className="row center">
                                    <a href="/tax-checklist.pdf" target="_blank" className=" modal-trigger btn boxes-mine-color-black-bg">TAX TIPS</a>
                                </div>
                                {/* main btn heading button endz */}

                                {/* tax body info starts here */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
