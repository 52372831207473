import React, { useEffect, useState } from "react";
import { Form, Input, Radio, DatePicker, Row, Col, Card, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

//constants
import {
  allMaritalStatus,
  allFillingForSpouse,
  allPostSecondaryStudent,
} from "../../../common/consts/ProtaxHelpers";
import moment from "moment";

//Redux imports
import { connect } from "react-redux";
import {
  handleChange,
  handleMultipleChange,
} from "../../../actions/PickupActions";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

function SpouseInfo(props) {
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const { handleChange, handleMultipleChange, setCurrent, spouseInfo, isEdit } =
    props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      maritalStatus: props.maritalStatus,
      fillingForSpouse: props.fillingForSpouse,
      disabled: spouseInfo?.disabled,
      dob: moment(spouseInfo?.dob),
      firstName: spouseInfo?.firstName,
      lastName: spouseInfo?.lastName,
      netIncome: spouseInfo?.netIncome,
      postSecondaryStudent: spouseInfo?.postSecondaryStudent,
      sin: spouseInfo?.sin,
    });
  }, []);

  const onFinish = (values) => {
    let newValues = values;
    delete newValues.maritalStatus;
    delete newValues.fillingForSpouse;
    if (
      getMaritalStatusConditions() &&
      moment().diff(values.dob, "years") < 18
    ) {
      let confirmation = window.confirm("Are you sure DOB is correct?");
      if (confirmation) {
        handleChange("spouseInfo", newValues);
        setCurrent((previousCurrent) => previousCurrent + 1);
      }
    } else {
      handleChange("spouseInfo", newValues);
      setCurrent((previousCurrent) => previousCurrent + 1);
    }
  };
  const [tempDetails, setTempDetails] = useState(null);

  const history = useHistory();

  function showSaveAlert() {
    toast.success("Data Saved!");
  }
  function pushToDashboard() {
    history.push("/");
  }

  function saveDetails(isExit) {
    if (tempDetails) {
      let newValues = { ...tempDetails };
      delete newValues.maritalStatus;
      delete newValues.fillingForSpouse;
      if (
        getMaritalStatusConditions() &&
        moment().diff(tempDetails.dob, "years") < 18
      ) {
        let confirmation = window.confirm("Are you sure DOB is correct?");
        if (confirmation) {
          handleChange("spouseInfo", newValues);
          showSaveAlert();
          if (isExit) pushToDashboard();
        }
      } else {
        handleChange("spouseInfo", newValues);
        showSaveAlert();
        if (isExit) pushToDashboard();
      }
    } else {
      showSaveAlert();
      if (isExit) pushToDashboard();
    }
  }

  return (
    <>
      <Row
        style={{
          marginTop: "50px",
          marginBottom: "50px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Col ld={{ span: 16 }} md={{ span: 20 }}>
          <Card
            title={
              <div>
                <Button
                  className="mr-2 protax-back-btn white-text"
                  size={"large"}
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => {
                    setCurrent((previousCurrent) => previousCurrent - 1);
                  }}
                />
                Enter your Spousal Information
              </div>
            }
            headStyle={{
              background: "#b0000b",
              color: "#fff",
              borderRadius: "12px 12px 0px 0px",
              fontSize: "22px",
            }}
            style={{ borderRadius: "12px" }}
          >
            <Form
              {...layout}
              name="dynamic_rule"
              onFinish={onFinish}
              form={form}
              onValuesChange={(changedValues, allValues) => {
                setTempDetails(allValues);
              }}
            >
              <Row>
                <Col xs={{ span: 24 }}>
                  <Form.Item
                    name="maritalStatus"
                    label="Marital Status"
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      name="radiogroup"
                      name="maritalStatus"
                      onChange={(e) => {
                        handleChange("maritalStatus", e.target.value);
                      }}
                    >
                      {allMaritalStatus.map((item, index) => {
                        return (
                          <Radio
                            value={item.value}
                            key={index}
                            className="text-capitalize"
                          >
                            {item.name}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              {/* //start */}
              {getMaritalStatusConditions() ? (
                <>
                  <Row>
                    <Col xs={{ span: 24 }}>
                      <Form.Item
                        name="fillingForSpouse"
                        label="Are you filling for spouse?"
                        rules={[{ required: true }]}
                      >
                        <Radio.Group
                          name="radiogroup"
                          name="fillingForSpouse"
                          onChange={(e) => {
                            handleChange("fillingForSpouse", e.target.value);
                          }}
                        >
                          {allFillingForSpouse.map((item, index) => {
                            return (
                              <Radio
                                value={item.value}
                                key={index}
                                className="text-capitalize"
                              >
                                {item.name}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <Form.Item
                        name="firstName"
                        label="First Name"
                        rules={[
                          {
                            required: true,
                            message: "First Name Required!",
                          },
                        ]}
                      >
                        <Input
                          style={{ height: "2rem" }}
                          name="firstName"
                          // onChange={handleText}
                          placeholder="John"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <Form.Item name="middleName" label="Middle Name">
                        <Input
                          style={{ height: "2rem" }}
                          name="middleName"
                          // onChange={handleText}
                          placeholder=" "
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <Form.Item
                        name="lastName"
                        label="Last Name"
                        rules={[
                          {
                            required: true,
                            message: "Please input last name",
                          },
                        ]}
                      >
                        <Input
                          style={{ height: "2rem" }}
                          name="lastName"
                          // onChange={handleText}
                          placeholder="Smith"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {!props.fillingForSpouse ? (
                    <Row>
                      <Col lg={{ span: 24 }} xs={{ span: 24 }}>
                        <Form.Item name="netIncome" label="Net Income">
                          <Input style={{ height: "2rem" }} name="netIncome" />
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : null}
                  <Row gutter={24}>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                      <Form.Item
                        name="sin"
                        label="Social Insurance Number"
                        rules={[
                          {
                            max: 9,
                          },
                          {
                            min: 9,
                          },
                        ]}
                      >
                        <Input
                          style={{ height: "2rem" }}
                          name="sin"
                          // onChange={handleText}
                          placeholder="Please input Social Insurance Number"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                      <Form.Item
                        name="dob"
                        label="D.O.B"
                        rules={[{ required: true }]}
                      >
                        <DatePicker
                          className="m-0 w-100"
                          onChange={(moment, value) => {}}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                      <Form.Item
                        // {...formItemLayout}
                        name="postSecondaryStudent"
                        style={{ width: "100%", display: "flex" }}
                        label="Post-Secondary Student"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Radio.Group name="postSecondaryStudent">
                          {allPostSecondaryStudent.map((item, index) => {
                            return (
                              <Radio
                                value={item.value}
                                key={index}
                                className="text-capitalize"
                              >
                                {item.name}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                      <Form.Item
                        // {...formItemLayout}
                        name="disabled"
                        style={{ width: "100%", display: "flex" }}
                        label="Disability Tax Credit"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Radio.Group name="disabled">
                          {allPostSecondaryStudent.map((item, index) => {
                            return (
                              <Radio
                                value={item.value}
                                key={index}
                                className="text-capitalize"
                              >
                                {item.name}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : null}
              {/* //end */}
              <Row gutter={24}>
                <Col xs={{ span: 24 }}>
                  <Button
                    type="dashed"
                    className="mr-2 protax-back-btn"
                    onClick={() => {
                      setCurrent((previousCurrent) => previousCurrent - 1);
                    }}
                  >
                    Back
                  </Button>
                  {!isEdit && (
                    <>
                      <Button
                        onClick={() => {
                          saveDetails(false);
                        }}
                        type="dashed"
                        className="mr-3"
                        htmlType="button"
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          saveDetails(true);
                        }}
                        type="dashed"
                        className=" mr-3"
                        htmlType="button"
                      >
                        Save and Exit
                      </Button>
                    </>
                  )}
                  <Button
                    type="primary"
                    className="protax-btn"
                    htmlType="submit"
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
  function getMaritalStatusConditions() {
    let { maritalStatus } = props;
    let conditions = false;
    if (maritalStatus === 1 || maritalStatus === 2) conditions = true;
    return conditions;
  }
}

const mapStateToProps = () => (state) => ({
  maritalStatus: state.Pickup.maritalStatus,
  fillingForSpouse: state.Pickup.fillingForSpouse,
  spouseInfo: state.Pickup.spouseInfo,
  isEdit: state.Pickup.isEdit,
});
const mapDispatchToProps = () => (dispatch) => ({
  handleChange: (name, value) => dispatch(handleChange(name, value)),
  handleMultipleChange: (state) => dispatch(handleMultipleChange(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpouseInfo);
