import React from "react";
import { Button, Col } from "antd";
// import IdProofImage from "../../assets/idproof.png";
import paymentIcon from "../../assets/appointment.svg";
import { useHistory } from "react-router-dom";
import moment from "moment";

export default function Appointment(props) {
  const { orderInfo } = props;
  const history = useHistory();

  const { timeSlot, preferredDate } = orderInfo;

  const isAppointmentExists = timeSlot && preferredDate;

  if (orderInfo) {
    return (
      <Col
        xs={{ span: 24 }}
        md={{ span: 12 }}
        lg={{ span: 12 }}
        xl={{ span: 8 }}
      >
        <div className="my-card py-5">
          <div>
            <span className="text-bold fa-lg">Appointment</span>
          </div>
          <div>
            {isAppointmentExists ? (
              <span className="text-grey fa-md text-uppercase">
                {moment(preferredDate || moment()).format("YYYY-MM-DD")} (
                {timeSlot})
              </span>
            ) : (
              <span className="text-grey fa-md text-capitalize">
                Schedule your appointment now
              </span>
            )}
          </div>
          <div className="percent-chart-container">
            <div className="percent-chart">
              <img
                className="percent-chart"
                style={{ width: "140px", height: "150px" }}
                src={paymentIcon}
                alt="appointment"
              />
            </div>
            <div className="text-right btn_margin_top">
              <Button
                type="primary"
                className="protax-btn mt-2"
                // style={{ paddingRight: 10 }}
                onClick={() => {
                  history.push(`/appointment/${orderInfo?._id}`);
                }}
              >
                {isAppointmentExists ? "Change Appointment" : "Schedule Now"}
              </Button>
            </div>
          </div>
        </div>
      </Col>
    );
  }
  return null;
}
