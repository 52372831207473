import React, { useEffect, useState } from "react";
import Calculator from "awesome-react-calculator";
import { useDispatch, useSelector } from "react-redux";
import { updateCalculatorState } from "../../../actions/CommonActions";
import Lottie from "react-lottie";
import { LottieArrow } from "../../../common/consts/Arrow";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LottieArrow,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const style = {
  height: "24rem",
  width: "15rem",
};

export default function CalculatorComp() {
  function handleInput(input) {}

  function onResultChange(newResult) {}

  const isCalculatorOpen = useSelector(
    (state) => state?.Common?.isCalculator || false
  );
  const dispatch = useDispatch();

  const [isArrowVisible, setIsArrowVisible] = useState(false);

  useEffect(() => {
    if (isCalculatorOpen) {
      setIsArrowVisible(true);
      setTimeout(() => {
        setIsArrowVisible(false);
      }, 5000);
    }
  }, [isCalculatorOpen]);

  return (
    <>
      <div
        className="calculator-button-open"
        onClick={() => {
          dispatch(updateCalculatorState(!isCalculatorOpen));
        }}
      >
        <i
          className={`fas fa-${
            isCalculatorOpen ? "times" : "calculator"
          } text-white fa-lg`}
        />
      </div>
      {isCalculatorOpen ? (
        <div className="calculator-container">
          {isArrowVisible ? (
            <Lottie
              options={defaultOptions}
              height={400}
              width={150}
              isStopped={false}
              isPaused={false}
            />
          ) : null}
          <div className="calculator-demo" style={style}>
            <Calculator
              onNewInput={handleInput}
              onResultChange={onResultChange}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}
