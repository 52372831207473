import {
  PICKUP_HANDLE_CHANGE,
  PICKUP_RESET_STATE,
  SET_EDIT_STATE,
  HANDLE_MULTIPLE_CHANGE
} from './types';

//store
import store from '../Store';

export const handleChange = (name, payload) => dispatch => {
  let stateToSave = store.getState().Pickup;
  if (stateToSave?.isOldData && !stateToSave?.isEdit) {
    stateToSave[name] = payload;
    localStorage.setItem('_pickupState', JSON.stringify(stateToSave));
  }
  dispatch({
    type: PICKUP_HANDLE_CHANGE,
    payload,
    name
  })
};

export const resetPickupState = () => ({
  type: PICKUP_RESET_STATE,
  payload: {}
});

export const setEditState = (state) => ({
  type: SET_EDIT_STATE,
  payload: state
})

export const handleMultipleChange = (state) => dispatch => {
  let stateToSave = store.getState().Pickup;
  if (stateToSave?.isOldData && !stateToSave?.isEdit) {
    stateToSave = {
      ...stateToSave,
      ...state
    };
    localStorage.setItem('_pickupState', JSON.stringify(stateToSave));
  }
  dispatch({
    type: HANDLE_MULTIPLE_CHANGE,
    payload: state,
  })
}