import React from "react";
import { Button, Col } from "antd";
// import IdProofImage from "../../assets/idproof.png";
import paymentIcon from "../../assets/paymentIcon.svg";
import { checkIfOrderFromOtherPlatform, getTransactionTitle } from "../../common/utils";
import { useHistory } from "react-router-dom";

export default function Payment(props) {
  const { orderInfo } = props;
  const history = useHistory();

  const transactionTitle = getTransactionTitle(
    orderInfo.payment,
    orderInfo.totalAmount,
    orderInfo.baseOrder
  );

  const orderEditDetails = checkIfOrderFromOtherPlatform(orderInfo);

  if (orderInfo && transactionTitle && orderEditDetails?.isOrderEditable) {
    return (
      <Col
        xs={{ span: 24 }}
        md={{ span: 12 }}
        lg={{ span: 12 }}
        xl={{ span: 8 }}
      >
        <div className="my-card py-5">
          <div>
            <span className="text-bold fa-lg">{transactionTitle}</span>
          </div>
          <div>
            <span className="text-grey fa-md text-capitalize">
              Payment Details - {orderInfo?.firstName}
            </span>
          </div>
          <div className="percent-chart-container">
            <div className="percent-chart">
              <img
                className="percent-chart"
                style={{ width: "140px", height: "150px" }}
                src={paymentIcon}
              />
            </div>
            <div className="text-right btn_margin_top">
              <Button
                type="primary"
                className="protax-btn"
                // style={{ paddingRight: 10 }}
                onClick={() => {
                  history.push("/transaction", {
                    payment: orderInfo.payment,
                    totalAmount: orderInfo.totalAmount,
                    orderId: orderInfo._id,
                    discount: orderInfo?.baseOrder?.discount,
                  });
                }}
              >
                {transactionTitle}
              </Button>
            </div>
          </div>
        </div>
      </Col>
    );
  }
  return null;
}
