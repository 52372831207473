import React from "react";
import { Button, Col, message, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";

// import editLatestProfile from "../../assets/editTaxForm.png";
import EditDocumentIcon from "../../assets/EditDocumentIcon.svg";

// redux import

import { setEditState } from "../../actions/PickupActions";
import { updateEditBaseOrder } from "../../actions/OrderActions";
import { connect } from "react-redux";
import { checkIfOrderFromOtherPlatform } from "../../common/utils";

function LatestTaxForm(props) {
  const { orderInfo, updateEditBaseOrder, setEditState, freshStart } = props;
  let history = useHistory();
  const orderEditDetails = checkIfOrderFromOtherPlatform(orderInfo);

  return (
    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
      <div className="my-card py-5">
        <div>
          <span className="text-bold fa-lg">
            Edit your info or upload missing document here
          </span>
        </div>
        <div>
          <span className="text-grey fa-md text-capitalize">
            Order - {orderInfo?.firstName}
          </span>
        </div>
        <div className="percent-chart-container">
          <div className="percent-chart">
            <img
              className="percent-chart"
              style={{ width: "140px", height: "150px" }}
              src={EditDocumentIcon}
            />
          </div>
          <div className="text-right btn_margin_top">
            <Button
              type="primary"
              className="protax-btn"
              // style={{ paddingRight: 10 }}
              onClick={async () => {
                if (orderInfo.status < 3) {
                  if (
                    orderEditDetails.isOrderEditable &&
                    !orderEditDetails?.isOrderBlocked
                  ) {
                    openEditPage();
                  } else if (orderEditDetails.isOrderBlocked) {
                    message.error(
                      "Order not editable, Kindly contact us directly!!"
                    );
                  } else {
                    message.error(
                      "Order not editable, Orders done from mobile app can only be edited on app."
                    );
                  }
                } else {
                  message.error(
                    "Cannot edit the order because work is in progress, Kindly contact us directly!!"
                  );
                }
              }}
            >
              Click Here
            </Button>
          </div>
        </div>
      </div>
    </Col>
  );

  async function openEditPage() {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Please review all the steps & all the steps are mandatory.",
      okText: "Ok",
      cancelText: "Cancel",
      onOk: async () => {
        await freshStart();
        updateEditBaseOrder(orderInfo.baseOrder.order);
        setEditState({
          ...orderInfo,
          isEdit: true,
        });
        history.push("/tax_return");
      },
    });
  }
}

const mapDispatchToProps = () => (dispatch) => ({
  setEditState: (state) => dispatch(setEditState(state)),
  updateEditBaseOrder: (order) => dispatch(updateEditBaseOrder(order)),
});

export default connect(null, mapDispatchToProps)(LatestTaxForm);
