import React from 'react';
import moment from 'moment';
import { Divider } from 'antd';
import NotFound from '../../../assets/notFound.png'

export default function DependenatsForm(props) {
    let { dependants, title } = props;
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h5>{title}</h5>
                </div>
            </div>
            <div className="row">
                {
                    dependants?.map((dependant, index) => {
                        return (
                            <div className="col-12" key={index}>
                                <div className="text-bold text-capitalize">{`${dependant?.firstName} ${dependant?.middleName} ${dependant?.lastName}`}</div>
                                <div className="d-flex"><div className="text-bold" style={{ minWidth: '150px' }}>DOB :</div>{moment(dependant.dob).format('Do MMMM, YYYY')}</div>
                                <div className="d-flex"><div className="text-bold" style={{ minWidth: '150px' }}>Relation :</div> {dependant.relation}</div>
                                <div className="d-flex"><div className="text-bold" style={{ minWidth: '150px' }}>Net Income :</div> {dependant?.netIncome || ' - '}</div>
                                <div className="d-flex"><div className="text-bold" style={{ minWidth: '150px' }}>Post Secondary :</div>{dependant.postSecondaryStudent ? 'Yes' : 'No'}</div>
                                <div className="d-flex"><div className="text-bold" style={{ minWidth: '150px' }}>Disabled :</div> {dependant.disabled ? 'Yes' : 'No'}</div>
                                <Divider />
                            </div>
                        )
                    })
                }
            </div>
            {
                dependants.length === 0 && (
                    (
                        <div className="row">
                            <div className="col-md-12 text-center m-3">
                                <img
                                    src={NotFound}
                                    className="img-fluid"
                                    width="300"
                                />
                            </div>
                            <div className="col-md-12 text-center">
                                <span>No Dependenets Added!!</span>
                            </div>
                        </div>
                    )
                )
            }
        </>
    )
}