export const LottieArrow = {
    "v": "5.5.7",
    "meta": { "g": "LottieFiles AE 0.1.20", "a": "", "k": "", "d": "", "tc": "" },
    "fr": 30,
    "ip": 0,
    "op": 34,
    "w": 500,
    "h": 500,
    "nm": "arrow animation",
    "ddd": 0,
    "assets": [],
    "layers": [{
        "ddd": 0,
        "ind": 1,
        "ty": 4,
        "nm": "Layer 5 Outlines",
        "sr": 1,
        "ks": { "o": { "a": 0, "k": 50, "ix": 11 }, "r": { "a": 0, "k": 0, "ix": 10 }, "p": { "a": 1, "k": [{ "i": { "x": 0.667, "y": 1 }, "o": { "x": 0.333, "y": 0 }, "t": 2, "s": [250, 178, 0], "to": [0, 6.667, 0], "ti": [0, 0, 0] }, { "i": { "x": 0.667, "y": 1 }, "o": { "x": 0.333, "y": 0 }, "t": 16, "s": [250, 218, 0], "to": [0, 0, 0], "ti": [0, 6.667, 0] }, { "t": 30, "s": [250, 178, 0] }], "ix": 2 }, "a": { "a": 0, "k": [72.596, 41.955, 0], "ix": 1 }, "s": { "a": 0, "k": [100, 100, 100], "ix": 6 } },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [
                            [0, 0],
                            [0, 0],
                            [0, 0]
                        ],
                        "o": [
                            [0, 0],
                            [0, 0],
                            [0, 0]
                        ],
                        "v": [
                            [57.596, -26.956],
                            [-0.001, 26.956],
                            [-57.597, -26.956]
                        ],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, { "ty": "st", "c": { "a": 0, "k": [0.7490196078431373, 0.6823529411764706, 0.6, 1], "ix": 3 }, "o": { "a": 0, "k": 100, "ix": 4 }, "w": { "a": 0, "k": 30, "ix": 5 }, "lc": 2, "lj": 2, "bm": 0, "nm": "Stroke 1", "mn": "ADBE Vector Graphic - Stroke", "hd": false }, { "ty": "tr", "p": { "a": 0, "k": [72.596, 41.955], "ix": 2 }, "a": { "a": 0, "k": [0, 0], "ix": 1 }, "s": { "a": 0, "k": [100, 100], "ix": 3 }, "r": { "a": 0, "k": 0, "ix": 6 }, "o": { "a": 0, "k": 100, "ix": 7 }, "sk": { "a": 0, "k": 0, "ix": 4 }, "sa": { "a": 0, "k": 0, "ix": 5 }, "nm": "Transform" }],
            "nm": "Group 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }],
        "ip": 0,
        "op": 35,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 2,
        "ty": 4,
        "nm": "Layer 4 Outlines",
        "sr": 1,
        "ks": { "o": { "a": 0, "k": 80, "ix": 11 }, "r": { "a": 0, "k": 0, "ix": 10 }, "p": { "a": 1, "k": [{ "i": { "x": 0.667, "y": 1 }, "o": { "x": 0.333, "y": 0 }, "t": 3, "s": [250, 251, 0], "to": [0, 6.667, 0], "ti": [0, 0, 0] }, { "i": { "x": 0.667, "y": 1 }, "o": { "x": 0.333, "y": 0 }, "t": 17, "s": [250, 291, 0], "to": [0, 0, 0], "ti": [0, 6.667, 0] }, { "t": 32, "s": [250, 251, 0] }], "ix": 2 }, "a": { "a": 0, "k": [72.596, 41.955, 0], "ix": 1 }, "s": { "a": 0, "k": [100, 100, 100], "ix": 6 } },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [
                            [0, 0],
                            [0, 0],
                            [0, 0]
                        ],
                        "o": [
                            [0, 0],
                            [0, 0],
                            [0, 0]
                        ],
                        "v": [
                            [57.596, -26.955],
                            [-0.001, 26.955],
                            [-57.597, -26.955]
                        ],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, { "ty": "st", "c": { "a": 0, "k": [0.6705882352941176, 0.5882352941176471, 0.47843137254901963, 1], "ix": 3 }, "o": { "a": 0, "k": 100, "ix": 4 }, "w": { "a": 0, "k": 30, "ix": 5 }, "lc": 2, "lj": 2, "bm": 0, "nm": "Stroke 1", "mn": "ADBE Vector Graphic - Stroke", "hd": false }, { "ty": "tr", "p": { "a": 0, "k": [72.596, 41.955], "ix": 2 }, "a": { "a": 0, "k": [0, 0], "ix": 1 }, "s": { "a": 0, "k": [100, 100], "ix": 3 }, "r": { "a": 0, "k": 0, "ix": 6 }, "o": { "a": 0, "k": 100, "ix": 7 }, "sk": { "a": 0, "k": 0, "ix": 4 }, "sa": { "a": 0, "k": 0, "ix": 5 }, "nm": "Transform" }],
            "nm": "Group 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }],
        "ip": 0,
        "op": 35,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 3,
        "ty": 4,
        "nm": "Layer 3 Outlines",
        "sr": 1,
        "ks": { "o": { "a": 0, "k": 100, "ix": 11 }, "r": { "a": 0, "k": 0, "ix": 10 }, "p": { "a": 1, "k": [{ "i": { "x": 0.667, "y": 1 }, "o": { "x": 0.333, "y": 0 }, "t": 4, "s": [250, 324, 0], "to": [0, 6.667, 0], "ti": [0, 0, 0] }, { "i": { "x": 0.667, "y": 1 }, "o": { "x": 0.333, "y": 0 }, "t": 18, "s": [250, 364, 0], "to": [0, 0, 0], "ti": [0, 6.667, 0] }, { "t": 34, "s": [250, 324, 0] }], "ix": 2 }, "a": { "a": 0, "k": [132.596, 101.955, 0], "ix": 1 }, "s": { "a": 0, "k": [100, 100, 100], "ix": 6 } },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [
                            [0, 0],
                            [0, 0],
                            [0, 0]
                        ],
                        "o": [
                            [0, 0],
                            [0, 0],
                            [0, 0]
                        ],
                        "v": [
                            [57.596, -26.955],
                            [-0.001, 26.955],
                            [-57.597, -26.955]
                        ],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, { "ty": "st", "c": { "a": 0, "k": [0.6705882352941176, 0.5882352941176471, 0.47843137254901963, 1], "ix": 3 }, "o": { "a": 0, "k": 100, "ix": 4 }, "w": { "a": 0, "k": 30, "ix": 5 }, "lc": 2, "lj": 2, "bm": 0, "nm": "Stroke 1", "mn": "ADBE Vector Graphic - Stroke", "hd": false }, { "ty": "tr", "p": { "a": 0, "k": [132.596, 101.955], "ix": 2 }, "a": { "a": 0, "k": [0, 0], "ix": 1 }, "s": { "a": 0, "k": [100, 100], "ix": 3 }, "r": { "a": 0, "k": 0, "ix": 6 }, "o": { "a": 0, "k": 100, "ix": 7 }, "sk": { "a": 0, "k": 0, "ix": 4 }, "sa": { "a": 0, "k": 0, "ix": 5 }, "nm": "Transform" }],
            "nm": "Group 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }],
        "ip": 0,
        "op": 35,
        "st": 0,
        "bm": 0
    }],
    "markers": []
}