import React, { useState, useEffect } from "react";
import { Steps, Row, Col } from "antd";
import { useLocation } from "react-router-dom";

//forms
import PersonalInfo from "./steps/PersonalInfo";
import SpouseInfo from "./steps/SpouseInfo";
import DependentsInfo from "./steps/DependentsInfo";
import QuestionsInfo from "./steps/QuestionsInfo";
import Appointment from "./steps/Appointment";
import Payment from "./checklists/Payment";
import Checklist from "./checklists";
import PaymentFinal from "./checklists/PaymentFinal";
import ViewOrder from "../viewOrder";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

//key
import { PUB_KEY_STRIPE } from "../../common/consts/Config";

//redux imports
import { connect } from "react-redux";
import { handleChange } from "../../actions/PickupActions";
import Calculator from "./calculator";
import Prefill from "./prefill";

const { Step } = Steps;

function ReqForm(props) {
  const { handleChange } = props;

  const steps = [
    {
      title: "Personal",
    },
    {
      title: "Spousal",
    },
    {
      title: "Dependants",
    },
    {
      title: "Questions",
    },
    {
      title: "Checklist",
    },
    {
      title: "Review",
    },
    {
      title: "Appointment",
    },
    {
      title: "Breakdown",
    },
    {
      title: "Payment",
    },
  ];

  let stepper = 0;

  const location = useLocation();

  const [current, setCurrent] = useState(0);
  const [saveOrderReponse, setSaveOrderResponse] = useState({
    response: {},
    total: 0,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    handleChange("currentFormWeb", current);
  }, [current]);

  useEffect(() => {
    if (!props.previousAlert) {
      props.history.push("/");
    }
    // if (props?.history?.location?.state?.current) {
    //   setCurrent(props?.history?.location?.state?.current);
    // }
  }, [props.previousAlert]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const stripePromise = loadStripe(PUB_KEY_STRIPE);

  const [editOrderId, setEditOrderId] = useState(false);

  return (
    <Elements stripe={stripePromise}>
      <Row style={{ marginTop: "50px", marginBottom: "50px" }}>
        <Col lg={{ span: 20, offset: 2 }} sm={{ span: 22, offset: 1 }}>
          <div className="steps-action">
            <Steps
              current={current}
              style={{ flexDirection: "row", flexWrap: "wrap" }}
            >
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </div>
        </Col>
      </Row>
      {renderFrom()}
      <Calculator />
    </Elements>
  );
  function renderFrom() {
    if (current === 0) return <PersonalInfo setCurrent={setCurrent} />;
    else if (current === 1) return <SpouseInfo setCurrent={setCurrent} />;
    else if (current === 2) return <DependentsInfo setCurrent={setCurrent} />;
    else if (current === 3) return <QuestionsInfo setCurrent={setCurrent} />;
    else if (current === 4) return <Checklist setCurrent={setCurrent} />;
    else if (current === 5) return <ViewOrder setCurrent={setCurrent} />;
    else if (current === 6) return <Appointment setCurrent={setCurrent} />;
    else if (current === 7)
      return (
        <Payment
          {...props}
          setCurrent={setCurrent}
          setSaveOrderResponse={setSaveOrderResponse}
          setEditOrderId={setEditOrderId}
        />
      );
    else if (current === 8)
      return (
        <PaymentFinal
          {...props}
          setCurrent={setCurrent}
          saveOrderReponse={saveOrderReponse}
          setSaveOrderResponse={setSaveOrderResponse}
          editOrderId={editOrderId}
        />
      );
  }
}

const mapDispatchToProps = () => (dispatch) => ({
  handleChange: (name, value) => dispatch(handleChange(name, value)),
});

const mapStateToProps = () => (state) => ({
  previousAlert: state.Pickup.previousAlert,
  isEdit: state.Pickup.isEdit
});

export default connect(mapStateToProps, mapDispatchToProps)(ReqForm);
