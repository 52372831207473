import React, { useEffect, useState } from 'react';

import { Link } from "react-router-dom";
import M from 'materialize-css';

//modals
import DropOffModal from './DropOffModal';

export default function Navbar() {

  const [dropOffModalVisible, setDropOffModalVisible] = useState(false);

  useEffect(() => {
    M.AutoInit();
  }, []);

  function logout() {
    let confirmation = window.confirm("Are you sure you want to logout?");
    if (confirmation) {
      localStorage.removeItem('protax_web_user_info');
      localStorage.removeItem('protax_web_access_token');
      // window.open('https://www.protaxcanada.ca/');
      window.location.href = "https://www.protaxcanada.ca/"
    }
  }

  return (
    <>

      <DropOffModal
        modalVisible={dropOffModalVisible}
        setModalVisible={setDropOffModalVisible}
      />

      {/* modal endzz here  */}

      <div class="row">
        <div class="col s12 center-align family-old" style={{ fontSize: '25px', textTransform: 'uppercase' }}>
          Our office is open and Now we are providing Drop Off service.

    <button class="btn tooltipped" data-position="bottom" data-delay="50" data-tooltip="Get Your Quote" style={{ marginBottom: '10px', background: '#080' }} onClick={() => { setDropOffModalVisible(true) }}>Download Drop Off Form Here </button>
        </div>
      </div>
      <div className="row info-header hide-on-med-and-down">

        <div className="col s4 left-align">
          <a href="mailto:info@protaxcanada.ca" style={{ color: '#ffffff' }}> <i className="material-icons left">email</i>
      protaxcanada@hotmail.ca</a>
        </div>

        <div className="col s4 center-align">
          {/* email : surindergiri2@gmail.com  */}
        </div>

        <div className="col s2 right">
          <a href="#" style={{ color: '#ffffff' }}> <i className="material-icons left">call</i>
      905-930-7410</a>
        </div>
      </div>
      <nav>
        <div className="nav-wrapper navbar-own">
          <a href="http://www.protaxcanada.ca/" className="brand-logo" style={{ fontFamily: 'Lobster, cursive', marginLeft: '5px Georgia Serif' }}><img src={process.env.PUBLIC_URL + '/img/plogo.png'} width="300px" /></a>
          <a href="#" data-activates="mobile-demo" className="button-collapse"><i className="material-icons">menu</i></a>
          <ul className="right hide-on-med-and-down">
            <li><a href="https://www.protaxcanada.ca"> <i className="material-icons left">account_balance</i> Home </a></li>
            <li>
              <a href="https://www.protaxcanada.ca/services.php" className="dropdown-button" data-activates="dropdown1"><i className="material-icons left">perm_data_setting</i>Services<i className="material-icons right">arrow_drop_down</i></a>

              <ul id="dropdown1" className="dropdown-content">
                <li><a href="https://www.protaxcanada.ca/services.php">Personal Tax Return</a></li>
                <li className="divider"></li>
                <li><a href="https://www.protaxcanada.ca/services.php">Corporate Tax Return</a></li>
                <li className="divider"></li>
                <li><a href="https://www.protaxcanada.ca/services.php">Instant Cash Back</a></li>
                <li className="divider"></li>
                <li><a href="https://www.protaxcanada.ca/services.php">Book Keeping Services</a></li>
                <li className="divider"></li>
                <li><a href="https://www.protaxcanada.ca/services.php">RRSP's</a></li>
                <li className="divider"></li>
                <li><a href="https://www.protaxcanada.ca/services.php">Business Plan</a></li>
                <li className="divider"></li>
                <li><a href="https://www.protaxcanada.ca/services.php">Cash Flow Statements</a></li>
                <li className="divider"></li>
                <li><a href="https://www.protaxcanada.ca/services.php">Payroll Services</a></li>
                <li className="divider"></li>
                <li><a href="https://www.protaxcanada.ca/services.php">More</a></li>
                <li className="divider"></li>
              </ul>

            </li>
            <li><a href="https://www.protaxcanada.ca/rates.php"> <i className="material-icons left">attach_money</i> Rates</a></li>
            {/* <li><a href="taxtips.php"> <i className="material-icons left">assignment</i> Tax Tips</a></li> */}
            <li><a href="https://www.protaxcanada.ca/aboutus.php"> <i className="material-icons left">assignment_ind</i> About us</a></li>
            <li><a href="https://www.protaxcanada.ca/contact.php"> <i className="material-icons left">contact_phone</i> Contact us</a></li>
            {localStorage.getItem('protax_web_access_token') ?
              <>
                <li><Link to="/"><i className="material-icons left">dashboard</i> Dashboard </Link></li>
                <li onClick={logout}><a><i className="material-icons left">login</i> Logout</a></li>
              </>
              :
              <li><Link to="/login"><i className="material-icons left">login</i> Login </Link></li>
            }

          </ul>
          <ul className="side-nav" id="mobile-demo">
            <li><a href="https://www.protaxcanada.ca"> <i className="material-icons left">account_balance</i> Home</a></li>
            <li>
              <ul className="collapsible" data-collapsible="accordian">
                <li>
                  <a href="https://www.protaxcanada.ca/services.php" className="collapsible-header  waves-effect waves-teal"><i className="material-icons left">perm_data_setting</i>Services<i className="material-icons right">arrow_drop_down</i></a>
                  <div className="collapsible-body">
                    <ul>
                      <li><a href="https://www.protaxcanada.ca/services.php">Personal Tax Return</a></li>
                      <li className="divider"></li>
                      <li><a href="https://www.protaxcanada.ca/services.php">Corporate Tax Return</a></li>
                      <li className="divider"></li>
                      <li><a href="https://www.protaxcanada.ca/services.php">Instant Cash Back</a></li>
                      <li className="divider"></li>
                      <li><a href="https://www.protaxcanada.ca/services.php">Book Keeping Services</a></li>
                      <li className="divider"></li>
                      <li><a href="https://www.protaxcanada.ca/services.php">RRSP's</a></li>
                      <li className="divider"></li>
                      <li><a href="https://www.protaxcanada.ca/services.php">Business Plan</a></li>
                      <li className="divider"></li>
                      <li><a href="https://www.protaxcanada.ca/services.php">Cash Flow Statements</a></li>
                      <li className="divider"></li>
                      <li><a href="https://www.protaxcanada.ca/services.php">Payroll Services</a></li>
                      <li className="divider"></li>
                      <li><a href="https://www.protaxcanada.ca/services.php">More</a></li>
                      <li className="divider"></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </li>
            <li><a href="https://www.protaxcanada.ca/rates.php"><i className="material-icons left">attach_money</i> Rates</a></li>
            <li><a href="https://www.protaxcanada.ca/taxtips.php"> <i className="material-icons left">assignment</i> Tax Tips</a></li>
            <li><a href="https://www.protaxcanada.ca/aboutus.php"><i className="material-icons left">assignment_ind</i> About us </a></li>
            <li><a href="https://www.protaxcanada.ca/contact.php"> <i className="material-icons left">contact_phone</i> Contact us </a></li>
            {localStorage.getItem('protax_web_access_token') ?
              <>
                <li><Link to="/"><i className="material-icons left">dashboard</i> Dashboard </Link></li>
                <li onClick={logout}><a> <i className="material-icons left">login</i> Logout </a></li>
              </>
              :
              <li><Link to="/login"> <i className="material-icons left">r</i> Login </Link></li>
            }
          </ul>
          {/* Drop down Menu  */}
        </div>
      </nav>
    </>
  )
}
