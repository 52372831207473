import React, { useState, useEffect } from 'react';
import { Button, Image, Divider } from 'antd';
import NotFound from '../../../assets/notFound.png'
import { BASE_URL } from '../../../common/consts/Config';

export default function QuestionsForm(props) {

    let { checklist, title, setCurrent } = props;
    const [atleastOneChecked, setAtleastOneChecked] = useState(false);

    useEffect(() => {
        let oneChecked = false;
        for (const item of checklist) {
            if (item.checked) oneChecked = true;
        }
        if (oneChecked) setAtleastOneChecked(true);
    }, []);

    return (
        <>
            <div className="row" style={{ marginBottom: '10px' }}>
                <div className="col-12">
                    <h5 className="mb-0">{title}</h5>
                    {
                        !atleastOneChecked ? (
                            <>
                                <div>
                                    {`No ${title} Checked!!`}
                                </div>
                                <div 
                                    className="cursor-pointer"
                                    onClick={()=>{
                                        setCurrent(4);
                                    }}
                                >
                                    <b>Click Here</b> to Add Now!
                                </div>

                            </>
                        ) : null
                    }
                </div>
            </div>
            <div className="row">
                {
                    checklist.map((item, index) => {
                        if (item.checked) {
                            return (
                                <div className="col-12" key={index}>
                                    <div>
                                        {/* <span className="mr-2 text-bold">{index + 1}</span> */}
                                        {item.title}
                                    </div>
                                    <div className="text-bold">
                                        {item.checked ? 'Checked' : 'Not Checked'}
                                    </div>
                                    <div className="p-1 pb-3 px-3" style={{ overflowX: 'scroll' }} key={index}>
                                        {
                                            item.checked && item.type === 'inputs' && (
                                                item.inputs.map((inputDetails, ci) => {
                                                    return (
                                                        <div
                                                            className="row"
                                                            key={ci}
                                                        >
                                                            <div
                                                                className="col-12"
                                                            >
                                                                <div className="d-flex">
                                                                    <span className="text-bold">{inputDetails?.name || inputDetails?.placeholder}  -</span>
                                                                    <span>{` ${inputDetails?.name?.includes('Number')?'':'$'}${inputDetails.value}`}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            )
                                        }
                                        {
                                            item.checked && item?.type === 'images' && item?.images?.map((image, index) => {
                                                if (image.endsWith(".pdf")) {
                                                    return (
                                                        <Button
                                                            type="primary"
                                                            onClick={() => {
                                                                window.open(BASE_URL + image, '_blank');
                                                            }}
                                                            className="protax-btn mt-2"
                                                        >
                                                            View PDF
                                                        </Button>
                                                    )
                                                }
                                                else
                                                    return (
                                                        <Image
                                                            className="img-fluid mr-2"
                                                            key={index}
                                                            src={BASE_URL + image}
                                                            width={200}
                                                            onError={(e) => (e.target.onerror = null, e.target.src = NotFound)}
                                                        />
                                                    )
                                            })
                                        }

                                        {
                                            item?.type === 'child' && item?.childs?.map((child, ci) => {
                                                return (
                                                    <div className="col-12" key={ci}>
                                                        <div className="d-flex">
                                                            <span className="text-bold">{child.firstName} -</span>
                                                            <span>{` $${child.amount}`}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                        {
                                            item?.checked && item?.type === 'political' && (
                                                <div className="row">
                                                    {
                                                        item.typeOfContribution.map((cont, contInd) => {
                                                            if (cont.checked) {
                                                                return (
                                                                    <div
                                                                        className="col-12"
                                                                        key={contInd}
                                                                    >
                                                                        <div className="d-flex">
                                                                            <span className="text-bold">{cont.title}  -</span>
                                                                            <span>{` $${cont.amount}`}</span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            else return null
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                        else return null;
                    })
                }
            </div>
            <Divider />
        </>
    )
}
