export const prefillQuestions = [
    "Are you a Canadian Citizen ?",
    "Do you have an incorporated business?",
    "Are you currently in Bankruptcy Status?",
    "Did you work outside Canada or have foreign employment or foreign pension income?",
    "Are you eligible for the disability tax Credit?",
];

export const T776Fields = [
    {
        title: "Details of other co-owners and partners",
        fields: [{
            label: "Co-owner or partner's name and address",
            id: "t776COAd1",
            value: "",
            half: true,
        },
        {
            label: "Percentage of ownership",
            id: "t776COper1",
            value: "",
            half: true,
        },
        {
            label: "Co-owner or partner's name and address",
            id: "t776COAd2",
            value: "",
            half: true,
        },
        {
            label: "Percentage of ownership",
            id: "t776COper2",
            value: "",
            half: true,
        },
        {
            label: "Co-owner or partner's name and address",
            id: "t776COAd3",
            value: "",
            half: true,
        },
        {
            label: "Percentage of ownership",
            id: "t776COper3",
            value: "",
            half: true,
        },
        ],
    },{
        title: "Income",
        fields: [{
            label: "Addresses of your rental properties",
            id: "t776InAd1",
            value: "",
            half: true,
        },
        {
            label: "Gross rents",
            id: "t776InGR1",
            value: "",
            half: true,
        },
        ],
    }, {
        title: "Expenses",
        other: true,
        otherLabel: "Other expenses",
        otherFieldCId: "t776ExOtherEx",
        otherFields: [{
            label: "",
            value: "",
            id: "t776ExOtherField",
        }],
        fields: [{
            label: "Advertising",
            id: "t776ExAdv",
            value: "",
        },
        {
            label: "Insurance",
            id: "t776ExIns",
            value: "",
        },
        {
            label: "Interest and bank charges",
            id: "t776ExIntBnk",
            value: "",
        },
        {
            label: "Office expenses",
            id: "t776ExOE",
            value: "",
        },
        {
            label: "Professional fees (includes legal and accounting fees)",
            id: "t776ExProfFee",
            value: "",
        },
        {
            label: "Management and administration fees",
            id: "t776ExMgAdFee",
            value: "",
        },
        {
            label: "Repairs and maintenance",
            id: "t776ExRepMain",
            value: "",
        },
        {
            label: "Salaries, wages, and benefits (including employer's contributions)",
            id: "t776ExSalWagBen",
            value: "",
        },
        {
            label: "Property taxes",
            id: "t776ExPropTax",
            value: "",
        },
        {
            label: "Travel",
            id: "t776ExTravel",
            value: "",
        },
        {
            label: "Utilities",
            id: "t776ExUti",
            value: "",
        },
        {
            label: "Motor vehicle expenses (not including capital cost allowance) ",
            id: "t776ExMotorVeh",
            value: "",
        },
        ],
    }
];

export const T2125Fields = [{
    title: "Identification",
    fields: [{
        label: "Business number (GST/HST Number)",
        value: "",
        id: "t2125IdenBusNum",
    },
    {
        label: "Business Discription ",
        value: "",
        id: "t2125IdenBusDis",
    },
    ],
},
{
    title: "Business income",
    fields: [{
        label: "Gross sales, commissions, or fees (include GST/HST collected or collectible)",
        value: "",
        id: "t2125BsInGS",
    },
    {
        label: "GST/HST collected or collectible on sales",
        value: "",
        id: "t2125BsInRemRt",
    },
    ],
},
{
    title: "Professional income",
    fields: [{
        label: "Gross professional fees including  GST/HST collected or collectible",
        value: "",
        id: "t2125PrInGrProfFee",
    },
    {
        label: "GST/HST collected or collectible on sales",
        value: "",
        id: "t2125PrInGst",
    },
    ],
},
{
    title: "Cost of goods sold and gross profit",
    other: true,
    otherLabel: "Other costs",
    otherFieldCId: "t2125CostOGdSoldGPOC",
    otherFields: [{
        label: "",
        value: "",
        id: "t2125CostOGdSoldGPOC1",
    },],
    fields: [{
        label: "Purchases during the year",
        value: "",
        id: "t2125CostOGdSoldGPPurchase",
    },
    {
        label: "Direct wage costs",
        value: "",
        id: "t2125CostOGdSoldGPDirectWage",
    },
    {
        label: "Subcontracts",
        value: "",
        id: "t2125CostOGdSoldGPSubContracts",
    },
    ],
},
{
    title: "Operating Expenses",
    other: true,
    otherLabel: "Other costs",
    otherFieldCId: "t2125NILBAOC1",
    otherFields: [{
        label: "",
        value: "",
        id: "t2125NILBAOC1",
    },],
    fields: [{
        label: "Advertising",
        value: "",
        id: "t2125NILBAAdv",
    },
    {
        label: "Meals and entertainment",
        value: "",
        id: "t2125NILBAMelEnt",
    },
    {
        label: "Bad debts",
        value: "",
        id: "t2125NILBABdDebt",
    },
    {
        label: "Insurance",
        value: "",
        id: "t2125NILBAINS",
    },
    {
        label: "Interest and bank charges",
        value: "",
        id: "t2125NILBAIntBnkChrg",
    },
    {
        label: "Business taxes, licences, and memberships",
        value: "",
        id: "t2125NILBABsTxLiceMem",
    },
    {
        label: "Office expenses",
        value: "",
        id: "t2125NILBAOffExp",
    },
    {
        label: "Office stationery and supplies",
        value: "",
        id: "t2125NILBAOffStatSupp",
    },
    {
        label: "Professional fees (includes legal and accounting fees)",
        value: "",
        id: "t2125NILBAProffFee",
    },
    {
        label: "Management and administration fees",
        value: "",
        id: "t2125NILBAMngmtAdminFee",
    },
    {
        label: "Rent",
        value: "",
        id: "t2125NILBARnt",
    },
    {
        label: "Repairs and maintenance",
        value: "",
        id: "t2125NILBARepr",
    },
    {
        label: "Salaries, wages, and benefits (including employer's contributions)",
        value: "",
        id: "t2125NILBASalWagBen",
    },
    {
        label: "Property taxes",
        value: "",
        id: "t2125NILBAPropTax",
    },
    {
        label: "Travel expenses",
        value: "",
        id: "t2125NILBATrvlExp",
    },
    {
        label: "Utilities",
        value: "",
        id: "t2125NILBAUti",
    },
    {
        label: "Fuel costs (except for motor vehicles)",
        value: "",
        id: "t2125NILBAFuelCost",
    },
    {
        label: "Delivery, freight, and express",
        value: "",
        id: "t2125NILBADlvFrieght",
    },
    {
        label: "Motor vehicle expenses (not including CCA) (amount 16 of Chart A)",
        value: "",
        id: "t2125NILBAMotorVehExp",
    },
    ],
},
{
    title: "Detailed Motor vehicle expenses",
    other: true,
    expand: true,
    otherLabel: "Other expenses (specify):",
    otherFieldCId: "t2125MtrVehExpOE",
    otherFields: [{
        label: "",
        value: "",
        id: "t2125MtrVehExpOE1",
    },],
    fields: [{
        label: "Kilometres you drove in the fiscal period that was part of earning business income",
        value: "",
        id: "t2125MtrVehExpKmBussInc",
    },
    {
        label: "Total kilometres you drove in the fiscal period",
        value: "",
        id: "t2125MtrVehExpTtlKmFP",
    },
    {
        label: "Fuel and oil",
        value: "",
        id: "t2125MtrVehExpFuelOil",
    },
    {
        label: "Interest (use Chart B below)",
        value: "",
        id: "t2125MtrVehExpInt",
    },
    {
        label: "Insurance",
        value: "",
        id: "t2125MtrVehExpInsc",
    },
    {
        label: "Licence and registration",
        value: "",
        id: "t2125MtrVehExpLcsReg",
    },
    {
        label: "Maintenance and repairs",
        value: "",
        id: "t2125MtrVehExpMntncRep",
    },
    {
        label: "Leasing (use Chart C below)",
        value: "",
        id: "t2125MtrVehExpLsc",
    },
    {
        label: "Electricity for zero-emission vehicles",
        value: "",
        id: "t2125MtrVehExpElecZeroVehcl",
    },
    ],
},
{
    title: "Calculating business-use-of-home expenses",
    other: true,
    expand: true,
    otherLabel: "Other expenses",
    otherFieldCId: "t2125CalcBussExpOE1",
    otherFields: [{
        label: "",
        value: "",
        id: "t2125CalcBussExpOE1",
    },],
    fields: [{
        label: "Heat",
        value: "",
        id: "t2125CalcBussExpHeat",
    },
    {
        label: "Electricity",
        value: "",
        id: "t2125CalcBussElec",
    },
    {
        label: "Insurance",
        value: "",
        id: "t2125CalcBussIns",
    },
    {
        label: "Maintenance",
        value: "",
        id: "t2125CalcBussMntnc",
    },
    {
        label: "Mortgage interest",
        value: "",
        id: "t2125CalcBussExpMortInt",
    },
    {
        label: "Property taxes",
        value: "",
        id: "t2125CalcBussExpPropTax",
    },
    ],
},
];

export const T777Fields = [{
    title: "Expenses",
    other: true,
    otherLabel: "Other expenses (employment use of a cell phone, long distance calls for employment purposes, etc.) (9270)",
    otherFieldCId: "t777ExpsOE",
    otherFields: [{
        label: "",
        value: "",
        id: "t777ExpsOE1",
    },],
    fields: [{
        label: "Accounting and legal fees (8862)",
        value: "",
        id: "t777ExpsAccLglFee",
    },
    {
        label: "Advertising and promotion (8520)",
        value: "",
        id: "t777ExpsAdvProm",
    },
    {
        label: "Allowable motor vehicle expenses (from line 30 below) (9281)",
        value: "",
        id: "t777ExpsAllMtrVh",
    },
    {
        label: "Food, beverages, and entertainment expenses (8523)",
        value: "",
        id: "t777ExpsFdBevEnt",
    },
    {
        label: "Lodging (9200)",
        value: "",

        id: "t777ExpsLod",
    },
    { label: "Parking (8910)", value: "", id: "t777ExpsPrk" },
    {
        label: "Office supplies (postage, stationery, ink cartridge, etc.) (8810)",
        value: "",
        id: "t777ExpsOffSupp",
    },
    {
        label: "Tradesperson's tools expenses (maximum $500) (1770)",
        value: "",
        id: "t777ExpsTradToolExp",
    },
    {
        label: "Apprentice mechanic tools expenses (9131)",
        value: "",
        id: "t777ExpsApprtMechTool",
    },
    {
        label: "Musical instrument expenses (1776)",
        value: "",
        id: "t777ExpsMusicInst",
    },
    {
        label: "Artists' employment expenses (9973)",
        value: "",
        id: "t777ExpsArtisEmplExp",
    },
    ],
},
{
    title: "Calculation of allowable motor vehicle expenses",
    fields: [{
        label: "Enter the year, make and model of motor vehicle used to earn employment income.",
        value: "",
        id: "t777CalcMtrVehExpVehEmpInc",
    },
    {
        label: "Enter the number of kilometres you drove in the tax year to earn employment income.",
        value: "",
        id: "t777CalcMtrVehExpKmEmpInc",
    },
    {
        label: "Enter the total number of kilometres you drove in the tax year.",
        value: "",
        id: "t777CalcMtrVehExpKminTxYr",
    },
    {
        label: "Enter the motor vehicle expenses you paid for:",
        type: "sub",
        subFields: [{
            other: true,
            otherLabel: "Other expenses. Specify:",
            otherFieldCId: "t777MtrVehExpOE",
            otherFields: [{
                label: "",
                value: "",
                id: "t777MtrVehExpOE1",
            },],
            title: "Enter the motor vehicle expenses you paid for:",
            fields: [{
                label: "Fuel (gasoline, propane, oil)",
                value: "",
                id: "t777MtrVehExpFuel",
            },
            {
                label: "Maintenance and repairs",
                value: "",
                id: "t777MtrVehExpMntRpr",
            },
            { label: "Insurance", value: "", id: "t777MtrVehExpInsc" },
            {
                label: "Licence and registration",
                value: "",
                id: "t777MtrVehExpLscReg",
            },
            {
                label: "Capital cost allowance (see Parts A and B of this form)",
                value: "",
                id: "t777MtrVehExpCapCostAll",
            },
            { label: "Interest", value: "", id: "t777MtrVehExpIntrst" },
            { label: "Leasing", value: "", id: "t777MtrVehExpLeasing" },
            ],
        },],
    },
    ],
},
{
    title: "Calculation of work-space-in-the-home expenses",
    other: true,
    otherLabel: "Other expenses (rent, etc.) Specify:",
    otherFieldCId: "t777CalcWSPINHOE",
    otherFields: [{
        label: "",
        value: "",
        id: "t777CalcWSPINHOE1",
    },],
    fields: [{
        label: "Electricity, heat, water, home Internet access fees",
        id: "t777CalcWSPINHElecHeatWat",
        value: "",
    },
    {
        label: "Maintenance (cleaning supplies, light bulbs, etc.)",
        id: "t777CalcWSPINHMnt",
        value: "",
    },
    {
        label: "Home insurance (commission employees only)",
        id: "t777CalcWSPINHHomIns",
        value: "",
    },
    {
        label: "Property taxes (commission employees only)",
        id: "t777CalcWSPINHPropTaxes",
        value: "",
    },
    ],
},
];