import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, message, Button, Table, Form } from 'antd';
import Helper from '../../common/consts/Helper';
import { toast } from 'react-toastify';
import { PHOTO_URL } from '../../common/consts/Config';

export default function Document(props) {

    const [doucmentUpload, setDocumentUpload] = useState([]);
    const [isLoading, setIsLoading] = useState({
        loading: false,
        index: null
    });

    let order = props?.history?.location?.state?.order || {};

    useEffect(() => {
        if (order) {
            let newDocuments = [];
            let checklistsToCheck = [
                ...order.otherDocChecklist,
                ...order.spouseOtherDocChecklist
            ]

            for (const list of checklistsToCheck) {
                if (list?.showEmailButton && list?.checked) {
                    newDocuments.push({
                        name: `${list.title}`,
                        file: getOrderDocument(list?.documentType),
                        documentType: list?.documentType,
                        isAlreadyPresent: getOrderDocument(list?.documentType) ? true : false
                    });
                }
            }
            setDocumentUpload(newDocuments);
        }
        else props.history.push('/orders')
    }, [order]);

    function getOrderDocument(documentType) {
        let myDocVar = `orderDocument${documentType}`;
        let orderDocumentExist = null;
        if (order[myDocVar]) {
            orderDocumentExist = {
                name: order[myDocVar]
            }
        }
        return orderDocumentExist;
    }

    return (
        <>
            <Row className="py-5">
                <Col xs={{ span: 20, offset: 2 }}>
                    <div className="d-flex align-items-center mb-2">
                        <i
                            className="fas fa-chevron-left mr-2 fa-lg cursor-pointer p-1"
                            onClick={() => { props.history.goBack(); }}
                        ></i>
                        <h5 className="text-bold mb-0">Upload Documents</h5>
                    </div>
                </Col>
                <Col xs={{ span: 20, offset: 2 }}>
                    <Form
                        name="dynamic_rule"
                    >
                        <Row className="mt-2">
                            {
                                doucmentUpload.map((doc, index) => {
                                    return (
                                        <Fragment
                                            key={index}
                                        >
                                            <Col
                                                xs={{ span: 22, offset: 1 }}
                                                md={{ span: 20, offset: 0 }}
                                                className="mt-1 btn-small"
                                            >
                                                <label>Upload - {doc.name}</label>
                                                <div className="file-field input-field">
                                                    <div className="btn">
                                                        <span>Choose</span>
                                                        <input
                                                            type="file"
                                                            accept="application/pdf"
                                                            disabled={doc?.isAlreadyPresent}
                                                            onChange={(e) => {
                                                                if (e.target.files && e.target.files?.length > 0) {
                                                                    let file = e.target.files[0];
                                                                    let newDocumentsToUpload = doucmentUpload.slice();
                                                                    newDocumentsToUpload[index].file = file;
                                                                    setDocumentUpload(newDocumentsToUpload);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="file-path-wrapper">
                                                        <input
                                                            className="file-path validate"
                                                            type="text"
                                                            value={doc?.file?.name || doc?.file || ''}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={{ span: 24 }} md={{ span: 4 }} className="d-flex justify-content-end align-items-center">
                                                <Button
                                                    type="primary"
                                                    className="protax-btn"
                                                    loading={isLoading.loading && isLoading.index === index}
                                                    onClick={() => {
                                                        if (!doc?.isAlreadyPresent) {
                                                            if (doc?.file) {
                                                                uploadDocument(index, doc.documentType)
                                                            }
                                                            else {
                                                                toast.error("Please choose a file to continue!")
                                                            }
                                                        } else {
                                                            window.open(PHOTO_URL+doc?.file?.name, '_blank')
                                                        }
                                                    }}
                                                >
                                                    {
                                                        !doc?.isAlreadyPresent ? 'Upload' : 'View'
                                                    }
                                                </Button>
                                            </Col>
                                        </Fragment>
                                    )
                                })
                            }
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    )
    function uploadDocument(index, docType) {
        setIsLoading({
            ...isLoading,
            loading: true,
            index
        })
        let body = new FormData();
        body.append('orderId', order?._id);
        body.append('documentType', docType);
        body.append('document', doucmentUpload[index].file)
        let res = Helper('upload_order_document', 'POST', body, true);
        res.then((response) => {
            if (response?.error) {
                toast.error(response?.error?.toString());
            }
            else {
                props.history.goBack();
                toast.success(`Document Updated - ${doucmentUpload[index]?.name}!!`);
            }
        })
            .catch((error) => null)
            .finally(() => {
                setIsLoading({
                    ...isLoading,
                    loading: false,
                    index: null
                })
            })
    }
}