import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Radio,
  DatePicker,
  Row,
  Col,
  Card,
  Button,
  Checkbox,
} from "antd";

//Redux imports
import { connect } from "react-redux";
import {
  handleChange,
  resetPickupState,
  handleMultipleChange,
} from "../../../actions/PickupActions";
import {
  updateOrderBreakup,
  updateSales,
  setSalesFetched,
} from "../../../actions/OrderActions";

//constants
import Helper from "../../../common/consts/Helper";
import { toast } from "react-toastify";

//images
import PaymentImage from "../../../assets/paymentloading.png";

function StepTwo(props) {
  const {
    handleChange,
    handleMultipleChange,

    setCurrent,

    PickupParams,
    resetPickupState,
    baseOrder,
    sales,
    updateOrderBreakup,
    editBaseOrder,

    updateSales,
    setSalesFetched,
    isSalesFetched,

    setSaveOrderResponse,
    setEditOrderId,
  } = props;

  //states
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [previousModalVisible, setPreviousModalVisible] = useState(false);
  const [disclamerModalVisible, setDisclamerModalVisible] = useState(false);

  const [discount, setDiscount] = useState(0);
  const [collegeDetails, setCollegeDetails] = useState({
    imageUrl: "",
    name: "",
  });

  //payment gateway params
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [paymentToken, setPaymentToken] = useState(null);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);

  const [tax, setTax] = useState(false);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [terms2Accepted, setTerms2Accepted] = useState(false);

  function getSales() {
    let res = Helper("v4/sales", "GET");
    res
      .then((response) => {
        if (response) {
          if (response?.error) {
            setSalesFetched(false);
            // alert("We are facing some technical issues, Please try again later!!")
          } else {
            setSalesFetched(true);
            updateSales(response);
          }
        } else {
          // alert("We are facing some technical issues, Please try again later!!")
        }
      })
      .catch((error) => {
        setSalesFetched(false);
        // alert("We are facing some technical issues, Please try again later!!");
      });
  }

  const onFinish = (values) => {
    handleMultipleChange(values);
    setCurrent((previousCurrent) => previousCurrent + 1);
  };

  function getAmountToTransact() {
    let transactions = PickupParams.isEdit
      ? PickupParams?.payment?.slice() || []
      : [];
    let totalPaid = 0;
    let PaymentAmount = 0;
    for (const transaction of transactions) {
      if (
        transaction.status === "succeeded" &&
        transaction.paymentStatus !== 2
      ) {
        totalPaid += +transaction.amount;
      }
    }

    totalPaid = +totalPaid / 100;
    PaymentAmount = +total - +totalPaid - +discount;

    return PaymentAmount?.toFixed(2);
  }
  function getAmountPaidAlready() {
    let transactions = PickupParams.isEdit
      ? PickupParams?.payment?.slice() || []
      : [];
    let totalPaid = 0;
    for (const transaction of transactions) {
      if (
        transaction.status === "succeeded" &&
        transaction.paymentStatus !== 2
      ) {
        totalPaid += +transaction.amount;
      }
    }

    totalPaid = +totalPaid / 100;
    return totalPaid?.toFixed(2);
  }

  useEffect(() => {
    // getOrderDetails();
    getSales();
    fetchDiscountDetails();
  }, []);

  useEffect(() => {
    if (Object.keys(sales)?.length > 0) {
      getOrderDetails();
    }
  }, [sales]);

  function getOrderDetails() {
    let {
      slipsChecklist,
      spouseSlipsChecklist,
      spouseReceiptsChecklist,
      receiptsChecklist,
      spouseOtherDocChecklist,
      otherDocChecklist,
      questions,
    } = PickupParams;

    //users checklists
    let myChecklists = [
      ...slipsChecklist,
      ...receiptsChecklist,
      ...otherDocChecklist,
    ];

    //spouse checklists
    let spouseChecklists = [
      ...spouseSlipsChecklist,
      ...spouseReceiptsChecklist,
      ...spouseOtherDocChecklist,
    ];

    //empty array of order
    let newBaseOrders = [];

    //user base charge
    newBaseOrders = [
      {
        title: "Personal Tax Return",
        description: "Base Charge",
        charge: sales["Personal Tax Return"]?.price || "49.99",
        numberOfItems: "1",
        amount: sales["Personal Tax Return"]?.price,
        spouse: false,
      },
    ];
    if (PickupParams.fillingForSpouse) {
      //if filling for spuose then spouse's base charge
      newBaseOrders.push({
        title: "Personal Tax Return",
        description: "Base Charge",
        charge: sales["Personal Tax Return"]?.price || "49.99",
        numberOfItems: "1",
        amount: sales["Personal Tax Return"]?.price || "49.99",
        spouse: true,
      });
    }
    for (const checklist of myChecklists) {
      //users checklists
      if (checklist.checked && sales[checklist.title]) {
        //if checklist is checked && exists in sales object
        if (sales[checklist.title].inputBased) {
          //if sale is input based
          newBaseOrders.push({
            title: checklist.title,
            description: "Charge",
            charge: sales[checklist.title].price,
            numberOfItems: checklist.inputs[0].value,
            amount: +checklist.inputs[0].value * +sales[checklist.title].price, //updating the amount acc to input
            spouse: false,
          });
        } else {
          //sale is not input based
          newBaseOrders.push({
            title: checklist.title,
            description: "Charge",
            charge: sales[checklist.title].price,
            numberOfItems: "1",
            amount: sales[checklist.title].price, //updating the fixed price
            spouse: false,
          });
        }
      }
    }
    for (const question of questions) {
      for (const row of question.questions) {
        if (question.checked && sales[row.question]) {
          if (
            sales[row.question].inputBased &&
            sales[row.question].inputBasedType === 2
          ) {
            const tempQuesObject = {
              title: question.messageForCharge,
              description: "Charge",
              charge: +sales[row.question].price,
              numberOfItems: question.inputValue,
              amount: +question.inputValue * +sales[row.question].price, //updating the amount acc to input
              spouse: false,
            };
            if (question.spouseChecked) {
              let newTempQuesObj = { ...tempQuesObject };
              newBaseOrders.push({ ...tempQuesObject });
              newTempQuesObj.spouse = true;
              newBaseOrders.push({ ...newTempQuesObj });
            } else {
              let newTempQuesObj = { ...tempQuesObject };
              newBaseOrders.push({ ...newTempQuesObj });
            }
          } else {
            const tempQuesObject = {
              title: question.messageForCharge,
              description: "Charge",
              charge: +sales[row.question].price,
              numberOfItems: "1",
              amount: +sales[row.question].price, //updating the amount acc to input
              spouse: false,
            };
            if (question.spouseChecked) {
              let newTempQuesObj = { ...tempQuesObject };
              newBaseOrders.push({ ...tempQuesObject });
              newTempQuesObj.spouse = true;
              newBaseOrders.push({ ...newTempQuesObj });
            } else {
              let newTempQuesObj = { ...tempQuesObject };
              newBaseOrders.push({ ...newTempQuesObj });
            }
          }
        }
      }
    }
    if (PickupParams.fillingForSpouse) {
      //filling for spouse also
      for (const checklist of spouseChecklists) {
        if (checklist.checked && sales[checklist.title]) {
          //checklist is checked and checklist exists in sales
          if (sales[checklist.title].inputBased) {
            //checklist is input based
            newBaseOrders.push({
              title: checklist.title,
              description: "Charge",
              charge: sales[checklist.title].price,
              numberOfItems: checklist.inputs[0].value,
              amount:
                +checklist.inputs[0].value * +sales[checklist.title].price, //updating the price according to input
              spouse: true,
            });
          } else {
            //checklist is not input based
            newBaseOrders.push({
              title: checklist.title,
              description: "Charge",
              charge: sales[checklist.title].price,
              numberOfItems: "1",
              amount: sales[checklist.title].price, //upadting the fixed price
              spouse: true,
            });
          }
        }
      }
    }

    let total = 0;
    for (const order of newBaseOrders) {
      total += +order.amount;
    }

    const tax = ((+sales["tax"]?.price || 13) / 100) * total;

    const finalTax = tax?.toFixed(2);
    const finalTotal = total.toFixed(2);

    newBaseOrders.push({
      title: "Tax",
      description: `Charge HST ${sales["tax"]?.price}%`,
      charge: finalTax,
      numberOfItems: "1",
      amount: finalTax, //upadting the fixed price
      spouse: true,
      tax: true,
    });

    setTotal(+finalTotal + +finalTax);

    updateOrderBreakup([...newBaseOrders]);
  }

  function getPreviousBillTotal() {
    let total = 0;
    for (const order of editBaseOrder) {
      total += +order.amount;
    }
    return total.toFixed(2);
  }

  function getPreviousBillTotal() {
    let total = 0;
    for (const order of editBaseOrder) {
      total += +order.amount;
    }
    return total.toFixed(2);
  }
  function submitForm(noPayment) {
    let url = PickupParams.isEdit ? "v2/edit_order" : "order";
    let method = PickupParams.isEdit ? "PUT" : "POST";
    setIsLoading(true);
    let body = {};
    if (PickupParams.isEdit) {
      body = {
        ...PickupParams,
        isStaffView: true,
        isAdminView: true,
        baseOrder: {
          createdAt: new Date(),
          order: baseOrder,
          currentTotal: total - getPreviousBillTotal(),
          overallTotal: total,
          isRefund: total - getPreviousBillTotal() <= 0 ? true : false,
          discount: PickupParams?.baseOrder?.discount || 0,
        },
        pickupId: PickupParams._id,
        cancelledOrder: [
          ...PickupParams.cancelledOrder,
          {
            createdAt: new Date(),
            order: editBaseOrder,
          },
        ],
        totalAmount: +total,
        source: "web",
      };
    } else {
      body = {
        ...PickupParams,
        baseOrder: {
          createdAt: new Date(),
          order: baseOrder,
          currentTotal: total - getPreviousBillTotal(),
          overallTotal: total,
          isRefund: false,
          discount: discount || 0,
        },
        totalAmount: +total,
        source: "web",
      };
    }

    if (!body.fillingForSpouse)
      body.spouseInfo = {
        ...body.spouseInfo,
        disabled: false,
        postSecondaryStudent: false,
      };
    let res = Helper(url, method, body);
    res
      .then(async (response) => {
        if (response.error) {
          toast.error(
            typeof response.error === "string"
              ? response.error
              : "Something went wrong, Please try again later!!",
            {
              position: toast.POSITION.BOTTOM_CENTER,
            }
          );
        } else {
          if (PickupParams?.isEdit) {
            setEditOrderId(PickupParams?._id);
          }
          await fetchJson();
          localStorage.removeItem("_pickupState");
          // resetPickupState();// this is the problem
          if (!noPayment) {
            setCurrent((perviousCurrent) => perviousCurrent + 1);
            // handleCardPayPress(PickupParams.isEdit ? PickupParams._id : response.pickupRequestInfo._id);
            setSaveOrderResponse({
              response: response,
              total: Math.ceil(+getAmountToTransact() * 100),
            });
          } else {
            toast.success("Order Edited Successfully!!");
            // handleChange('isEdit', false);
            props.history.push("/orders");
          }
        }
      })
      .catch((error) => {
        toast.error("Something went wrong, Please try again later!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function paymentAlert() {
    let confirmation = window.confirm(
      "Please make sure that you have entered your information correctly. Once you proceed, you won’t be able to edit the information. Do you want to continue?"
    );
    if (confirmation) {
      submitForm(getAmountToTransact() <= 0 ? true : false);
    }
  }

  function fetchJson() {
    let res = Helper("v4/read_json", "GET");
    return res
      .then((response) => {
        if (response?.data) {
          handleMultipleChange({
            ...response.data,
            isOldData: false,
          });
        }
      })
      .catch(() => {});
  }

  const baseOrderYour = baseOrder.filter((e) => !e.spouse && !e.tax);
  const baseOrderSpouse = baseOrder.filter((e) => e.spouse && !e.tax);
  const taxOrderTotal = baseOrder.filter((e) => e.tax);

  if (!isSalesFetched) {
    return (
      <Row
        style={{
          marginTop: "50px",
          marginBottom: "50px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Col lg={{ span: 16 }} md={{ span: 20 }}>
          <div className="d-flex justify-content-center flex-column align-items-center">
            <img src={PaymentImage} className="w-50" width="50%" />
          </div>
          <div className="d-flex justify-content-center flex-column align-items-center">
            <h5>Please wait, While we are Calculating your Payment Details!</h5>
          </div>
        </Col>
      </Row>
    );
  } else
    return (
      <>
        <Row
          style={{
            marginTop: "50px",
            marginBottom: "50px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Col lg={{ span: 16 }} md={{ span: 20 }}>
            <Card
              title="Payment"
              headStyle={{
                background: "#b0000b",
                color: "#fff",
                borderRadius: "12px 12px 0px 0px",
                fontSize: "22px",
              }}
              style={{ borderRadius: "12px" }}
            >
              {baseOrderYour.length !== 0 ? (
                <Row gutter={24}>
                  <Col xs={24}>
                    <div className="text-bold">Your Charges</div>
                  </Col>
                </Row>
              ) : null}
              {baseOrderYour.map((order, index) => {
                return (
                  <Row className="row mt-2" key={index}>
                    <Col xs={12}>
                      <div className="text-capitalize">{order.title}</div>
                      <div className="text-grey">
                        {order.description}{" "}
                        {+order.numberOfItems > 1
                          ? `(${order.numberOfItems} x $${order.charge})`
                          : ""}
                      </div>
                    </Col>
                    <Col xs={12} className="text-right">
                      <span className="text-bold">${order.amount}</span>
                    </Col>
                  </Row>
                );
              })}
              {baseOrderSpouse.length !== 0 ? (
                <Row gutter={24}>
                  <Col xs={24}>
                    <div className="text-bold">Spouse Charges</div>
                  </Col>
                </Row>
              ) : null}
              {baseOrderSpouse.map((order, index) => {
                return (
                  <Row gutter={24} key={index} className="mt-2">
                    <Col xs={12} className="text-left">
                      <div className="text-capitalize">{order.title}</div>
                      <div className="text-grey">
                        {order.description}{" "}
                        {+order.numberOfItems > 1
                          ? `(${order.numberOfItems} x $${order.charge})`
                          : ""}
                      </div>
                    </Col>
                    <Col xs={12} className="text-right">
                      <span className="text-bold">${order.amount}</span>
                    </Col>
                  </Row>
                );
              })}

              {taxOrderTotal.length !== 0 ? (
                <Row gutter={24}>
                  <Col xs={24}>
                    <div className="text-bold">Tax Charges</div>
                  </Col>
                </Row>
              ) : null}
              {taxOrderTotal.map((order, index) => {
                return (
                  <Row gutter={24} key={index} className="mt-2">
                    <Col xs={12} className="text-left">
                      <div className="text-capitalize">{order.title}</div>
                      <div className="text-grey">
                        {order.description}{" "}
                        {+order.numberOfItems > 1
                          ? `(${order.numberOfItems} x $${order.charge})`
                          : ""}
                      </div>
                    </Col>
                    <Col xs={12} className="text-right">
                      <span className="text-bold">${order.amount}</span>
                    </Col>
                  </Row>
                );
              })}

              {editBaseOrder.length > 0 && (
                <>
                  <Row gutter={24} className="mt-2">
                    <Col xs={12} className="text-left">
                      <div className="text-capitalize">Current</div>
                    </Col>
                    <Col xs={12} className="text-right">
                      <span className="text-bold">${total?.toFixed(2)}</span>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={12} className="text-left">
                      <div className="text-capitalize">Paid Already</div>
                    </Col>
                    <Col xs={12} className="text-right">
                      <span className="text-bold">
                        - ${+getAmountPaidAlready()}
                      </span>
                    </Col>
                  </Row>
                </>
              )}

              {discount && discount > 0 ? (
                <Row gutter={24} className="mt-2">
                  <Col xs={12} className="text-left">
                    <div className="text-capitalize">Discount</div>
                  </Col>
                  <Col xs={12} className="text-right">
                    <span className="text-bold">- ${discount}</span>
                  </Col>
                </Row>
              ) : null}

              <Row gutter={24} className="mt-2">
                <Col xs={12} className="text-left">
                  <div className="text-capitalize fa-lg text-bold">TOTAL</div>
                </Col>
                <Col xs={12} className="text-right">
                  <span className="fa-lg text-bold">
                    ${getAmountToTransact()}
                  </span>
                </Col>
              </Row>

              {collegeDetails &&
              collegeDetails?.name &&
              collegeDetails?.imageUrl ? (
                <Row gutter={24} className="mt-2" align="middle">
                  <Col xs={12} className="text-left">
                    <div className="text-capitalize fa-lg text-bold">
                      <img
                        src={collegeDetails?.imageUrl}
                        className="img-fluid"
                        width="150"
                      />
                    </div>
                  </Col>
                  <Col xs={12} className="text-right">
                    <span className="fa-lg text-bold">
                      {collegeDetails?.name}
                    </span>
                  </Col>
                </Row>
              ) : null}

              <Row gutter={24} className="mt-2">
                <Col xs={{ span: 24 }}>
                  <Checkbox
                    checked={termsAccepted}
                    onChange={(e) => {
                      setTermsAccepted(e.target.checked);
                    }}
                  >
                    <span className="text-justify">
                      By Checking this box, you agree to Protax Canada's
                      <b>
                        <a
                          target="_blank"
                          className="text-dark"
                          href="https://www.protaxcanada.ca/privacypolicy.php"
                        >
                          {" "}
                          Privacy policy
                        </a>
                      </b>
                      ,
                      <b>
                        <a
                          target="_blank"
                          className="text-dark"
                          href="https://www.protaxcanada.ca/termsandconditions.php"
                        >
                          {" "}
                          Terms and Conditions
                        </a>
                      </b>{" "}
                      and
                      <b>
                        <a
                          target="_blank"
                          className="text-dark"
                          href="https://www.protaxcanada.ca/refundpolicy.php"
                        >
                          {" "}
                          Refund Policy
                        </a>
                      </b>
                      . You also accept that you have read the legal Disclaimer
                      and agree to that.
                    </span>
                  </Checkbox>
                </Col>
                <Col xs={{ span: 24 }}>
                  <Checkbox
                    checked={terms2Accepted}
                    onChange={(e) => {
                      setTerms2Accepted(e.target.checked);
                    }}
                  >
                    <span className="text-justify">
                      By checking this Box your placing an order and perimitting
                      Protax Canada to pre-authorize your card with the above
                      amount.
                    </span>
                  </Checkbox>
                </Col>
              </Row>

              <Row gutter={24} className="mt-2">
                <Col xs={{ span: 24 }}>
                  <Button
                    type="dashed"
                    className="mr-2 protax-back-btn"
                    onClick={() => {
                      setCurrent((previousCurrent) => previousCurrent - 1);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    loading={isLoading || !isSalesFetched}
                    disabled={!termsAccepted || !terms2Accepted}
                    className="protax-btn"
                    onClick={() => {
                      paymentAlert();
                    }}
                  >
                    {getAmountToTransact() <= 0 ? "Submit" : "Pay Now"}
                  </Button>
                </Col>
              </Row>

              {getAmountToTransact() < 0 && (
                <Row gutter={24}>
                  <Col xs={{ span: 24 }}>
                    <div className="refund-box">
                      Since your card is pre-authorized no funds have been
                      withdrawn, card will be charged with current amount, any
                      extra pre-authorized amount will be release.
                    </div>
                  </Col>
                </Row>
              )}
            </Card>
          </Col>
        </Row>
      </>
    );

  function fetchDiscountDetails() {
    setIsLoading(true);
    let res = Helper("user_detail", "GET");
    res
      .then((response) => {
        if (response && response.userInfo) {
          let { collegeName, collegeImage, discount } = response.userInfo[0];
          if (collegeName || collegeImage) {
            setCollegeDetails({
              ...collegeDetails,
              name: collegeName || "",
              imageUrl: collegeImage || "",
            });
          }
          if (discount) {
            if (!PickupParams?.isEdit) {
              setDiscount(discount);
            } else {
              setDiscount(PickupParams?.baseOrder?.discount || 0);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
}

const mapStateToProps = () => (state) => ({
  PickupParams: state.Pickup,
  baseOrder: state.Order.baseOrder,
  sales: state.Order.sales,
  editBaseOrder: state.Order.editBaseOrder,
  isSalesFetched: state.Order.isSalesFetched,
});

const mapDispatchToProps = () => (dispatch) => ({
  handleChange: (name, value) => dispatch(handleChange(name, value)),
  resetPickupState: () => dispatch(resetPickupState()),
  updateOrderBreakup: (order) => dispatch(updateOrderBreakup(order)),
  updateSales: (sales) => dispatch(updateSales(sales)),
  setSalesFetched: (flag) => dispatch(setSalesFetched(flag)),
  handleMultipleChange: (state) => dispatch(handleMultipleChange(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
