import React from 'react';
import { Steps, Popover, Row, Col } from 'antd';
import {useParams} from 'react-router-dom';

const { Step } = Steps;

const customDot = (dot, other) => {
  return (
    <Popover
      content={
        <span>
          Status: <b>{other.title}</b>
        </span>
      }
    >
      {dot}
    </Popover>
  )
};

const steps = [
  {
    title: 'Pending',
    back: 1,
    description: 'Your order has Pending Status.'
  },
  {
    title: 'Work in progress',
    back: 1,
    description: 'Your order is in progress.'
  },
  {
    title: 'Completed',
    back: 1,
    description: 'Your order has been Completed.'
  },
  {
    title: 'File E Filed',
    back: 1,
    description: 'File E Filed'
  },
]

export default function Status(props) {

  const {statusLimit} = useParams();

  return (
    <div className="py-5">
      <Row className="py-5">
        <Col xs={{ span: 20, offset: 2 }}>
          <h4 className="text-bold">Tax Return Status</h4>
        </Col>
      </Row>
      <Row style={{ padding: '5%' }}>
        <Col xs={{ span: 20, offset: 2 }}>
          <Steps
            current={(+statusLimit-1) || 0}
            progressDot={customDot}
          >
            {
              steps.map((step, index) => {
                return (
                  <Step
                    title={step.title}
                    key={index}
                    description={step.description}
                  />
                )
              })
            }
          </Steps>
        </Col>
      </Row>
    </div>
  )
}
