import moment from "moment";

export function getTransactionTitle(payment, totalAmount, baseOrder) {
  let AmountToTransact = +getAmountToTransact(payment, totalAmount, baseOrder);
  if (+AmountToTransact === 0) return "Transaction Info";
  else if (+AmountToTransact < 0) return "Refund Info";
  else if (+AmountToTransact > 0) return "Pay Now";
}

export function getAmountToTransact(payment, totalAmount, baseOrder) {
  let transactions = payment?.slice() || [];
  let totalPaid = 0;
  let PaymentAmount = 0;
  for (const transaction of transactions) {
    if (transaction.status === "succeeded" && transaction.paymentStatus !== 2) {
      totalPaid += +transaction.amount;
    }
  }
  totalPaid = +totalPaid / 100;
  PaymentAmount = +totalAmount - +totalPaid - +(baseOrder?.discount || 0);
  return PaymentAmount?.toFixed(2);
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function checkDateRangeMonth(tStamp) {
  let withinDateRange = false;
  const timeStampForPickupState = moment(tStamp);
  const dateDiff = moment(moment()).diff(timeStampForPickupState, "days");
  if (dateDiff < 30) {
    withinDateRange = true;
  }
  return withinDateRange;
}

export function checkIfOrderFromOtherPlatform(order) {
  let isOrderEditable = false;
  let isOrderBlocked = false;

  // orders done from other platforms should be not editable
  if (order?.source === "web") {
    isOrderEditable = true;
    isOrderBlocked = false;
  } else {
    isOrderEditable = false;
    isOrderBlocked = false;
  }

  return { isOrderEditable, isOrderBlocked };
}
