import React, { Fragment, useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form, Spin, message } from "antd";
import Helper from "../../common/consts/Helper";
import { PHOTO_URL } from "../../common/consts/Config";

export default function IdProofUpload(props) {
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [idProofs, setIdProofs] = useState(["", "", "", "", "", ""]);

  const [isUploading, setIsUploading] = useState({
    isUploading: false,
    index: 0,
  });

  useEffect(() => {
    if (props?.idProofs) {
      const updatedProofs = [];
      for (let i = 0; i < 6; i++) {
        const proof = (props?.idProofs && props?.idProofs[i]) || null;
        if (proof) {
          updatedProofs?.push(proof);
        } else {
          updatedProofs.push("");
        }
      }
      setIdProofs(updatedProofs);
    }
  }, [props?.idProofs]);

  const handleOk = async () => {
    try {
      setConfirmLoading(true);
      const body = {
        orderId: props?.orderId,
        idProofs: idProofs,
      };
      let res = Helper("upload_proof", "POST", body);
      res
        .then((response) => {
          if (response?.error) {
          } else {
            handleCancel();
            props?.onSuccess();
          }
        })
        .catch(() => {})
        .finally(() => {
          setConfirmLoading(false);
        });
    } catch (error) {}
  };

  const handleCancel = () => {
    props?.onClose();
    setVisible(false);
  };

  useEffect(() => {
    setVisible(props?.active);
  }, [props?.active]);

  async function uploadPhoto(file, index) {
    try {
      let body = new FormData();
      body.append("image", file);
      setIsUploading({
        ...isUploading,
        isUploading: true,
        index,
      });
      let res = Helper("image", "POST", body, true);
      res
        .then((response) => {
          if (response?.data) {
            const newIdProofs = idProofs?.slice();
            newIdProofs[index] = response.data;
            setIdProofs(newIdProofs);
          }
          if (response.message) {
          } else if (response.error) message.error(response.error.toString());
        })
        .catch((error) => {})
        .finally(() => {
          setIsUploading({
            ...isUploading,
            isUploading: false,
            index,
          });
        });
    } catch (error) {}
  }

  return (
    <>
      <Modal
        title="Upload ID Proof"
        visible={visible}
        onOk={handleOk}
        okText="Save"
        width={1000}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Row className="py-5">
          {idProofs?.map((idProof, index) => {
            return (
              <Col xs={{ span: 24 }} md={{ span: 12 }} key={index}>
                <div className="file-field input-field">
                  <div className="btn-upload">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        if (e.target?.files && e.target?.files?.length > 0) {
                          uploadPhoto(e.target.files[0], index);
                        }
                      }}
                    />
                  </div>
                  <div className="file-path-wrapper">
                    <div className="idproof-image-container">
                      {isUploading?.isUploading &&
                      isUploading?.index === index ? (
                        <Spin />
                      ) : idProof ? (
                        <>
                          <img
                            src={PHOTO_URL + idProof}
                            className="idproof-image"
                          />
                        </>
                      ) : (
                        <i className="fas fa-upload" />
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Modal>
    </>
  );
}
