import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Typography, Button, Modal, Collapse } from "antd";
import { handleChange } from "../../../actions/PickupActions";
import { useDispatch, useSelector } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { updateCalculatorState } from "../../../actions/CommonActions";
import { CalculatorOutlined } from "@ant-design/icons";
import {
  T2125Fields,
  T776Fields,
  T777Fields,
} from "../../consts/PrefillConstants";

function T777(props) {
  const { Title } = Typography;
  const { confirm } = Modal;
  const { Panel } = Collapse;

  const allFields = {
    T777: T777Fields,
    T776: T776Fields,
    T2125: T2125Fields,
  };

  const { title, docType, onFinishClose, fieldName } = props;
  const [fields, setFields] = useState(allFields[fieldName]?.slice());

  const dispatch = useDispatch();

  const allFormValues = useSelector((state) => state.Pickup[docType] || {});

  const [saveConfirmOpen, setSaveConfirmOpen] = useState(false);

  useEffect(() => {
    console.log(allFormValues)
    if (
      allFormValues &&
      Array.isArray(allFormValues) &&
      allFormValues?.length > 0
    ) {
      setFields(allFormValues);
    } else {
      setFields(allFields[fieldName]?.slice());
    }
  }, [allFormValues]);

  const onFinish = (values) => {
    dispatch(handleChange(docType, fields));
    onFinishClose();
  };

  function addAnotherField(index, id, pid) {
    const updatedFields = fields?.slice();
    updatedFields[index]?.otherFields?.push({
      label: "",
      value: "",
      id: `${id}${pid}`,
    });
    setFields(updatedFields);
  }

  function removeOField(pInd, currIndex, fName, fVal) {
    const updatedFields = fields?.slice();
    // form.setFieldsValue({
    //   [fName]: '',
    //   [fVal]: ''
    // });
    updatedFields[pInd]?.otherFields?.splice(currIndex, 1);
    setFields(updatedFields);
  }

  function saveDetails() {
    dispatch(handleChange(docType, fields));
  }

  function showSave() {
    setSaveConfirmOpen(true);
    confirm({
      title: "Do you want to save the changes done till now?",
      icon: <ExclamationCircleOutlined />,
      content: "Save the changes and continue filling the form.",
      onOk() {
        saveDetails();
        setSaveConfirmOpen(false);
      },
      onCancel() {
        setSaveConfirmOpen(false);
      },
    });
  }

  useEffect(() => {
    window.interval23 = setInterval(() => {
      if (!saveConfirmOpen) {
        showSave();
      }
    }, 180000);
    return () => {
      clearInterval(window.interval23);
    };
  }, [saveConfirmOpen, fields]);

  function handleText(value, index, subIndex, isOther) {
    let updatedFields = fields?.slice();
    if (!isOther) {
      updatedFields[index].fields[subIndex].value = value;
    } else {
      updatedFields[index].otherFields[subIndex][isOther] = value;
    }
    setFields(updatedFields);
  }

  return (
    <>
      <Row className="relative w-100">
        <Col gutter={32} span={24}>
          <Card
            title={title}
            headStyle={{
              background: "#b0000b",
              color: "#fff",
              fontSize: "22px",
            }}
            style={{ borderRadius: "12px" }}
          >
            {renderFields(fields)}
            <Button type="primary" htmlType="button" onClick={onFinish}>
              Save
            </Button>
          </Card>
        </Col>
      </Row>
    </>
  );

  function renderOtherType(field, index) {
    return (
      <>
        <Title level={5} style={{ marginBottom: "10px", marginTop: "10px" }}>
          {field.otherLabel}
        </Title>
        <Row>
          <Col span={10}>Type</Col>
          <Col span={10}>Value</Col>
          <Col span={4}>Action</Col>
        </Row>
        <Row style={{ marginBottom: "24px" }}>
          {field?.otherFields?.map((oField, oIndex) => {
            const formNameId = `${oField.id}Label`;
            const formValueId = `${oField.id}Value`;
            return (
              <Fragment key={oIndex}>
                <Col span={10}>
                  <input
                    style={{ height: "2.2rem" }}
                    name={oIndex}
                    value={oField?.label}
                    defaultValue={oField?.label}
                    onChange={(e) => {
                      handleText(e.target.value, index, oIndex, "label");
                    }}
                  />
                </Col>
                <Col span={10}>
                  <input
                    style={{ height: "2.2rem" }}
                    name={oIndex}
                    value={oField?.value}
                    onChange={(e) => {
                      handleText(e.target.value, index, oIndex, "value");
                    }}
                  />
                </Col>
                <Col span={4}>
                  {field?.otherFields?.length !== 1 ? (
                    <Button
                      type="primary"
                      shape="circle"
                      onClick={() => {
                        removeOField(index, oIndex, formValueId, formNameId);
                      }}
                    >
                      -
                    </Button>
                  ) : null}
                </Col>
              </Fragment>
            );
          })}
          <Col span={24}>
            <Button
              type="primary"
              onClick={() => {
                addAnotherField(
                  index,
                  field.otherFieldCId,
                  field?.otherFields?.length + 1
                );
              }}
            >
              Add Another
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  function renderCollapsedField(children, title) {
    return (
      <Collapse
        style={{
          marginTop: "24px",
          marginBottom: "24px",
        }}
      >
        <Panel header={<div style={{ fontWeight: "bolder", fontSize: "18px", margin: "auto" }}>{title}</div>} key="1">
          {children}
        </Panel>
      </Collapse>
    );
  }

  function generateFields(field, index) {
    return (
      <Fragment>
        <Title level={4} style={{ marginBottom: "10px", marginTop: "10px" }}>
          {field.title}
          <Button
            type="dashed"
            icon={<CalculatorOutlined />}
            onClick={() => {
              dispatch(updateCalculatorState(true));
            }}
            className="ml-2"
            size="small"
          >
            Open Calculator
          </Button>
        </Title>
        <Row gutter={{ md: 24, lg: 32 }}>
          {field?.fields?.map((name, subIndex) => {
            if (name.type === "sub") {
              return (
                <Col key={subIndex} md={{ span: 24 }}>
                  {renderFields(name.subFields)}
                </Col>
              );
            }
            return (
              <Col md={{ span: name?.half ? 12 : 24 }} key={subIndex}>
                <div>{name?.label}</div>
                <input
                  style={{ height: "2.2rem" }}
                  name={subIndex}
                  value={name.value}
                  defaultValue={name.value}
                  onChange={(e) => {
                    handleText(e.target.value, index, subIndex, false);
                  }}
                />
              </Col>
            );
          })}
        </Row>
        {field?.other ? renderOtherType(field, index) : null}
      </Fragment>
    );
  }

  function renderFields(fields) {
    return fields?.map((field, index) => {
      if (field?.expand) {
        return (
          <Fragment key={index}>
            {renderCollapsedField(generateFields(field, index), field.title)}
          </Fragment>
        );
      } else {
        return <Fragment key={index}>{generateFields(field, index)}</Fragment>;
      }
    });
  }
}

export default T777;
