import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Input, Form, Select } from 'antd';

//Redux imports
import { connect } from 'react-redux';
import { handleChange, handleMultipleChange } from '../../../actions/PickupActions';

//constants
import Helper from '../../../common/consts/Helper';
import moment from 'moment';

//cards
import CardImage from '../../../assets/cards.png';

import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { countries } from '../../../common/consts/ProtaxHelpers';
import { toast } from 'react-toastify';

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            fontWeight: 500,
            fontSize: '16px',
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSmoothing: 'antialiased',
            border: '1px solid #a0a'
        },
        invalid: {
            // iconColor: '#ffc7ee',
        },
    },
};

function PaymentFinal(props) {

    const { PickupParams, saveOrderReponse, editOrderId, handleChange } = props;


    const [isLoading, setIsLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();


    const handlePayment = async (values) => {
        const card = elements.getElement(CardElement);
        var paymentRequest = await stripe.createToken(card);
        if (paymentRequest && paymentRequest?.token?.id) {
            makePayment(
                editOrderId ? editOrderId : saveOrderReponse?.response?.pickupRequestInfo?._id,
                paymentRequest?.token?.id
            );
        }
    };


    function makePayment(id, token) {
        setIsLoading(true);
        let res = Helper('add_payment', 'POST', {
            source: token,
            orderId: id,
            amount: saveOrderReponse?.total,
        });
        res.then((response) => {
            setIsLoading(false)
            if (response.error) {
                toast.error(response?.error?.toString())
            }
            else {
                handleMultipleChange({
                    'isEdit': false,
                    'isOldData': false
                })
                toast.success('Order Place Successfully!!')
                props.history.replace(`/appointment/${id}`);
            }
        })
            .catch((error) => {
                setIsLoading(false);
                // removeRoute('PaymentFailure')
            })
            .finally(() => { setIsLoading(false) })
    }


    return (
        <>
            <Row style={{ marginTop: "50px", marginBottom: "50px", display: "flex", justifyContent: "center" }}>
                <Col lg={{ span: 14 }} md={{ span: 20 }}>
                    <Card
                        title="Fill your card details and Pay!"
                        headStyle={{ background: "#b0000b", color: "#fff", borderRadius: "12px 12px 0px 0px", fontSize: '22px' }} style={{ borderRadius: "12px" }}
                    >
                        <Form
                            {...layout}
                            onFinish={(values) => {
                                handlePayment(values)
                            }}
                        >
                            <Row gutter={24}>
                                <Col xs={{ span: 24 }} md={{ span: 16, offset: 4 }} className="mb-2">
                                    <CardElement
                                        options={CARD_OPTIONS}
                                    />
                                </Col>
                                <Col xs={{ span: 24 }} className="mb-2 d-flex">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        className="protax-btn mx-auto text-bold"
                                        loading={isLoading}
                                    >
                                        PAY ${saveOrderReponse?.total / 100}
                                    </Button>
                                </Col>
                                <Col xs={{ span: 24 }} className="d-flex">
                                    <img
                                        src={CardImage}
                                        className="mx-auto"
                                        height="40"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col >
            </Row >
        </>
    );
}



const mapStateToProps = () => state => ({
    PickupParams: state.Pickup,
});

const mapDispatchToProps = () => dispatch => ({
    handleChange: (name, value) => dispatch(handleChange(name, value)),
})


export default connect(mapStateToProps, mapDispatchToProps)(PaymentFinal);
