import React, { useState, useEffect } from "react";
import { Row, Col, message, Button, Table, Switch, Modal } from "antd";
import { useHistory, Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";

//constants
import Helper from "../../common/consts/Helper";
import _ from "lodash";
import moment from "moment";
import Status from "../../common/consts/Status";
import { Tooltips } from "../../common/consts/ProtaxHelpers";

//redux imports
import { connect } from "react-redux";
import { setEditState } from "../../actions/PickupActions";
import { updateEditBaseOrder } from "../../actions/OrderActions";
import { toast } from "react-toastify";
import {
  checkIfOrderFromOtherPlatform,
  getAmountToTransact,
  getTransactionTitle,
} from "../../common/utils";

function Order(props) {
  const { setEditState, updateEditBaseOrder } = props;

  let history = useHistory();
  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  async function openEditPage(record) {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Please review all the steps & all the steps are mandatory.",
      okText: "Ok",
      cancelText: "Cancel",
      onOk: async () => {
        updateEditBaseOrder(record.baseOrder.order);
        setEditState({
          ...record,
          isEdit: true,
        });
        history.push("/tax_return");
      },
    });
  }

  function getData() {
    setIsFetching(true);
    let res = Helper("fetch_orders", "POST", {});
    res
      .then((response) => {
        if (response.error) {
          message.error(response.error);
        } else {
          setAllOrders(response.orders);
          setOrders(response.orders?.filter((e) => e.status < 4));
        }
      })
      .catch((error) => {
        message.error("Something went wrong, Please try again later!!");
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <span>{moment(text).format("Do MMMM, YYYY")}</span>
      ),
      width: 150,
    },
    // {
    //     title: 'Time',
    //     dataIndex: 'createdAt',
    //     key: 'time',
    //     render: (text, record) => (<span>{moment(text).format('hh:mm A')}</span>),
    //     width: 100
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <span className="text-bold text-uppercase">{Status[text]}</span>
      ),
      width: 150,
    },
    {
      title: "Form",
      key: "view_form",
      render: (text, record) => {
        const orderEditDetails = checkIfOrderFromOtherPlatform(record);
        return (
          <div className="d-flex align-items-center">
            <Button
              type="primary"
              className="protax-btn btn-sm ml-1"
              onClick={() => {
                if (record.status < 3) {
                  if (
                    orderEditDetails.isOrderEditable &&
                    !orderEditDetails?.isOrderBlocked
                  ) {
                    openEditPage(record);
                  } else if (orderEditDetails.isOrderBlocked) {
                    message.error(
                      "Order not editable, Kindly contact us directly!!"
                    );
                  } else {
                    message.error(
                      "Order not editable, Orders done from mobile app can only be edited on app."
                    );
                  }
                } else {
                  message.error(
                    "Cannot edit the order because work is in progress, Kindly contact us directly!!"
                  );
                }
              }}
            >
              Edit
            </Button>
          </div>
        );
      },
      width: 150,
    },
    {
      title: "Signature",
      key: "Signature",
      render: (text, itemProps) => (
        <Button
          onClick={() => {
            if (
              !itemProps.document1 &&
              !itemProps.document2 &&
              !itemProps.document3 &&
              !itemProps.document4 &&
              !itemProps.document5
            ) {
              toast.info(
                "We haven't Added any Documents yet, Please wait for us to add documents for signature!"
              );
            } else {
              localStorage.setItem("orderId", itemProps?._id);
              props.history.push("/signature", {
                document1: itemProps?.document1,
                document2: itemProps?.document2,
                document3: itemProps?.document3,
                document4: itemProps?.document4,
                document5: itemProps?.document5,

                signature1: itemProps?.signature1,
                signature2: itemProps?.signature2,
                signature3: itemProps?.signature3,
                signature4: itemProps?.signature4,
                signature5: itemProps?.signature5,

                orderId: itemProps?._id,
              });
            }
          }}
          type="primary"
          className={`protax-btn btn-sm btn-success`}
        >
          Signature
        </Button>
      ),
      width: 150,
    },
    {
      title: "Status",
      key: "view_status",
      render: (text, record) => (
        <Link to={`/status/${record.status}`}>
          <Button type="primary" className="protax-btn btn-sm">
            Status
          </Button>
        </Link>
      ),
      width: 100,
    },
    {
      title: "Upload",
      key: "upload",
      render: (text, record) => (
        <Button
          type="primary"
          className={`protax-btn btn-sm`}
          onClick={() => {
            props.history.push("/documents", {
              order: record,
            });
          }}
          disabled={!documentButtonConditions(record)}
        >
          Documents
        </Button>
      ),
      width: 150,
    },
    {
      title: "Appointment Schedule",
      key: "appointment",
      render: (text, record) => (
        <Button
          type="primary"
          className={`protax-btn btn-sm`}
          onClick={() => {
            props.history.push(`/appointment/${text._id}`, {
              order: record,
            });
          }}
          // disabled={!documentButtonConditions(record)}
        >
          Book Appointment
        </Button>
      ),
      width: 200,
    },
    {
      title: "Payment",
      key: "payment",
      render: (text, record) => {
        const orderEditDetails = checkIfOrderFromOtherPlatform(record);

        if (
          orderEditDetails?.isOrderEditable &&
          !orderEditDetails?.isOrderBlocked
        ) {
          return (
            <Button
              type="primary"
              className={`protax-btn btn-sm ${getTransactionColor(
                record.payment,
                record.totalAmount,
                record.baseOrder
              )}`}
              onClick={() =>
                history.push("/transaction", {
                  payment: record.payment,
                  totalAmount: record.totalAmount,
                  orderId: record._id,
                  discount: record?.baseOrder?.discount,
                })
              }
            >
              {getTransactionTitle(
                record.payment,
                record.totalAmount,
                record.baseOrder
              )}
            </Button>
          );
        } else return null;
      },
      fixed: "right",
      width: 150,
    },
  ];

  return (
    <>
      <Row className="py-5">
        <Col xs={{ span: 20, offset: 2 }}>
          <div className="d-flex align-items-center mb-2">
            <i
              className="fas fa-chevron-left mr-2 fa-lg cursor-pointer p-1"
              onClick={() => {
                props.history.push("/");
              }}
            ></i>
            <h4 className="text-bold mb-0">Tax Return Status</h4>
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 22, offset: 1 }}
          lg={{ span: 20, offset: 2 }}
        >
          <Row>
            <Col xs={{ span: 24 }}>
              <div className="my-card">
                <div className="d-flex align-items-center py-5">
                  <div className="text-semibold fa-lg">
                    Include E-Filed Orders
                  </div>
                  <Switch
                    className="ml-2"
                    onChange={(checked) => {
                      if (checked) {
                        setOrders(allOrders);
                      } else {
                        setOrders(allOrders?.filter((e) => e.status < 4));
                      }
                    }}
                  />
                </div>
                <Table
                  loading={isFetching}
                  columns={columns}
                  dataSource={orders}
                  pagination={false}
                  bordered
                  scroll={{
                    x: 360,
                  }}
                  sticky
                  width={1024}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );

  function getTransactionColor(payment, totalAmount, baseOrder) {
    let AmountToTransact = +getAmountToTransact(
      payment,
      totalAmount,
      baseOrder
    );
    if (+AmountToTransact === 0) return "btn-info";
    else if (+AmountToTransact < 0) return "btn-info";
    else if (+AmountToTransact > 0) return "btn-success";
  }

  function documentButtonConditions(order) {
    let conditionSatisfied = false;
    let checklistsToCheck = [
      ...order.otherDocChecklist,
      ...order.spouseOtherDocChecklist,
    ];

    for (const list of checklistsToCheck) {
      if (list?.showEmailButton && list?.checked) {
        conditionSatisfied = true;
        break;
      }
    }

    return conditionSatisfied;
  }
}

const mapStateToProps = () => (state) => ({
  // dependants: state.Pickup.dependants
});
const mapDispatchToProps = () => (dispatch) => ({
  // handleChange: (name, value) => dispatch(handleChange(name, value)),
  setEditState: (state) => dispatch(setEditState(state)),
  updateEditBaseOrder: (order) => dispatch(updateEditBaseOrder(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Order);
