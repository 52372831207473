import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyCrK21YOfeKDFLc03JqnJSUUrpobZhNz_I",
    authDomain: "protax-5fcd6.firebaseapp.com",
    databaseURL: "https://protax-5fcd6-default-rtdb.firebaseio.com",
    projectId: "protax-5fcd6",
    storageBucket: "protax-5fcd6.appspot.com",
    messagingSenderId: "1080548635349",
    appId: "1:1080548635349:web:f6f19a78a7ab56f9b8285c",
    measurementId: "G-7185X56LJR",
};

firebase.initializeApp(config);

export default firebase;

