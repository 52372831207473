import {
  ORDER_DETAILS_RESET,
  UPDATE_EDIT_ORDER_BREAKUP,
  UPDATE_ORDER_BREAKUP,
  UPDATE_SALES,
  SET_SALES_FLAG
} from '../actions/types';

//async storage
// import AsyncStorage from '@react-native-async-storage/async-storage';

const initialState = {
  baseOrder: [{
    title: 'Tax Filling',
    description: 'Base Charge',
    charge: '49.99',
    numberOfItems: '1',
    amount: '49.99'
  },],
  editBaseOrder: [],
  sales: {},
  isSalesFetched: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_ORDER_BREAKUP:
      return {
        ...state,
        baseOrder: action.payload
      }
    case UPDATE_SALES:
      return {
        ...state,
        sales: action.payload
      }
    case UPDATE_EDIT_ORDER_BREAKUP:
      return {
        ...state,
        editBaseOrder: action.payload
      }
    case ORDER_DETAILS_RESET:
      return {
        ...state,
        baseOrder: [{
          title: 'Tax Filling',
          description: 'Base Charge',
          charge: '49.99',
          numberOfItems: '1',
          amount: '49.99'
        }],
        editBaseOrder: [],
      }
    case SET_SALES_FLAG:
      return {
        ...state,
        isSalesFetched: action.payload
      }
    default:
      return {
        ...state
      }
  }
}