//status
export default {
  1: 'pending',
  2: 'work in progress',
  3: 'completed',
  4: 'file e filed',
}

export const StatusDrop = {
  1: 'pending',
  2: 'drop initiated',
  3: 'picked up from office',
  4: 'dropped off',
}

// export const DropImages = {
//   1: require('../../assets/images/status/1.png'),
//   2: require('../../assets/images/status/2.png'),
//   3: require('../../assets/images/status/3.png'),
//   4: require('../../assets/images/status/6.png'),
// }

// export const StatusImages = {
//   1: require('../../assets/images/status/1.png'),
//   2: require('../../assets/images/status/2.png'),
//   3: require('../../assets/images/status/3.png'),
//   4: require('../../assets/images/status/4.png'),
//   5: require('../../assets/images/status/5.png'),
//   6: require('../../assets/images/status/6.png'),
// }