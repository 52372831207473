import React from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import T777 from "../../../common/components/OtherDocForms/T777";

export default function PrintPanelModal(props) {
  const docType = props?.documentType || 1;
  const { documentIsForSpouse, tFormNumber } = props;
  return (
    <Modal
      visible={props.modalVisible}
      onCancel={() => {
        props.setModalVisible(false);
      }}
      destroyOnClose
      center
      width={1000}
      classNames={{
        modal: "dropOffModal",
      }}
      footer={null}
      bodyStyle={{
        padding: '0px'
      }}
      closeIcon={<CloseOutlined style={{ color: "#fff" }} />}
    >
      {docType === 2 ? (
        <T777
          title="T777 Form"
          docType={`T777${
            documentIsForSpouse ? "Spouse" : "User"
          }${tFormNumber}`}
          onFinishClose={() => {
            props?.setModalVisible(false);
          }}
          fieldName="T777"
        />
      ) : null}
      {docType === 1 ? (
        <T777
          title="T2125 Form"
          docType={`T2125${
            documentIsForSpouse ? "Spouse" : "User"
          }${tFormNumber}`}
          onFinishClose={() => {
            props?.setModalVisible(false);
          }}
          fieldName="T2125"
        />
      ) : null}
      {docType === 3 ? (
        <T777
          title={`T776 Form : Property ${tFormNumber}`}
          fieldName="T776"
          docType={`T776${
            documentIsForSpouse ? "Spouse" : "User"
          }${tFormNumber}`}
          onFinishClose={() => {
            props?.setModalVisible(false);
          }}
        />
      ) : null}
    </Modal>
  );
}
