import React, { useState } from "react";
import { Button, Col } from "antd";
// import IdProofImage from "../../assets/idproof.png";
import IdProofUpload from "../idProofUpload";

import IdProofIcon from "../../assets/IdProofIcon.svg";

export default function IdProof(props) {
  const [uploadActive, setUploadActive] = useState(false);
  const { orderInfo } = props;

  if (orderInfo && Object.keys(orderInfo)?.length > 0) {
    return (
      <Col
        xs={{ span: 24 }}
        md={{ span: 12 }}
        lg={{ span: 12 }}
        xl={{ span: 8 }}
      >
        <div className="my-card py-5">
          <div>
            <span className="text-bold fa-lg">Upload Id Proof</span>
          </div>
          <div>
            <span className="text-grey fa-md">
              Id proof for order - {orderInfo?.firstName}
            </span>
          </div>
          <div className="percent-chart-container">
            <div className="percent-chart">
              <img
                className="percent-chart"
                style={{ width: "140px", height: "150px" }}
                src={IdProofIcon}
              />
            </div>
            <div className="text-right">
              <Button
                type="primary"
                className="protax-btn"
                // style={{ paddingRight: 10 }}
                onClick={() => {
                  setUploadActive(true);
                }}
              >
                Upload Now
              </Button>
            </div>
          </div>
        </div>
        <IdProofUpload
          active={uploadActive}
          onClose={() => {
            setUploadActive(false);
          }}
          orderId={props?.orderInfo?._id}
          idProofs={props?.orderInfo?.idProofs}
          onSuccess={() => {
            props?.getLatestOrder();
          }}
        />
      </Col>
    );
  }
  return null;
}
