import { Button, Divider } from "antd";
import React, { useEffect } from "react";
import { Document, Page } from "react-pdf";
import { PHOTO_URL } from "../../../common/consts/Config";

export default function SingleDoc(props) {
  const { doc, handleSignature } = props;

  return (
    <div>
      <h5>Document {doc.docNumber}</h5>

      <div
        className="position-relative doc-parent"
        style={{
          position: "relative",
        }}
      >
        <div>
          <Document
            file={
              doc?.signature
                // ? `data:application/pdf;base64,${doc?.signature}`
                ? PHOTO_URL + doc.signature
                : PHOTO_URL + doc.document
            }
            onLoadSuccess={() => {
              console.log("load success");
            }}
            onSourceError={(error) => {
              console.log(error, " this is an error");
            }}
          >
            <Page pageNumber={1} />
          </Document>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        {!doc.signature ? (
          <Button
            type="primary"
            className="protax-btn btn-sm mt-3"
            onClick={() => {
              handleSignature();
            }}
          >
            Add Signature
          </Button>
        ) : null}
      </div>

      <Divider />
    </div>
  );
}
