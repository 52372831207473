import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Radio,
  DatePicker,
  Row,
  Col,
  Card,
  Button,
  Modal,
} from "antd";

//Redux imports
import { connect } from "react-redux";
import {
  handleChange,
  handleMultipleChange,
} from "../../../actions/PickupActions";

//constants
import moment from "moment";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

function StepTwo(props) {
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const { handleChange, handleMultipleChange, setCurrent } = props;
  const [form] = Form.useForm();
  const [tempDetails, setTempDetails] = useState(null);

  useEffect(() => {
    form.setFieldsValue({
      gender: props.gender,
      firstName: props.firstName,
      middleName: props.middleName,
      lastName: props.lastName,
      sin: props.sin,
      dob: moment(props.dob),
      address: props.address,
      city: props.city,
      postalCode: props.postalCode,
      province: props.province,
      preferredContactMobile: props.preferredContactMobile,
      preferredContactEmail: props.preferredContactEmail,
      preferredContactMethod: props.preferredContactMethod,
      preferredReviewMethod: props.preferredReviewMethod,
      alternateMobileNumber: props.alternateMobileNumber,
    });
  }, []);

  const onFinish = (values) => {
    if (moment().diff(values.dob, "years") < 18) {
      let confirmation = window.confirm("Are you sure DOB is correct?");
      if (confirmation) {
        handleMultipleChange(values);
        setCurrent((previousCurrent) => previousCurrent + 1);
      }
    } else {
      handleMultipleChange(values);
      setCurrent((previousCurrent) => previousCurrent + 1);
    }
  };

  const history = useHistory();

  function SameBrowserInfo() {
    Modal.info({
      title: "Information Saved!",
      content: (
        <div>
          <p>
            Please use same browser if you want to countiue form where you left
          </p>
        </div>
      ),
      onOk() {},
    });
  }

  function showSaveAlert() {
    toast.success("Data Saved!");
    setTimeout(()=>{
      SameBrowserInfo();
    }, 1000)
  }
  function pushToDashboard() {
    history.push("/");
  }

  function saveDetails(isExit) {
    if (tempDetails) {
      if (moment().diff(tempDetails.dob, "years") < 18) {
        let confirmation = window.confirm("Are you sure DOB is correct?");
        if (confirmation) {
          handleMultipleChange(tempDetails);
          showSaveAlert();
          if (isExit) pushToDashboard();
        }
      } else {
        handleMultipleChange(tempDetails);
        showSaveAlert();
        if (isExit) pushToDashboard();
      }
    } else {
      showSaveAlert();
      if (isExit) pushToDashboard();
    }
  }
return (
    <>
      <Row
        style={{
          marginTop: "50px",
          marginBottom: "50px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Col ld={{ span: 16 }} md={{ span: 20 }}>
          <Card
            title="Enter your personal Information"
            headStyle={{
              background: "#b0000b",
              color: "#fff",
              borderRadius: "12px 12px 0px 0px",
              fontSize: "22px",
            }}
            style={{ borderRadius: "12px" }}
          >
            <Form
              {...layout}
              form={form}
              name="dynamic_rule"
              onFinish={onFinish}
              onValuesChange={(changedValues, allValues) => {
                setTempDetails(allValues);
              }}
            >
              <Row>
                <Col xs={{ span: 24 }}>
                  <Form.Item
                    name="gender"
                    label="Gender"
                    // value={props.gender}
                    rules={[
                      {
                        required: true,
                        message: "Gender is Required!",
                      },
                    ]}
                  >
                    <Radio.Group
                      name="radiogroup"
                      name="gender"
                    // onChange={handleText}
                    >
                      <Radio value={0}>Male</Radio>
                      <Radio value={1}>Female</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "First Name Required!",
                      },
                    ]}
                  >
                    <Input
                      style={{ height: "2.2rem" }}
                      // value={props.firstName}
                      name="firstName"
                      // onChange={handleText}
                      placeholder="John"
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item name="middleName" label="Middle Name">
                    <Input
                      style={{ height: "2.2rem" }}
                      // value={props.middleName}
                      name="middleName"
                      // onChange={handleText}
                      placeholder=" "
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Last Name is Required!",
                      },
                    ]}
                  >
                    <Input
                      style={{ height: "2.2rem" }}
                      name="lastName"
                      // onChange={handleText}
                      // value={props.lastName}
                      placeholder="Smith"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item
                    name="sin"
                    label="Social Insurance Number"
                    rules={[
                      {
                        max: 9,
                        message: "SIN cannot be longer than 9 Numbers!",
                      },
                      {
                        min: 9,
                        message: "SIN must be at least 9 Numbers!",
                      },
                    ]}
                  >
                    <Input
                      style={{ height: "2.2rem" }}
                      // value={props.sin}
                      name="sin"
                      // onChange={handleText}
                      placeholder="Please input Social Insurance Number"
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item
                    name="dob"
                    label="D.O.B"
                    rules={[
                      {
                        required: true,
                        message: "D.O.B is Required!",
                      },
                    ]}
                  >
                    <DatePicker
                      className="m-0 w-100"
                      onChange={(moment, value) => {
                        handleChange("dob", value);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={{ span: 24 }}>
                  <Form.Item
                    name="address"
                    label="Address"
                    rules={[
                      {
                        required: true,
                        message: "Address is Required!",
                      },
                    ]}
                  >
                    <Input
                      style={{ height: "2.2rem" }}
                      name="address"
                      // value={props.address}
                      // onChange={handleText}
                      placeholder="Please input Address"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={{ span: 24 }}>
                  <Form.Item
                    name="province"
                    label="Province"
                    rules={[
                      {
                        required: true,
                        message: "Province is Required!",
                      },
                    ]}
                  >
                    <Input
                      style={{ height: "2.2rem" }}
                      name="province"
                      // onChange={handleText}
                      // value={props.province}
                      placeholder="Please input Province"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item
                    // {...formItemLayout}
                    name="city"
                    label="City"
                    rules={[
                      {
                        required: true,
                        message: "City is Required!",
                      },
                    ]}
                  >
                    <Input
                      style={{ height: "2.2rem" }}
                      name="city"
                      // onChange={handleText}
                      // value={props.city}
                      placeholder="Please input city"
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item
                    // {...formItemLayout}
                    name="postalCode"
                    label="Postal Code"
                    rules={[
                      {
                        required: true,
                        message: "Postal Code is Required!",
                      },
                    ]}
                  >
                    <Input
                      style={{ height: "2.2rem" }}
                      name="postalCode"
                      // onChange={handleText}
                      // value={props.postalCode}
                      placeholder="Please input postal code"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={{ span: 24 }}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Form.Item
                    name="preferredContactMethod"
                    style={{ width: "100%", display: "flex" }}
                    label="Preferred Contact Method"
                    rules={[
                      {
                        required: true,
                        message: "Preferred Contact Method is Required",
                      },
                    ]}
                  >
                    {/* <div style={{ display: "flex", width: "100%" }}> */}
                    <Radio.Group
                      onChange={(e) => {
                        handleChange("preferredContactMethod", e.target.value);
                      }}
                      name="preferredContactMethod"
                    >
                      <Radio value={0}>Mobile</Radio>
                      <Radio value={1}>Email</Radio>
                    </Radio.Group>
                    {/* </div> */}
                  </Form.Item>
                  <Form.Item
                    name={
                      props.preferredContactMethod === 0
                        ? "preferredContactMobile"
                        : "preferredContactEmail"
                    }
                    style={{ width: "100%", display: "flex" }}
                    label="Preferred Contact"
                    rules={[
                      {
                        required: true,
                        message: "Preferred Contact is Required",
                      },
                    ]}
                  >
                    <Input
                      style={{ height: "2.2rem" }}
                      name={
                        props.preferredContactMethod === 0
                          ? "preferredContactMobile"
                          : "preferredContactEmail"
                      }
                      // value={props.preferredContactMethod === 0 ? props.preferredContactMobile : props.preferredContactEmail}
                      placeholder={
                        props.preferredContactMethod === 0
                          ? "Please input mobile number"
                          : "Please input Email"
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item
                    name="alternateMobileNumber"
                    label="Alternate Mobile Number"
                  >
                    <Input
                      style={{ height: "2rem", width: "100%" }}
                      name="alternateMobileNumber"
                      // onChange={handleText}
                      value={props.alternateMobileNumber}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item
                    // {...formItemLayout}
                    name="preferredReviewMethod"
                    style={{ width: "100%", display: "flex" }}
                    label="Preferred Method To Review Tax Return"
                    rules={[
                      {
                        required: true,
                        message:
                          "Preferred Method To Review Tax Return is Required",
                      },
                    ]}
                  >
                    <Radio.Group
                      name="preferredReviewMethod"
                    // onChange={handleText}
                    // value={props.preferredReviewMethod}
                    >
                      <Radio value={0}>In Person</Radio>
                      <Radio value={1}>By Phone</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={{ span: 24 }}>
                  {
                    props?.isEdit ? null : (
                      <>
                        <Button
                          onClick={() => {
                            saveDetails(false);
                          }}
                          type="dashed"
                          className="mr-3"
                          htmlType="button"
                        >
                          Save
                        </Button>
                        <Button
                          onClick={() => {
                            saveDetails(true);
                          }}
                          type="dashed"
                          className=" mr-3"
                          htmlType="button"
                        >
                          Save and Exit
                        </Button>
                      </>)}
                  <Button
                    type="primary"
                    className="protax-btn"
                    htmlType="submit"
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = () => (state) => ({
  gender: state.Pickup.gender,
  firstName: state.Pickup.firstName,
  middleName: state.Pickup.middleName,
  lastName: state.Pickup.lastName,
  sin: state.Pickup.sin,
  dob: state.Pickup.dob,
  address: state.Pickup.address,
  city: state.Pickup.city,
  postalCode: state.Pickup.postalCode,
  province: state.Pickup.province,
  preferredContactMobile: state.Pickup.preferredContactMobile,
  preferredContactEmail: state.Pickup.preferredContactEmail,
  preferredContactMethod: state.Pickup.preferredContactMethod,
  preferredReviewMethod: state.Pickup.preferredReviewMethod,
  alternateMobileNumber: state.Pickup.alternateMobileNumber,
  isEdit: state.Pickup.isEdit
});
const mapDispatchToProps = () => (dispatch) => ({
  handleChange: (name, value) => dispatch(handleChange(name, value)),
  handleMultipleChange: (state) => dispatch(handleMultipleChange(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
