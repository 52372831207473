import React, { useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import { Typography } from "antd";
import Helper from "../../../common/consts/Helper";
import { Radio, Space } from "antd";
import { useDispatch } from "react-redux";
import { handleMultipleChange } from "../../../actions/PickupActions";
import { useHistory } from "react-router-dom";

const { Title, Paragraph } = Typography;

export default function Prefill(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setIsModalVisible(props?.active);
  }, [props?.active]);

  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    dispatch(
      handleMultipleChange({
        // * Step 1
        gender: currentOrder?.gender,

        firstName: currentOrder?.firstName,
        middleName: currentOrder?.middleName,
        lastName: currentOrder?.lastName,

        sin: currentOrder?.sin,
        dob: currentOrder?.dob,

        address: currentOrder?.address,
        province: currentOrder?.province,

        city: currentOrder?.city,
        postalCode: currentOrder?.postalCode,
        province: currentOrder?.province,

        preferredContactEmail: currentOrder?.preferredContactEmail,
        preferredContactMethod: currentOrder?.preferredContactMethod,
        preferredContactMobile: currentOrder?.preferredContactMobile,
        alternateMobileNumber: currentOrder?.alternateMobileNumber,
        preferredReviewMethod: currentOrder?.preferredReviewMethod,

        // * Step 2

        maritalStatus: currentOrder?.maritalStatus,
        fillingForSpouse: currentOrder?.fillingForSpouse,
        spouseInfo: currentOrder?.spouseInfo,

        // * Step 3
        dependants: currentOrder?.dependants,
      })
    );
    setIsModalVisible(false);
    info();
  };

  const info = () => {
    Modal.warning({
      title: "Importent Notice",
      content: (
        <div>
          <p>
            Please read and update your prefilled information from last year
            carefully.
          </p>
        </div>
      ),
      onOk() {
        setTimeout(() => {
          history.push("/tax_return");
        }, 200);
      },
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setTimeout(() => {
      history.push("/tax_return");
    }, 200);
  };

  function getData() {
    setIsFetching(true);
    let res = Helper("fetch_orders", "POST", {});
    res
      .then((response) => {
        setIsFetching(false);
        if (response.error) {
        } else {
          const updatedOrders = [];
          for (const order of response?.orders) {
            if (
              !updatedOrders?.find(
                (e) =>
                  e.firstName === order?.firstName &&
                  e.lastName === order?.lastName
              )
            ) {
              updatedOrders.push(order);
            }
          }
          setAllOrders(updatedOrders);
        }
      })
      .catch((error) => {
        setIsFetching(false);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Modal
        okText="Prefill"
        cancelText="Fresh Start"
        title={
          <>
            <Title level={3}>Prefill Form</Title>
            <Paragraph>Please choose</Paragraph>
          </>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Radio.Group
          onChange={(e) => {
            setSelectedOrder(e.target.value);
          }}
          value={selectedOrder}
        >
          <Space direction="vertical">
            {isFetching && <Spin />}

            {allOrders?.map((order, index) => {
              return (
                <Radio
                  onChange={() => {
                    setCurrentOrder(order);
                  }}
                  value={order._id}
                  key={index}
                  className="text-capitalize"
                >
                  {`${order?.firstName} ${order?.lastName}`}
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
      </Modal>
    </div>
  );
}
