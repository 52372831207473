import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={'100%'}
    height={300}
    viewBox="0 0 450 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="163" cy="217" r="107" /> 
    <rect x="33" y="13" rx="2" ry="2" width="209" height="21" /> 
    <rect x="34" y="48" rx="2" ry="2" width="350" height="11" />
  </ContentLoader>
)

export default MyLoader