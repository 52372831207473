import React, { useEffect, useState } from "react";
import { Row, Col, message, Spin } from "antd";

//constatns
import {
  BASE_URL,
  REDIRECT_URL,
} from "../../common/consts/Config";
import Helper from "../../common/consts/Helper";

//redux imports
import { connect } from "react-redux";
import { getParameterByName } from "../../common/utils";
import SingleDoc from "./components/SingleDoc";
import DocusignLoader from "./components/DocusignLoader";
import { Modal } from "antd";
import { io } from "socket.io-client";
import { useHistory } from "react-router-dom";

function Signature(props) {
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  const [isLoadingSignature, setIsLoadingSignature] = useState(-1);
  const [isLoadingDocusign, setIsLoadingDocusign] = useState(false);

  const [details, setDetails] = useState({
    document1: null,
    document2: null,
    document3: null,
    document4: null,
    document5: null,

    signature1: null,
    signature2: null,
    signature3: null,
    signature4: null,
    signature5: null,
  });

  const {
    document1,
    document2,
    document3,
    document4,
    document5,

    signature1,
    signature2,
    signature3,
    signature4,
    signature5,
  } = details;

  const orderId = localStorage.getItem("orderId");

  const history = useHistory();

  useEffect(() => {
    const socket = io(BASE_URL);
    socket.on("connect", () => {});
    socket.on("SIGN_COMPLETE", () => {
      history.push("/signature");
      setSuccessModalVisible(true);
      fetchOrderById();
    });

    if (!orderId) {
      props.history.push("/orders");
    } else {
      fetchOrderById();
    }
    return () => {
      socket.disconnect();
    };
  }, [orderId]);

  async function startZohoThing(docType) {
    try {
      setIsLoadingDocusign(true);
      await generateZohoToken(`document${docType}`, docType);
      setIsLoadingDocusign(false);
    } catch (error) {
      setIsLoadingDocusign(false);
    }
  }

  async function createZohoDocument(accessToken, docPath, docNumber) {
    try {
      const res = await Helper("create_document", "POST", {
        token: accessToken,
        documentName: details[docPath],
        documentType: docPath,
        orderId: orderId,
      });
      const actions = res.info.requests.actions;
      const documentIds = res.info.requests.document_ids;
      const request_id = res.info.requests.request_id;
      const request_name = res.info.requests.request_name;
      const actionId =
        actions && actions.length > 0 ? actions[0].action_id : "";
      const documentId =
        documentIds && documentIds?.length > 0
          ? documentIds[0].document_id
          : "";

      if (request_id && request_name && actionId && documentId) {
        await sendDocumentZoho(
          request_id,
          request_name,
          actionId,
          documentId,
          accessToken,
          docNumber
        );
      }
    } catch (error) {}
  }

  async function sendDocumentZoho(
    request_id,
    request_name,
    action_id,
    document_id,
    token,
    docNumber
  ) {
    try {
      const res = await Helper("send_document_for_sign", "POST", {
        request_id,
        request_name,
        action_id,
        document_id,
        token,
        document_type: docNumber,
      });
      await getZohoRedirectUrl(action_id, request_id, token);
    } catch (error) {}
  }

  async function getZohoRedirectUrl(action_id, request_id, token) {
    try {
      const res = await Helper("generate_document_uri", "POST", {
        action_id,
        request_id,
        token,
        domain: REDIRECT_URL,
      });
      if (res.info?.sign_url) {
        window.open(res.info?.sign_url, "_self");
      }
    } catch (error) {}
  }

  async function generateZohoToken(path, docNumber) {
    try {
      const res = await Helper("regenerate_access_token", "POST", {
        redirectUri: REDIRECT_URL,
      });
      const accessToken = res.tokenInfo?.access_token;
      if (accessToken) {
        await createZohoDocument(accessToken, path, docNumber);
      }
    } catch (error) {}
  }
  const docType = localStorage.getItem("docType");

  async function fetchOrderById() {
    try {
      setIsLoadingSignature(true);
      const response = await Helper("fetch_order_by_id", "POST", {
        orderId: orderId,
      });
      setIsLoadingSignature(false);
      if (response?.orders && response?.orders[0]) {
        const order = response?.orders[0];
        setDetails({
          ...data,
          document1: order.document1,
          document2: order.document2,
          document3: order.document3,
          document4: order.document4,
          document5: order.document5,

          signature1: order.documentSignature1 || "",
          signature2: order.documentSignature2 || "",
          signature3: order.documentSignature3 || "",
          signature4: order.documentSignature4 || "",
          signature5: order.documentSignature5 || "",
        });
      }
    } catch (error) {
      setIsLoadingSignature(false);
    }
  }

  const data = [
    {
      document: document1,
      signature: signature1,
      docNumber: 1,
    },
    {
      document: document2,
      signature: signature2,
      docNumber: 2,
    },
    {
      document: document3,
      signature: signature3,
      docNumber: 3,
    },
    {
      document: document4,
      signature: signature4,
      docNumber: 4,
    },
    {
      document: document5,
      signature: signature5,
      docNumber: 5,
    },
  ];

  return (
    <div className="py-5">
      <Row className="py-5">
        <Col xs={{ span: 20, offset: 2 }}>
          <h4 className="text-bold">Signature</h4>
          <h6>Please Provide Signatures on all the Documents</h6>
        </Col>
        <Col xs={{ span: 20, offset: 2 }} className="mt-3">
          <Spin size="large" spinning={isLoadingSignature} />
        </Col>
        <Col
          xs={{ span: 22, offset: 1 }}
          md={{ span: 20, offset: 2 }}
          className="py-5"
        >
          {data.map((doc, index) => {
            if (doc.document) {
              return (
                <SingleDoc
                  key={index}
                  doc={doc}
                  handleSignature={async () => {
                    try {
                      handleSignature(doc.document, doc.docNumber);
                    } catch (error) {}
                  }}
                />
              );
            } else return null;
          })}
        </Col>
      </Row>
      <DocusignLoader active={isLoadingDocusign} />
      <Modal
        title="Signature Added Succesfully!"
        centered
        visible={successModalVisible}
        onOk={() => setSuccessModalVisible(false)}
        onCancel={() => setSuccessModalVisible(false)}
        width={650}
      >
        <p>
          You signatures has been recorded succesfully. Thanks for using protax.
          Team Protax.
        </p>
      </Modal>
    </div>
  );

  function handleSignature(doc, num) {
    localStorage.setItem("docType", num);
    startZohoThing(num);
    // window.open(
    //   `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoSign.documents.CREATE,ZohoSign.documents.READ,ZohoSign.documents.UPDATE,ZohoSign.documents.DELETE&client_id=${CLIENT_ID_ZOHO}&state=${num}&response_type=code&redirect_uri=${REDIRECT_URL}&access_type=offline`,
    //   "_self"
    // );
  }
}

const mapStateToProps = () => (state) => ({
  web_signature: state.Pickup.web_signature,
});

export default connect(mapStateToProps)(Signature);
