//pickup types
export const PICKUP_HANDLE_CHANGE = 'PICKUP_HANDLE_CHANGE';
export const PICKUP_RESET_STATE = 'PICKUP_RESET_STATE';
export const HANDLE_MULTIPLE_CHANGE = 'HANDLE_MULTIPLE_CHANGE';
export const SET_EDIT_STATE = 'SET_EDIT_STATE';

export const SET_USER_INFO = 'SET_USER_INFO';

export const UPDATE_ORDER_BREAKUP = 'UPDATE_ORDER_BREAKUP';
export const UPDATE_EDIT_ORDER_BREAKUP = 'UPDATE_EDIT_ORDER_BREAKUP'
export const UPDATE_SALES = 'UPDATE_SALES';
export const ORDER_DETAILS_RESET = 'ORDER_DETAILS_RESET';
export const SET_SALES_FLAG = 'SET_SALES_FLAG';

export const UPDATE_CALCULATOR_STATE = 'UPDATE_CALCULATOR_STATE'