import React from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'react-responsive-modal';

//redux imports
import { connect } from 'react-redux';
import { handleChange } from '../../../actions/PickupActions';

//antd components
import { List, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

function DependantsModal(props) {

    let {
        dependants,
        receiptsChecklist,
        handleChange,
        modalVisible,
        setModalVisible
    } = props;

    return (
        // <Modal
        //     open={modalVisible}
        //     onClose={() => { setModalVisible(false) }}
        //     showCloseIcon={false}
        // >
            // <div className="p-4 modal-lg modal-dialog">
            <>
                <h5 className="mt-3">Dependants List</h5>
                <div className="mt-2">
                    <List
                        bordered
                        dataSource={dependants}
                        renderItem={(item, index) => (
                            <List.Item className="text-capitalize">
                                <Typography.Text>{index + 1}</Typography.Text>
                                <Typography.Text>
                                    <span>
                                        {item.firstName} {item.middleName} {item.lastName}
                                    </span>
                                </Typography.Text>
                                <Typography.Text>
                                    <span>
                                        {item.relation}
                                    </span>
                                </Typography.Text>
                                <Typography.Text>
                                    <DeleteOutlined  className="cursor-pointer" onClick={() => { deleteDependant(index) }} />
                                </Typography.Text>
                            </List.Item>
                        )}
                    />
                </div>
                </>
            // </div>
        // </Modal>
    )

    function deleteDependant(index) {
        let confirmation = window.confirm("Are you sure you want to delete this dependant?");
        if (confirmation) {
            let newDependants = dependants.slice();
            if (newDependants[index].relation === 'children') {
                let newReceipts = receiptsChecklist.slice();
                let rIndex = newReceipts.findIndex(e => e.type === 'child');
                if (rIndex !== -1) {
                    let cIndex = newReceipts[rIndex].childs.findIndex(e => e.id === newDependants[index].id);
                    if (cIndex !== -1) {
                        newReceipts[rIndex].childs.splice(cIndex, 1);
                        handleChange('receiptsChecklist', newReceipts);
                    }
                }
            }
            newDependants.splice(index, 1);
            handleChange('dependants', newDependants);
            toast.info('Dependant Deleted!!', {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }
}

const mapStateToProps = () => state => ({
    dependants: state.Pickup.dependants,
    receiptsChecklist: state.Pickup.receiptsChecklist
});

const mapDispatchToProps = () => dispatch => ({
    handleChange: (name, value) => dispatch(handleChange(name, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(DependantsModal);