import {
    BASE_URL
} from './Config';

const Helper = (url, method, data, isFormData = false, withoutBaseUrl = false) => {
    return (
        fetch(withoutBaseUrl ? url : BASE_URL + url, {
            method: method,
            body: isFormData ? (data) : (JSON.stringify(data)),
            headers: getHeaders(isFormData)
        }).then((response) => {
            if (response.status === 401) {
                localStorage.removeItem('protax_web_access_token');
            }
            return response.json()
        }).catch((error) => {
            // useToasts.addToast(ErrorMessages.internetConnection, { appearance: 'error' })
        })
    )
}

const getHeaders = (isFormData) => {
    let headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('protax_web_access_token'),
        'Bypass-Tunnel-Reminder': 'easdf'
    }
    if (isFormData)
        return {
            ...headers
        }
    else {
        return {
            ...headers,
            'Content-Type': 'application/json'
        }
    }
}

export default Helper;