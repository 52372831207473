import {
  UPDATE_EDIT_ORDER_BREAKUP,
  UPDATE_ORDER_BREAKUP,
  UPDATE_SALES,
  ORDER_DETAILS_RESET,
  SET_SALES_FLAG
} from './types';

export const updateOrderBreakup = (order) => dispatch => {
  dispatch({
    type: UPDATE_ORDER_BREAKUP,
    payload: order
  })
}

export const updateSales = (sales) => dispatch => {
  dispatch({
    type: UPDATE_SALES,
    payload: sales
  })
}
export const updateEditBaseOrder = (order) => dispatch => {
  dispatch({
    type: UPDATE_EDIT_ORDER_BREAKUP,
    payload: order
  })
}
export const orderDetailsReset = () => dispatch => {
  dispatch({
    type: ORDER_DETAILS_RESET,
    payload: null
  })
}

export const setSalesFetched=(payload)=>dispatch=>{
  dispatch({
    type: SET_SALES_FLAG,
    payload
  })
}