export const BASE_URL = "https://protaxapi.nugen.co.in/"; //production 
export const PHOTO_URL = "https://protaxapi.nugen.co.in"; //production

// export const BASE_URL = "https://protaxapi.herokuapp.com/";
// export const PHOTO_URL = "https://protaxapi.herokuapp.com";

// export const BASE_URL = "https://kind-pug-68.loca.lt/";
// export const PHOTO_URL = "https://kind-pug-68.loca.lt";

// export const BASE_URL = "http://192.168.1.5:8012/";
// export const PHOTO_URL = "http://192.168.1.5:8012";

// export const BASE_URL = "https://protaxstagingapi.nugen.co.in/";
// export const PHOTO_URL = "https://protaxstagingapi.nugen.co.in";

export const PUB_KEY_STRIPE = "pk_live_51Hzla3ISq1TbukTY0V4ySLlRibgxaXUDtoj97xSvo8CsYXZsJKqWzcpMpSQz9liBNBaIBP4wf13hCPiMd2AnzX2700Veftn7vp";
// export const PUB_KEY_STRIPE = "pk_test_51Hzla3ISq1TbukTYrMnlrwufrBgpGt92Qi2ey3KCRzylVdRQBmcLY8TAceow3MpjqO05dnSdHkLeIz9ZSLkmtVbs00jn9c8M6A";
// export const REDIRECT_URL = "http://localhost:3000/signature"
// export const REDIRECT_URL = "https://protax-nugen.vercel.app/signature"
export const REDIRECT_URL = "https://app.protaxcanada.ca/signature"