import React from 'react';

//forms
import DependendantsForm from './Forms/DependendantsForm';
import Checklist from './Forms/Checklist';

import { Button } from 'antd';

//redux imports
import { connect } from 'react-redux';

import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';

function ViewOrder(props) {

    let form = props?.Pickup;

    const { setCurrent } = props;

    let dependants = [...form.dependants];

    let slipsChecklist = [...form.slipsChecklist];
    let receiptsChecklist = [...form.receiptsChecklist];
    let otherDocChecklist = [...form.otherDocChecklist];
    let spouseSlipsChecklist = [...form.spouseSlipsChecklist];
    let spouseReceiptsChecklist = [...form.spouseReceiptsChecklist];
    let spouseOtherDocChecklist = [...form.spouseOtherDocChecklist];

    const history = useHistory();
    function saveDetails() {
        toast.success("Data Saved!");
        history.push("/");
    }
    return (

        <div className="content">
            <div className="row">
                <div className="col m8 offset-m2 m-auto">
                    <div className="card">
                        <div className="card-content d-flex justify-content-between flex-wrap">
                            <h5 className="card-title text-uppercase text-bold">
                                <span
                                    onClick={() => {
                                        setCurrent(previousCurrent => previousCurrent - 1);
                                    }}
                                    className="cursor-pointer"
                                >
                                    <i className="fa fa-chevron-left mr-3" style={{ fontSize: '15px' }} />
                                </span>
                                Review your tax summary</h5>
                            <div className="d-flex align-items-center">
                                <button
                                    className="btn ant-btn-primary"
                                    onClick={() => {
                                        setCurrent(previousCurrent => previousCurrent + 1);
                                    }}
                                >
                                    Next
                                </button>
                            </div>
                        </div>

                        <div className="card-content">
                            {/* {renderForm()} */}
                            <DependendantsForm {...props} dependants={dependants} title="Dependents Information" />
                            <h5 className="text-capitalize">{form?.firstName + ' ' + form?.lastName}</h5>
                            <Checklist {...props} checklist={slipsChecklist} title="Slips" setCurrent={setCurrent} />
                            <Checklist {...props} checklist={receiptsChecklist} title="Receipts" setCurrent={setCurrent} />
                            <Checklist {...props} checklist={otherDocChecklist} title="Other Documents" setCurrent={setCurrent} />
                            {
                                form.fillingForSpouse ? (
                                    <>
                                        <h5 className="text-capitalize">{form?.spouseInfo?.firstName + ' ' + form?.spouseInfo?.lastName}</h5>
                                        <Checklist {...props} checklist={spouseSlipsChecklist} title="Slips" setCurrent={setCurrent} />
                                        <Checklist {...props} checklist={spouseReceiptsChecklist} title="Receipts" setCurrent={setCurrent} />
                                        <Checklist {...props} checklist={spouseOtherDocChecklist} title="Other Documents" setCurrent={setCurrent} />
                                    </>
                                ) : (
                                    null
                                )
                            }
                            <Button
                                type="dashed"
                                className="mr-2 protax-back-btn"
                                onClick={() => { setCurrent(previousCurrent => previousCurrent - 1) }}
                            >
                                Back
                            </Button>
                            {
                                props?.isEdit ? null : (
                                    <Button
                                        onClick={saveDetails}
                                        type="dashed"
                                        className=" mr-3"
                                        htmlType="button"
                                    >
                                        Save and Exit
                                    </Button>
                                )
                            }
                            <Button
                                type="primary"
                                className="protax-btn"
                                onClick={() => {
                                    setCurrent(previousCurrent => previousCurrent + 1);

                                }}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = () => state => ({
    Pickup: state.Pickup,
    isEdit: state?.Pickup?.isEdit
});

export default connect(mapStateToProps)(ViewOrder);