import React from 'react';
import {
    Route,
    Redirect
} from 'react-router-dom';

export default function PrivateRoute({ component: Component, redirectUrl, condition, ...rest }) {
    return (
        <Route
            {...rest}
            render={props =>
                (localStorage.getItem('protax_web_access_token')) ? (
                    <Component {...rest} {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: redirectUrl,
                                state: { from: props.location }
                            }}
                        />
                    )
            }
        />
    )
}