import React from 'react';

export default function Header() {
  return (
    <div style={{ display: "none" }}>
      ProTax Canada is based in Hamilton and Stoney Creek area. We provide Tax return
      preparation and bookkeeping services to individuals, self-employed, partnerships
      and incorporated businesses throughout the year at very reasonable rates. Tax Return,Accountant,Tax
      Preparation, Corporate Tax Return,Income tax return hamilton, Services,Personal
      Tax Return,Income Tax Return,Corporate Tax Return,HST Return,Instant Cash Fund,Cash
      Back,Individual Tax Return,Hamilton, Stoney Creek,Ancaster,Beamsville,BookKeeping,Accounting,Payroll,T1,T2,Tax
      Consultant,Tax Preparer,Tax Tips,Free Efile,Tax Refund,Tax Filling,Business,Canada,Self
      Employed. Taxes and Tax Preparation, Filing Advice Help with Tax Return Information
      | Taxes and Professional tax preparation services, Serving Hamilton, Stoney Creek
      and surrounding southern Ontario area
    </div>
  )
}
