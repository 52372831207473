import React, { useState } from "react";
import { Button, Col } from "antd";
// import IdProofImage from "../../assets/idproof.png";
import SignIcon from "../../assets/SignIcon.svg"
import { useHistory } from "react-router-dom";

export default function Signature(props) {
  const { orderInfo } = props;
  const history = useHistory();
  if (
    !orderInfo.document1 &&
    !orderInfo.document2 &&
    !orderInfo.document3 &&
    !orderInfo.document4 &&
    !orderInfo.document5
  ) {
    return null;
  }
  return (
    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl = {{span: 8}}>
      <div className="my-card py-5 h-100">
        <div>
          <span className="text-bold fa-lg">Sign tax forms</span>
        </div>
        <div>
          <span className="text-grey fa-md">
            Add Signature - {orderInfo?.firstName}
          </span>
        </div>
        <div className="percent-chart-container">
          <div className="percent-chart">
            <img
              className="percent-chart"
              style={{ width: "140px", height: "150px" }}
              src={SignIcon}
              alt="sign"
            />
          </div>
          <div className="text-right mt-2">
            <Button
              type="primary"
              className="protax-btn"
              // style={{ paddingRight: 10 }}
              onClick={() => {
                localStorage.setItem('orderId', orderInfo?._id);
                history.push("/signature", {
                  document1: orderInfo?.document1,
                  document2: orderInfo?.document2,
                  document3: orderInfo?.document3,
                  document4: orderInfo?.document4,
                  document5: orderInfo?.document5,

                  signature1: orderInfo?.signature1,
                  signature2: orderInfo?.signature2,
                  signature3: orderInfo?.signature3,
                  signature4: orderInfo?.signature4,
                  signature5: orderInfo?.signature5,

                  orderId: orderInfo?._id,
                });
              }}
            >
              Your Signature
            </Button>
          </div>
        </div>
      </div>
    </Col>
  );
}
