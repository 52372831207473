import React, { useState } from "react";
//routing
import { Link } from "react-router-dom";
import {
  Button,
  Input,
  Row,
  Col,
  Card,
  message,
  Spin,
  Typography,
  Space,
} from "antd";

// helper
import Helper from "../../common/consts/Helper";
// toaster
import { toast } from "react-toastify";

//redux imports
import { connect } from "react-redux";

// import loginPanelImage from "../../assets/tax-rafiki.png";
import loginImage  from  '../../assets/loginImage.png'

function Login(props) {
  let [mobile, setMobile] = useState("");
  let [otp, setOtp] = useState("");
  let [emailError, setEmailError] = useState("");
  let [passwordError, setPasswordError] = useState("");
  let [otpBtn, setOtpBtn] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const { Text, Title } = Typography;

  //loading indicators
  let [isOtpLoading, setIsOtpLoading] = useState(false);
  let [isLoading, setIsLoading] = useState(false);

  // otp recived from sent otp hit
  let [receiveotp, setReceiveOtp] = useState({});

  // if(mobile.trim() !== '' && otp.trim() !== ''){
  //   loginbtn = true
  // }else{
  //   loginbtn = false
  // }

  const handleInput = (e) => {
    if (e.target.name == "mobile") {
      if (e.target.value === "" || e.target.value === undefined) {
        setEmailError("Please enter your Mobile number.");
        setMobile("");
      } else if (e.target.value !== "" || e.target.value !== undefined) {
        if (!Number(e.target.value)) {
          setMobile("");
          return;
        }
        setMobile(e.target.value);
      }
    }

    if (
      (e.target.name == "otp" && e.target.value === "") ||
      e.target.value === undefined
    ) {
      setPasswordError("Please verify otp.");
      setOtp("");
    } else if (e.target.name == "otp") {
      setPasswordError("");
      if (!Number(e.target.value)) {
        setOtp("");
        return;
      }
      setOtp(e.target.value);
    }
  };

  // hit server to get otp
  function sendOtp() {
    if (mobile === "") {
      message.error("Please enter a valid Mobile no", 5);
    } else {
      setIsLoading(true);
      let res = Helper("check_user", "POST", { mobile: mobile }, false);
      res
        .then((response) => {
          if (response.error) {
            // console.log(response.error);
          } else {
            setOtpBtn(true);
            setReceiveOtp(response);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  // verify user otp
  function verifyOtp() {
    if (otp.length !== 4) {
      message.error("Please enter a valid One Time Password (OTP)", 5);
    } else {
      setIsOtpLoading(true);
      let res = Helper(
        "verify_otp",
        "POST",
        {
          otp: otp,
          mobile: mobile,
          deviceInfo: {
            deviceToken: props?.notification_token,
          },
        },
        false
      );
      res
        .then((response) => {
          if (response.error) message.error(response.error, "danger");
          else {
            if (response.isNewUser === 1)
              props.history.push({ pathname: "/signup", state: mobile });
            else {
              localStorage.setItem(
                "protax_web_access_token",
                response.accessToken
              );
              localStorage.setItem(
                "protax_web_user_info",
                JSON.stringify(response?.userInfo[0])
              );
              props.history.push("/");
            }
          }
        })
        .catch((error) => {
          // console.log(error)
        })
        .finally(() => {
          setIsOtpLoading(false);
        });
    }
  }

  function resendOTP() {
    setIsResending(true);
    let res = Helper("send_otp", "POST", {
      mobile,
    });
    res
      .then(async (response) => {
        if (response.error) toast.error(response?.error?.toString());
        else toast.info(response.message);
      })
      .catch((error) => {
        // console.log(error)
      })
      .finally(() => {
        setIsResending(false);
      });
  }

  return (
    <div>
      <Row className="py-5 my-5">
        <Col
          xs={{ span: 22, offset: 1 }}
          md={{ span: 12, offset: 6 }}
          lg={{ span: 6, offset: 9 }}
        >
          <Card bordered>
            <div className="side_logo_login text-center">
              {/* <img className="login-image" src={loginPanelImage} alt="" /> */}
              <img
                className="login-image hidden"
                src={loginImage}
                alt=""
              />
            </div>
            <div className="text-center">
              <h5>GET STARTED</h5>
              <Title level={5}>
                Majority of the tax returns would be below $49.99
              </Title>
              <div className="text-left w-100">
                <Space direction="vertical" className="w-100">
                  <label>Mobile</label>
                  <div>
                    <Input
                      type="text"
                      name="mobile"
                      id="mobile"
                      className="form-control"
                      value={mobile}
                      onChange={(e) => {
                        setMobile(e.target.value);
                        setOtpBtn(false);
                      }}
                      placeholder="Enter Mobile Number"
                      required
                      aria-required="true"
                    />
                  </div>
                  <Text strong type="danger">
                    <i class="fas fa-flag"></i> If you have an existing account online and your number has changed, Please contact at 905-930-7411
                  </Text>
                </Space>
              </div>

              {otpBtn ? (
                <div className="text-left">
                  <div className="form-group">
                    <label>One Time Password</label>
                    <Input
                      type="text"
                      name="otp"
                      className="form-control"
                      onChange={(e) => setOtp(e.target.value)}
                      value={otp}
                      placeholder="Enter One Time Password"
                      required
                      aria-required="true"
                    />
                    <div
                      className="resend_otp"
                      onClick={() => {
                        !isResending && resendOTP();
                      }}
                    >
                      {isResending ? <Spin /> : "Resend One Time Password"}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="form-group">
                {otpBtn ? (
                  <Button
                    type="primary"
                    onClick={() => verifyOtp()}
                    className="_btn_04 protax-btn"
                    loading={isOtpLoading}
                  >
                    Verify One Time Password
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => sendOtp()}
                    className="_btn_04 protax-btn"
                    loading={isLoading}
                  >
                    Send One Time Password
                  </Button>
                )}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const styles = {
  defaultButton: {
    height: "45px",
    fontSize: "20px",
  },
  leftImageContainer: {
    width: "100%",
    height: "100%",
    background: "#fff",
    borderRadius: "6px 0 0 6px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  rightContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
};

const mapStateToProps = () => (state) => ({
  notification_token: state.Pickup.notification_token,
});

export default connect(mapStateToProps)(Login);
