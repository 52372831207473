import React, { useState, useEffect } from "react";

//pages
// import Contact from './pages/contact';
// import Home from './pages/home';
// import Aboutus from './pages/aboutus';
// import Services from './pages/services';
// import Rates from './pages/rates';
// import Taxtips from './pages/taxtips';
import Login from "./pages/login";
import Signup from "./pages/signup";
import Dashboard from "./pages/dashboard";
import Status from "./pages/dashboard/Status";
import Order from "./pages/order";
import Signature from "./pages/signature";
import DocumentUpload from "./pages/document";
import Transaction from "./pages/transaction";

//form
import Form from "./pages/reqform";

//layout
import Layout from "./common/components/Layout";
import { ToastContainer } from "react-toastify";

//routing
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./common/consts/PrivateRoute";

//stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

//constants
import Helper from "./common/consts/Helper";
import { PUB_KEY_STRIPE } from "./common/consts/Config";

//css
import "antd/dist/antd.css";
import "react-responsive-modal/styles.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import "react-circular-progressbar/dist/styles.css";

//redux imports
import { connect } from "react-redux";
import { handleMultipleChange, handleChange } from "./actions/PickupActions";

//firebase
import firebase from "./firebase";
import { checkDateRangeMonth } from "./common/utils";
import AppointmentSchedule from "./pages/reqform/steps/AppointmentSchedule";

function App(props) {
  const stripePromise = loadStripe(PUB_KEY_STRIPE);

  const [isFetchingJson, setIsFetchingJson] = useState(false);

  let { handleMultipleChange, handleChange } = props;

  function checkLocalStorage() {
    let result = localStorage.getItem("_pickupState");
    if (result) {
      let obj = JSON.parse(result);

      if (
        obj &&
        obj?.isOldData &&
        obj?.timestampForPickupState &&
        checkDateRangeMonth(obj.timestampForPickupState)
      ) {
        handleMultipleChange({
          ...obj,
          isOldData: true,
        });
        return obj;
      }
    }
  }

  useEffect(() => {
    let result = checkLocalStorage();
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      Notification.requestPermission()
        .then(() => {
          return messaging.getToken();
        })
        .then((token) => {
          if (token) {
            handleMultipleChange({
              notification_token: token,
              isOldData: result ? true : false,
            });
          }
        })
        .catch((error) => {});
    }
  }, []);

  // function fetchJson() {
  //   setIsFetchingJson(true);
  //   let res = Helper('read_json', 'GET');
  //   res.then((response) => {
  //     if (response?.data)
  //       handleMultipleChange(response.data)
  //   })
  //     .catch((error) => { })
  //     .finally(() => {
  //       setIsFetchingJson(false);
  //     })
  // }

  return (
    <Elements stripe={stripePromise}>
      <Router>
        <Switch>
          <Layout>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            {/* <Route path="/contact" component={Contact} />
            <Route path="/services" component={Services} />
            <Route path="/rates" component={Rates} />
            <Route path="/taxtips" component={Taxtips} />
            <Route path="/aboutus" component={Aboutus} /> */}

            <PrivateRoute
              path="/orders"
              component={Order}
              redirectUrl="/login"
            />
            <PrivateRoute
              path="/transaction"
              component={Transaction}
              redirectUrl="/transaction"
            />
            <PrivateRoute
              path="/status/:statusLimit"
              component={Status}
              redirectUrl="/login"
            />
            <PrivateRoute
              path="/signature"
              component={Signature}
              redirectUrl="/login"
            />
            <PrivateRoute
              path="/tax_return"
              component={Form}
              redirectUrl="/login"
            />
            <PrivateRoute
              path="/documents"
              component={DocumentUpload}
              redirectUrl="/login"
            />
            <PrivateRoute
              path="/appointment/:id"
              component={AppointmentSchedule}
              redirectUrl="/login"
            />

            <PrivateRoute
              exact
              path="/"
              component={Dashboard}
              redirectUrl="/login"
            />
            {/* <Route exact path="/" component={Home} /> */}
          </Layout>
        </Switch>
        <ToastContainer />
      </Router>
    </Elements>
  );
}

const mapDispatchToProps = () => (dispatch) => ({
  handleMultipleChange: (state) => dispatch(handleMultipleChange(state)),
  handleChange: (name, payload) => dispatch(handleChange(name, payload)),
});

export default connect(null, mapDispatchToProps)(App);
