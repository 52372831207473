import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <>
      <footer className="page-footer">
        <div className="row">
          <div className="row">
            <div className="col l2 s12 center-align" style={{ marginTop: '15px' }}>

              <a href="https://www.facebook.com/protaxcanada/">
                <div className="col l12 m4 side_social"><i class="fab fa-facebook-f fa-2x"></i></div>
              </a>
              <div className="col l12 m4 side_social"><i class="fab fa-twitter fa-2x"></i></div>
              <div className="col l12 m4 side_social"><i className="fab fa-2x fa-google-plus" aria-hidden="true"></i></div>

            </div>
            <div className="col l5 s12 bottm">
              <ul className="sub-font">
                <h5 className="white-text">New Services</h5>
                <li><a href="https://www.protaxcanada.ca/services.php" className="grey-text text-lighten-3">RRSP,TFSA,RESP</a></li>
                <li><a href="https://www.protaxcanada.ca/services.php" className="grey-text text-lighten-3">Life Insurence</a></li>
                <li><a href="https://www.protaxcanada.ca/services.php" className="grey-text text-lighten-3">Critical Illness Insurence</a></li>
                <li><a href="https://www.protaxcanada.ca/services.php" className="grey-text text-lighten-3">Travel Insurence </a></li>
                <li><a href="https://www.protaxcanada.ca/services.php" className="grey-text text-lighten-3">Super Visa Insurence </a></li>
                <li><a href="https://www.protaxcanada.ca/termsandconditions.php" className="grey-text text-lighten-3">Terms and Conditions </a></li>
                <li><a href="https://www.protaxcanada.ca/refundpolicy.php" className="grey-text text-lighten-3" >Refund Policy </a></li>
                <li><a href="https://www.protaxcanada.ca/privacypolicy.php" className="grey-text text-lighten-3" >Privacy Policy </a></li>
                <li><a href="https://www.protaxcanada.ca/legaldisclaimer.php" className="grey-text text-lighten-3">Legal Disclaimer </a></li>
              </ul>
            </div>
            <div className="col l4  s12 bottm">
              <h5 className="white-text">BUSINESS HOURS</h5>
              <ul>
                <li><a className="grey-text text-lighten-3" href="#!">MON 9AM TO 6PM</a></li>
                <li><a className="grey-text text-lighten-3" href="#!">TUE 9AM TO 6PM</a></li>
                <li><a className="grey-text text-lighten-3" href="#!">WED 9AM TO 6PM</a></li>
                <li><a className="grey-text text-lighten-3" href="#!">THU 9AM TO 6PM</a></li>
                <li><a className="grey-text text-lighten-3" href="#!">FRI 9AM TO 6PM</a></li>
                <li><a className="grey-text text-lighten-3" href="#!">SAT 9AM TO 6PM</a></li>
                <li><a className="grey-text text-lighten-3" href="#!">SUN CLOSED</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container">
            &copy; 2010 Copyright by nugeninfo.com
      <a className="grey-text text-lighten-4 right" href="https://www.facebook.com/protaxcanada/"><i className="fa fa-facebook" aria-hidden="true"></i> &nbsp; </a>
            <a className="grey-text text-lighten-4 right" href="#!"><i className="fa fa-twitter" aria-hidden="true"></i> &nbsp; </a>
            <a className="grey-text text-lighten-4 right" href="#!"><i className="fa fa-google-plus" aria-hidden="true"></i> &nbsp; </a>
          </div>
        </div>
      </footer>
    </>
  )
}
