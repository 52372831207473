import { Spin } from "antd";
import React from "react";
import Docusign from "../../../assets/docusign.png";

export default function DocusignLoader(props) {
  const { active } = props;
  if (active)
    return (
      <div className="dosign-loader">
        <div className="dosign-cont">
          <h4>Please wait..</h4>
          <h6>Please drag your signature to the signature position in the form. You are getting redirected to Zoho.</h6>
          <Spin style={{
              marginTop: '12px'
          }}/>
          <img
            alt="docusign"
            src={Docusign}
            width={"200"}
            style={{ marginTop: "24px" }}
          />
        </div>
      </div>
    );
  return null;
}
