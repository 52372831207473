import React, { useEffect, useState } from 'react';

//antd
import { Row, Col, Button, Card, Divider } from 'antd';
import moment from 'moment';

//images
import ImageSuccess from '../../assets/paymentSuccess.png';
import ImageFailure from '../../assets/paymentFailure.png';
import { BASE_URL, PHOTO_URL } from '../../common/consts/Config';
import Paymentmodal from '../reqform/checklists/Paymentmodal';

export default function Transaction(props) {

    const {
        payment,
        totalAmount,
        orderId,
        discount
    } = props.history.location?.state;

    const [paymentModalVisible, setPaymentModalVisible] = useState(false);
    const [isReauthorized, setIsReauthorized] = useState(false);

    useEffect(() => {
        if (!orderId) {
            props.history.push('/orders');
        }
    }, []);

    function getAmountToRefund() {
        let transactions = payment?.slice() || [];
        let totalPaid = 0;
        let PaymentAmount = 0;
        for (const transaction of transactions) {
            if (transaction.status === 'succeeded' && transaction.paymentStatus !== 2) {
                totalPaid += +transaction.amount;
            }
        }
        totalPaid = +totalPaid / 100;
        PaymentAmount = +totalAmount - +totalPaid - +(discount || 0);
        return (PaymentAmount.toFixed(2));
    }

    function getAmountToTransact() {
        let transactions = payment?.slice() || [];
        let totalPaid = 0;
        let PaymentAmount = 0;
        for (const transaction of transactions) {
            if (transaction.status === 'succeeded' && transaction.paymentStatus !== 2) {
                totalPaid += +transaction.amount;
            }
        }

        totalPaid = +totalPaid / 100;
        PaymentAmount = +totalAmount - +totalPaid - +(discount || 0);
        return (PaymentAmount.toFixed(2));
    }

    let amountToTransact = getAmountToTransact();
    let amountToRefund = getAmountToRefund();

    return (
        <Row className="py-5">
            <Col xs={{ span: 20, offset: 2 }}>
                <div className="d-flex align-items-center mb-2">
                    <i
                        className="fas fa-chevron-left mr-2 fa-lg cursor-pointer p-1"
                        onClick={() => { props.history.goBack(); }}
                    ></i>
                    <h5 className="text-bold mb-0">Transaction Details</h5>
                </div>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
                {
                    +amountToRefund < 0 ? (
                        <div className="refund-box p-4">
                            <div className="fa-lg text-bold">
                                Payment {+amountToRefund < 0 ? 'Refund' : ''}
                            </div>
                            <div className="text-bold">$ {Math.abs(amountToRefund)}</div>
                            <div>
                                <div>Somebody from the office will contact you regarding the refund!!</div>
                            </div>

                        </div>
                    ) : (
                            null
                        )
                }
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
                {
                    +amountToTransact > 0 ? (
                        <Card>
                            <div>
                                <div className="text-bold fa-lg">
                                    Payment {+amountToTransact >= 0 ? 'Pending' : ''}
                                </div>
                                <div className="text-bold mt-1">$ {Math.abs(amountToTransact)}</div>
                                <Button
                                    type="primary"
                                    className="protax-btn mt-2"
                                    // isLoading={isLoading}
                                    onClick={() => {
                                        // handleCardPayPress(orderId)
                                        setPaymentModalVisible(true);
                                        setIsReauthorized(false);
                                    }}
                                >
                                    Pay Now
                            </Button>
                            </div>
                        </Card>
                    ) : (
                            null
                        )
                }
            </Col>
            {
                payment.map((transaction, index) => {
                    return (
                        <Col
                            key={index}
                            xs={{ span: 22, offset: 1 }}
                            md={{ span: 10, offset: 1 }}
                        >
                            <div
                                className="transaction-box mt-2"
                            >
                                <div className="transaction-box-child">
                                    <div className="d-flex flex-column justify-content-between align-items-center">
                                        <img
                                            width="90"
                                            src={(transaction.status === 'succeeded' && transaction.paymentStatus !== 2) ? ImageSuccess : ImageFailure}
                                        />
                                        <div className="text-bold">{renderStatusText(transaction.status, transaction.paymentStatus)}</div>
                                    </div>
                                    <div>
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <div className="text-grey">Date</div>
                                                <div className="text-bold">{moment(transaction.createdAt).format('MMM Do YYYY')}</div>
                                            </div>
                                            <Divider type="vertical" style={{ height: '2rem' }} />
                                            <div className="ml-2">
                                                <div className="text-grey">Amount</div>
                                                <div className="text-bold">$ {+transaction.amount / 100}</div>
                                            </div>
                                        </div>
                                        {
                                            renderReciptButton(transaction)
                                        }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )
                })
            }
            <Paymentmodal
                modalVisible={paymentModalVisible}
                setModalVisible={setPaymentModalVisible}
                amountToTransact={amountToTransact}
                getAmountToTransact={getAmountToTransact}
                payment={payment}
                totalAmount={totalAmount}
                orderId={orderId}
                history={props.history}
                isReauthorized={isReauthorized} 
            />
        </Row>
    )

    function renderReciptButton(transaction) {
        if (transaction.status === 'succeeded' && transaction.paymentStatus === 2) {
            return (
                <Button
                    type="primary"
                    className="protax-btn btn-success mt-2"
                    // isLoading={isLoading}
                    onClick={() => {
                        setPaymentModalVisible(true);
                        setIsReauthorized(transaction.amount);
                    }}
                >
                    Reauthorize
                </Button>
            )
        }
        else {
            return (
                <a href={`${transaction.receipt_url}`} target="_blank">
                    <Button
                        type="primary"
                        className="protax-btn btn-info text-bold mt-2"
                        disabled={transaction.status === 'succeeded' ? false : true}

                    >
                        View Receipt
                </Button>
                </a>
            )
        }
    }
    function renderStatusText(status, paymentStatus) {
        if (status === 'succeeded' && paymentStatus === 0) {
            return "Pre-Authorized"
        }
        else if (status === 'succeeded' && paymentStatus === 1) {
            return "Completed"
        }
        else if (status === 'succeeded' && paymentStatus === 2) {
            return "Expired"
        }
        else {
            return "Failed"
        }
    }
}