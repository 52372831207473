import React, { useState } from 'react';
import { Form, Input, Radio, DatePicker, Row, Col, Card, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

//Redux imports
import { connect } from 'react-redux';
import { handleChange, handleMultipleChange } from '../../../actions/PickupActions';

//constants
import { allRelations, allPostSecondaryStudent } from '../../../common/consts/ProtaxHelpers';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';

//custom components
import DependantsModal from './DependantsModal';


const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const headStyle = {
  background: "#b0000b",
  color: "#fff",
  borderRadius: "12px 12px 0px 0px",
  fontSize: '22px'
}

function DependentsInfo(props) {

  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);

  const { handleChange, handleMultipleChange, setCurrent, dependants, receiptsChecklist } = props;

  const onFinish = (values) => {
    let newDependants = dependants.slice();
    const id = new Date().getTime();
    newDependants.push({
      ...values,
      id,
      amount: ''
    });
    handleChange('dependants', newDependants);

    if (values.relation === 'children') {
      let newReceipts = receiptsChecklist.slice();
      let rIndex = newReceipts.findIndex(e => e.type === 'child');
      if (rIndex !== -1) {
        newReceipts[rIndex].childs.push({
          ...values,
          id,
          amount: ''
        });
        handleChange('receiptsChecklist', newReceipts);
      }
    }

    toast.success("Dependant Added Succesfully!!", {
      position: toast.POSITION.BOTTOM_CENTER
    });
    form.setFieldsValue({
      relation: '',
      firstName: '',
      middleName: '',
      lastName: '',
      netIncome: '',
      postSecondaryStudent: '',
      disabled: '',
    });
  };

  const history = useHistory();
  function saveDetails() {
    toast.success("Data Saved!");
    history.push("/");
  }

  return (
    <>
      <Row style={{ marginTop: "50px", marginBottom: "50px", display: "flex", justifyContent: "center" }}>
        <Col ld={{ span: 16 }} md={{ span: 20 }}>
          <Card
            title={
              <div>
                <Button
                  className="mr-2 protax-back-btn white-text"
                  size={"large"}
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => {
                    setCurrent((previousCurrent) => previousCurrent - 1);
                  }}
                />Enter Dependants Information</div>}
            headStyle={headStyle}
            extra={(
              <div
                className="dependant_num"
                onClick={() => { setModalVisible(true) }}
              >
                {dependants?.length || 0}
              </div>
            )}
            style={{ borderRadius: "12px" }}
          >
            <Form
              {...layout}
              form={form}
              name="dynamic_rule"
              onFinish={onFinish}
            >
              <Row>
                <Col xs={{ span: 24 }}>
                  <Form.Item
                    name="relation"
                    label="Relation"
                    rules={[
                      {
                        required: true,
                        message: 'Relation Required!',
                      },
                    ]}
                  >
                    <Radio.Group
                      name="radiogroup"
                      name="relation"
                    >
                      {
                        allRelations.map((relation, index) => {
                          return (
                            <Radio
                              value={relation.value}
                              key={index}
                            >
                              {relation.name}
                            </Radio>
                          )
                        })
                      }
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    initialValue={props.firstName}
                    rules={[
                      {
                        required: true,
                        message: 'First Name Required!',
                      },
                    ]}
                  >
                    <Input
                      style={{ height: "2rem" }}
                      value={props.firstName}
                      name="firstName"
                      // onChange={handleText}
                      placeholder="John"
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item
                    name="middleName"
                    label="Middle Name"
                  >
                    <Input
                      style={{ height: "2rem" }}
                      value={props.middleName}
                      name="middleName"
                      // onChange={handleText}
                      placeholder=" "
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input last name',
                      },
                    ]}
                  >
                    <Input
                      style={{ height: "2rem" }}
                      name="lastName"
                      // onChange={handleText}
                      value={props.lastName}
                      placeholder="Smith"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item
                    name="netIncome"
                    label="Net Income"
                  >
                    <Input
                      style={{ height: "2rem" }}
                      name="netIncome"
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item
                    name="dob"
                    label="D.O.B"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      className="m-0 w-100"
                    // onChange={(moment, value) => { handleChange('dob', value) }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item
                    // {...formItemLayout}
                    name="postSecondaryStudent"
                    style={{ width: "100%", display: "flex" }}
                    label="Post-Secondary Student"
                    rules={[
                      {
                        required: true,
                        message: 'Post-Secondary Student is Requred!'
                      },
                    ]}
                  >
                    <Radio.Group
                      name="postSecondaryStudent"
                    >
                      {
                        allPostSecondaryStudent.map((item, index) => {
                          return (
                            <Radio
                              value={item.value}
                              key={index}
                              className="text-capitalize"
                            >
                              {item.name}
                            </Radio>
                          )
                        })
                      }
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item
                    // {...formItemLayout}
                    name="disabled"
                    style={{ width: "100%", display: "flex" }}
                    label="Disability Tax Credit"
                    rules={[
                      {
                        required: true,
                        message: 'Disability Tax Credit is Requred!'
                      },
                    ]}
                  >
                    <Radio.Group
                      name="disabled"
                    >
                      {
                        allPostSecondaryStudent.map((item, index) => {
                          return (
                            <Radio
                              value={item.value}
                              key={index}
                              className="text-capitalize"
                            >
                              {item.name}
                            </Radio>
                          )
                        })
                      }
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>



              <Row gutter={24}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Button
                    type="dashed"
                    className="mr-1 protax-back-btn"
                    onClick={() => { setCurrent(previousCurrent => previousCurrent - 1) }}
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    className="protax-btn mr-1"
                    htmlType="submit"
                  >
                    Add
                  </Button>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }} className="d-flex justify-content-end">
                  {
                    props?.isEdit ? null : (
                      <Button
                        onClick={saveDetails}
                        type="dashed"
                        className=" mr-3"
                        htmlType="button"
                      >
                        Save and Exit
                      </Button>
                    )}
                  <Button
                    type="primary"
                    className="protax-btn"
                    onClick={nextStep}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col xs={24}>
                <DependantsModal
                  modalVisible={modalVisible}
                  setModalVisible={setModalVisible}
                />
              </Col>
            </Row>
          </Card>
        </Col >
      </Row >

    </>
  );
  function nextStep() {
    setCurrent(previousCurrent => previousCurrent + 1)
  }
}

const mapStateToProps = () => state => ({
  dependants: state.Pickup.dependants,
  tempDependantsInfo: state.Pickup.tempDependantsInfo,
  receiptsChecklist: state.Pickup.receiptsChecklist,
  isEdit: state.Pickup.isEdit

});
const mapDispatchToProps = () => dispatch => ({
  handleChange: (name, value) => dispatch(handleChange(name, value)),
  handleMultipleChange: (state) => dispatch(handleMultipleChange(state))
})

export default connect(mapStateToProps, mapDispatchToProps)(DependentsInfo);
