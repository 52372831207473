import React, { Fragment, useEffect } from "react";
import { Form, Radio, Row, Col, Card, Button, Input, Divider } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

//Redux imports
import { connect } from "react-redux";
import { handleChange } from "../../../actions/PickupActions";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

function StepFour(props) {
  const [form] = Form.useForm();
  const { questions, handleChange, setCurrent, fillingForSpouse } = props;

  const onFinish = (values) => {
    console.log(questions, " here is this");
    let validated = true;
    let error = "";
    for (const question of questions) {
      if (question.checked && question.inputBased && !question.inputValue) {
        validated = false;
        error = `${question.inputPlaceholder} is required!`;
        break;
      }
    }
    if (validated) {
      setCurrent((previousCurrent) => previousCurrent + 1);
    } else {
      alert(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const history = useHistory();
  function saveDetails() {
    toast.success("Data Saved!");
    history.push("/");
  }

  useEffect(() => {
    let questionResponse = {};
    let index = 0;
    for (const question of questions) {
      questionResponse[`question${index}`] = question.checked;
      index += 1;
    }
    form.setFieldsValue({
      ...questionResponse,
    });
  }, []);

  return (
    <>
      <Row
        style={{
          marginTop: "50px",
          marginBottom: "50px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Col ld={{ span: 16 }} md={{ span: 20 }}>
          <Card
            title={
              <div>
                <Button
                  className="mr-2 protax-back-btn white-text"
                  size={"large"}
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => {
                    setCurrent((previousCurrent) => previousCurrent - 1);
                  }}
                />
                Answer Yes or No to the following Questions
              </div>
            }
            headStyle={{
              background: "#b0000b",
              color: "#fff",
              borderRadius: "12px 12px 0px 0px",
            }}
            style={{ borderRadius: "12px" }}
          >
            <Form form={form} name="dynamic_rule" onFinish={onFinish}>
              {questions.map((ques, index) => {
                return (
                  <Row gutter={24} key={index}>
                    {ques.questions.map((textQues, qi) => {
                      return (
                        <Fragment key={qi}>
                          <Col lg={{ span: 18 }} xs={{ span: 24 }}>
                            <li
                              style={{
                                listStyleType: "disc",
                                marginLeft: "20px",
                              }}
                            >
                              {textQues.question}
                            </li>
                          </Col>
                        </Fragment>
                      );
                    })}
                    <Col
                      lg={{ span: 5 }}
                      xs={{ span: 24 }}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "20px",
                      }}
                    >
                      <Form.Item
                        name={`question${index}`}
                        rules={[
                          {
                            required: true,
                            message: "Please Select yes or no",
                          },
                        ]}
                      >
                        <Radio.Group
                          name="radiogroup"
                          onChange={(e) => {
                            handleQuestionCheckInfo(index, e.target.value);
                            checkAndPushNewQuestion(
                              e.target.value,
                              ques.conditional
                            );
                          }}
                        >
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    {fillingForSpouse && ques.checked && ques.askSpouse ? (
                      <Fragment>
                        <div
                          style={{ flex: 1, marginLeft: 55, marginRight: 30 }}
                        >
                          <div>
                            {ques?.questionForSpouse ||
                              "Is this Valid for Spouse?"}
                          </div>
                        </div>
                        <div
                          style={{
                            flexDirection: "row",
                            width: "100%",
                            padding: "20px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            justifyContent: "space-around",
                          }}
                        >
                          <Col
                            lg={{ span: 5 }}
                            xs={{ span: 24 }}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "20px",
                            }}
                          >
                            <Form.Item
                              style={{
                                marginBottom: "10px",
                              }}
                              name={`questionSpouse${index}`}
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select yes or no",
                                },
                              ]}
                            >
                              <Radio.Group
                                name="radiogroup"
                                onChange={(e) => {
                                  handleQuestionCheckInfo(
                                    index,
                                    e.target.value,
                                    true
                                  );
                                  checkAndPushNewQuestion(
                                    e.target.value,
                                    ques.conditional
                                  );
                                }}
                              >
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </div>
                      </Fragment>
                    ) : null}
                    {ques.checked &&
                    ques.inputBased &&
                    ques.inputBasedType === 2 ? (
                      <div
                        style={{
                          flex: 1,
                          marginLeft: 55,
                          marginRight: 30,
                          marginBottom: 30,
                          marginTop: 10,
                        }}
                      >
                        <Input
                          className="questionInputBox"
                          value={ques.inputValue}
                          placeholder={`${ques?.inputPlaceholder}`}
                          onChange={(e) => {
                            handleInputValue(e.target.value, index);
                          }}
                          defaultValue={1}
                          min={1}
                          type="number"
                        />
                      </div>
                    ) : null}
                    <Divider />
                  </Row>
                );
              })}

              <Row gutter={24}>
                <Col xs={{ span: 12 }}>
                  <Button
                    type="dashed"
                    className="mr-2 protax-back-btn"
                    onClick={() => {
                      setCurrent((previousCurrent) => previousCurrent - 1);
                    }}
                  >
                    Back
                  </Button>
                  {props?.isEdit ? null : (
                    <Button
                      onClick={saveDetails}
                      type="dashed"
                      className=" mr-3"
                      htmlType="button"
                    >
                      Save and Exit
                    </Button>
                  )}
                  <Button
                    type="primary"
                    className="protax-btn"
                    htmlType="submit"
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );

  function handleInputValue(value, index) {
    let newQuestions = questions.slice();
    newQuestions[index].inputValue = value;
    handleChange("questions", newQuestions);
  }
  function checkAndPushNewQuestion(checked, conditional) {
    let newQuestions = questions.slice();
    if (checked && conditional) {
      newQuestions.push({
        questions: [
          {
            question: "Is this your first ever house ?",
          },
        ],
        checked: "",
      });
    } else if (!checked && conditional && newQuestions.length === 12) {
      newQuestions.pop();
    }
    handleChange("questions", newQuestions);
  }
  function handleQuestionCheckInfo(index, value, askSpouse) {
    let newQuestions = questions.slice();
    if (askSpouse) newQuestions[index].spouseChecked = value;
    else newQuestions[index].checked = value;
    handleChange("questions", newQuestions);
  }
}

const mapStateToProps = (state) => ({
  questions: state.Pickup.questions,
  fillingForSpouse: state.Pickup.fillingForSpouse,
  isEdit: state.Pickup.isEdit,
});

const mapDispatchToProps = (dispatch) => ({
  handleChange: (name, value) => dispatch(handleChange(name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepFour);
