import React, { useState, useEffect } from "react";

//ant design
import {
  Form,
  Checkbox,
  Button,
  Row,
  Col,
  Card,
  Layout,
  Upload,
  message,
  Modal,
  Divider,
  Spin,
  Image,
  Input,
  Select,
} from "antd";
import {
  ArrowLeftOutlined,
  UploadOutlined,
  PlusOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

//Redux imports
import { connect, useSelector } from "react-redux";
import { handleChange } from "../../../actions/PickupActions";

//constants
import { BASE_URL, PHOTO_URL } from "../../../common/consts/Config";
import Helper from "../../../common/consts/Helper";
import { allDisabilityTaxCredit } from "../../../common/consts/ProtaxHelpers";

import EmailModal from "./emailModal";
import ViewOrder from "../../viewOrder";
// file upload
const { Option } = Select;

function CheckTwo(props) {
  const {
    slipsChecklist,
    spouseSlipsChecklist,
    spouseReceiptsChecklist,
    receiptsChecklist,
    spouseOtherDocChecklist,
    otherDocChecklist,
    fillingForSpouse,
    Pickup,
    handleChange,
    setCurrent,
  } = props;

  const [form] = Form.useForm();

  const [isUploading, setIsUploading] = useState({
    isUploading: false,
    isUploadingIndex: null,
    isUploadingParentIndex: null,
  });

  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [documentType, setDocumentType] = useState(false);
  const [documentIsForSpouse, setDocumentIsForSpouse] = useState(false);

  const onFinish = (values) => {
    // console.log('Success:', values);
  };

  let allChecklists = [
    {
      name: "Slips",
      title: "Select the applicable income Slips",
      checklist: slipsChecklist,
      variableName: "slipsChecklist",
    },
    {
      name: "Receipts",
      title: "Select the applicable Receipts",
      checklist: receiptsChecklist,
      variableName: "receiptsChecklist",
    },
    {
      name: "Other Documents",
      title: "Select the applicable Other Documents",
      checklist: otherDocChecklist,
      variableName: "otherDocChecklist",
    },
  ];

  if (fillingForSpouse) {
    allChecklists.push({
      name: "Slips - Spouse",
      title: "Select the applicable income Slips",
      checklist: spouseSlipsChecklist,
      variableName: "spouseSlipsChecklist",
    });
    allChecklists.push({
      name: "Receipts - Spouse",
      title: "Select the applicable income Slips",
      checklist: spouseReceiptsChecklist,
      variableName: "spouseReceiptsChecklist",
    });
    allChecklists.push({
      name: "Other Documents - Spouse",
      title: "Select the applicable income Slips",
      checklist: spouseOtherDocChecklist,
      variableName: "spouseOtherDocChecklist",
    });
  }

  // DOCUMENT

  const Questions = useSelector((state) => state.Pickup?.questions);
  const [tFormNumber, setTFormNumber] = useState(1);

  const numberOfProp =
    +Questions?.find((e) => e.inputPlaceholder === "Number Of Properties")
      ?.inputValue || 1;
  const numberOfBusiness =
    +Questions?.find((e) => e.inputPlaceholder === "Number of businesses")
      ?.inputValue || 1;

  function getNumberOfFormGenerations(type) {
    let numberOfForms = 1;
    if (type === "rental") {
      numberOfForms = numberOfProp;
    } else if (type === "business") {
      numberOfForms = numberOfBusiness;
    }
    return numberOfForms;
  }

  const rentalArr = new Array(getNumberOfFormGenerations("rental")).fill(
    "DefaultValue"
  );
  const businessArr = new Array(getNumberOfFormGenerations("business")).fill(
    "DefaultValue"
  );

  const autoArr = [1];

  function getDocArr(docType) {
    if (docType === 3) {
      return rentalArr;
    } else if (docType === 1) {
      return businessArr;
    } else return autoArr;
  }

  function getDocTitle(docType) {
    if (docType === 3) {
      return "Property";
    } else if (docType === 1) {
      return "Business";
    } else return "Automobile";
  }

  const history = useHistory();
  function saveDetails() {
    toast.success("Data Saved!");
    history.push("/");
  }

  // DOCUMENT
  return (
    <>
      <EmailModal
        modalVisible={emailModalVisible}
        documentType={documentType}
        setModalVisible={setEmailModalVisible}
        documentIsForSpouse={documentIsForSpouse}
        tFormNumber={tFormNumber}
      />
      <Row
        style={{
          marginTop: "50px",
          marginBottom: "50px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Col ld={{ span: 16 }} md={{ span: 20 }}>
          <Card
            title={
              <div>
                <Button
                  className="mr-2 protax-back-btn white-text"
                  size={"large"}
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => {
                    setCurrent((previousCurrent) => previousCurrent - 1);
                  }}
                />
                Checklist
              </div>
            }
            headStyle={{
              background: "#b0000b",
              color: "#fff",
              borderRadius: "12px 12px 0px 0px",
              fontSize: "22px",
            }}
            style={{ borderRadius: "12px" }}
          >
            <Form form={form} name="dynamic_rule">
              {allChecklists.map((checklist, index) => {
                return (
                  <Row key={index} gutter={24}>
                    <Col xs={{ span: 24 }}>
                      <h5 className="text-uppercase">{checklist.name}</h5>
                      <h6 className="text-grey mb-1">{checklist.title}</h6>
                    </Col>
                    <Col>
                      <Row gutter={24}>
                        {checklist?.checklist?.map((list, listIndex) => {
                          return (
                            <Col xs={{ span: 24 }} key={listIndex}>
                              <Form.Item className="mb-0 mt-2">
                                <Checkbox
                                  className="checked"
                                  style={{ fontSize: "16px", marginBottom: 0 }}
                                  onChange={(e) => {
                                    const { variableName } = checklist;
                                    const { checked } = e.target;
                                    let newChecklist =
                                      Pickup[variableName].slice();
                                    newChecklist[listIndex].checked = checked;
                                    handleChange(variableName, newChecklist);
                                  }}
                                  checked={list.checked}
                                >
                                  {list.title}
                                </Checkbox>
                              </Form.Item>
                              <Row>
                                <Col xs={{ span: 24 }}>
                                  {list.checked &&
                                    list.type === "political" &&
                                    list.typeOfContribution.map(
                                      (contribution, contIndex) => {
                                        return (
                                          <div className="row" key={contIndex}>
                                            <div className="col-sm-12 col-md-3">
                                              <div className="d-flex flex-column">
                                                <label>
                                                  {contribution.title}
                                                </label>
                                                <Select
                                                  placeholder="Marital Status"
                                                  className="text-capitalize w-100"
                                                  value={contribution.checked}
                                                  onChange={(value) => {
                                                    handleCheckboxPolitical(
                                                      value,
                                                      listIndex,
                                                      contIndex,
                                                      "checked",
                                                      checklist.variableName
                                                    );
                                                  }}
                                                >
                                                  {allDisabilityTaxCredit.map(
                                                    (item, index) => {
                                                      return (
                                                        <Option
                                                          className="text-capitalize"
                                                          key={index}
                                                          value={item.value}
                                                        >
                                                          {item.name}
                                                        </Option>
                                                      );
                                                    }
                                                  )}
                                                </Select>
                                              </div>
                                            </div>
                                            {contribution.checked ? (
                                              <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                  <label>Amount</label>
                                                  <Input
                                                    value={contribution.amount}
                                                    placeholder="Amount"
                                                    prefix={
                                                      <DollarCircleOutlined />
                                                    }
                                                    onChange={(e) => {
                                                      handleCheckboxPolitical(
                                                        e.target.value,
                                                        listIndex,
                                                        contIndex,
                                                        "amount",
                                                        checklist.variableName
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        );
                                      }
                                    )}
                                  {list.checked &&
                                    list.type === "child" &&
                                    list.childs.map((child, ci) => {
                                      return (
                                        <div key={ci}>
                                          <div className="d-flex flex-column">
                                            <label className="text-capitalize text-bold">
                                              {child.firstName}
                                            </label>
                                            <Input
                                              value={child.amount}
                                              placeholder="Amount"
                                              prefix={<DollarCircleOutlined />}
                                              onChange={(e) => {
                                                handleChildValue(
                                                  e.target.value,
                                                  listIndex,
                                                  ci,
                                                  checklist.variableName
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}
                                  {list.checked && list.type === "inputs"
                                    ? list.inputs.map((inputDetails, ci) => {
                                      return (
                                        <div key={ci}>
                                          <Input
                                            value={inputDetails.value}
                                            placeholder={
                                              inputDetails?.placeholder
                                            }
                                            prefix={<DollarCircleOutlined />}
                                            onChange={(e) => {
                                              handleListText(
                                                e.target.value,
                                                listIndex,
                                                ci,
                                                checklist.variableName
                                              );
                                            }}
                                          />
                                        </div>
                                      );
                                    })
                                    : null}
                                  {list.checked && list.type === "images" ? (
                                    <>
                                      <div className="checklistImageContainer">
                                        {list.images.map((image, ci) => {
                                          // console.log(image, "image is")
                                          return (
                                            <div
                                              className="checklistImage"
                                              key={ci}
                                            >
                                              {checkFileType(image) ? (
                                                <div className="pdf-container">
                                                  <i class="fas fa-file-alt fa-2x"></i>

                                                  <Button
                                                    type="primary"
                                                    onClick={() => {
                                                      window.open(
                                                        PHOTO_URL + image,
                                                        "_blank"
                                                      );
                                                    }}
                                                    className="protax-btn mt-2"
                                                  >
                                                    View File
                                                  </Button>
                                                </div>
                                              ) : (
                                                <Image
                                                  src={PHOTO_URL + image}
                                                  height={150}
                                                  className="checklistImage ml-0"
                                                />
                                              )}
                                              <div
                                                className="checklistCross"
                                                onClick={() => {
                                                  deleteImage(
                                                    listIndex,
                                                    ci,
                                                    checklist.variableName
                                                  );
                                                }}
                                              >
                                                <i className="fa fa-times" />
                                              </div>
                                            </div>
                                          );
                                        })}
                                        {isUploading.isUploading &&
                                          isUploading.isUploadingIndex ===
                                          listIndex &&
                                          isUploading.isUploadingParentIndex ===
                                          index ? (
                                          <div className="spinner-image">
                                            <Spin />
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="col-md-12 mb-2 mt-3">
                                        <Upload
                                          accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint application/vnd.openxmlformats-officedocument.presentationml.presentation text/csv"
                                          beforeUpload={() => false}
                                          showUploadList={false}
                                          onChange={(e) => {
                                            handleImage(
                                              e.file,
                                              index,
                                              listIndex,
                                              checklist.variableName
                                            );
                                          }}
                                        >
                                          <Button icon={<UploadOutlined />}>
                                            Click to Upload
                                          </Button>
                                        </Upload>
                                      </div>
                                    </>
                                  ) : null}
                                  {list.checked && list.showEmailButton && (
                                    <div className="d-flex flex-column mxw-300">
                                      {getDocArr(list.documentType)?.map(
                                        (item, index) => {
                                          return (
                                            <Button
                                              key={index}
                                              type="primary"
                                              className="protax-btn btn-info mt-2"
                                              onClick={() => {
                                                setEmailModalVisible(true);
                                                setDocumentType(
                                                  list.documentType
                                                );
                                                setDocumentIsForSpouse(
                                                  checklist.name
                                                    ?.toLowerCase()
                                                    ?.includes("spouse")
                                                    ? true
                                                    : false
                                                );
                                                setTFormNumber(index + 1);
                                              }}
                                            >
                                              {`${getDocTitle(
                                                list.documentType
                                              )} ${list.documentType !== 2
                                                  ? `- ${index + 1}`
                                                  : ""
                                                } : ${list?.emailButtonTitle}`}
                                            </Button>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                    <Divider />
                  </Row>
                );
              })}

              <Row gutter={24}>
                <Col xs={{ span: 24 }}>
                  <Button
                    type="dashed"
                    className="mr-2 protax-back-btn"
                    onClick={() => {
                      setCurrent((previousCurrent) => previousCurrent - 1);
                    }}
                  >
                    Back
                  </Button>
                  {
                    props?.isEdit ? null : (
                      <Button
                        onClick={saveDetails}
                        type="dashed"
                        className=" mr-3"
                        htmlType="button"
                      >
                        Save and Exit
                      </Button>
                    )
                  }
                  <Button
                    type="primary"
                    className="protax-btn"
                    onClick={() => {
                      validateAndProceed();
                    }}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );

  function checkFileType(image) {
    let isFile = false;
    if (
      image.endsWith(".pdf") ||
      image.endsWith(".doc") ||
      image.endsWith(".docx") ||
      image.endsWith(".ppt") ||
      image.endsWith(".pptx") ||
      image.endsWith(".csv")
    ) {
      isFile = true;
    }
    return isFile;
  }
  function validateAndProceed() {
    //slips checks
    let atleastOneChecked = false;
    let atleastOneCheckedSpouse = false;
    let atleastOneSlipUploaded = true;
    let atleastOneSlipUploadedSpouse = true;

    for (const checklist of slipsChecklist) {
      if (checklist.checked) atleastOneChecked = true;
      if (
        checklist.checked &&
        checklist.type === "images" &&
        checklist.images.length === 0
      )
        atleastOneSlipUploaded = false;
    }
    if (fillingForSpouse) {
      for (const checklist of spouseSlipsChecklist) {
        if (checklist.checked) atleastOneCheckedSpouse = true;
        if (
          checklist.checked &&
          checklist.type === "images" &&
          checklist.images.length === 0
        )
          atleastOneSlipUploadedSpouse = false;
      }
    }

    //receipts checks
    let receiptValidated = validateReceipts(receiptsChecklist);

    if (fillingForSpouse) {
      var receiptValidatedSpouse = validateReceipts(spouseReceiptsChecklist);
    }

    //other
    let otherValidated = validateReceipts(otherDocChecklist);

    if (fillingForSpouse) {
      var otherValidatedSpouse = validateReceipts(spouseOtherDocChecklist);
    }

    //errors
    if (!atleastOneChecked) {
      message.error("Please select atleast one Slip to continue!!", 10);
    } else if (!atleastOneSlipUploaded) {
      message.error("Please upload atleast one Slip to continue!!", 10);
    } else if (!atleastOneCheckedSpouse && fillingForSpouse) {
      message.error(
        "Please select atleast one Slip in spouse to continue!!",
        10
      );
    } else if (!atleastOneSlipUploadedSpouse) {
      message.error(
        "Please upload atleast one Slip in spouse to continue!!",
        10
      );
    } else if (!receiptValidated.validated) {
      message.error(receiptValidated.error);
    } else if (fillingForSpouse && !receiptValidatedSpouse.validated) {
      message.error(receiptValidatedSpouse.error);
    } else if (!otherValidated.validated) {
      message.error(otherValidated.error);
    } else if (fillingForSpouse && !otherValidatedSpouse.validated) {
      message.error(otherValidatedSpouse.error);
    } else {
      setCurrent((previousCurrent) => previousCurrent + 1);
    }
  }

  function validateReceipts(parentChecklist) {
    let validated = true;
    let error = "";

    for (const checklist of parentChecklist) {
      let innerLoopbreaked = false;
      if (checklist.checked && checklist.type === "inputs") {
        for (const input of checklist.inputs) {
          if (input.value === "") {
            error = `Value for ${input.name} is required!`;
            validated = false;
            innerLoopbreaked = true;
            break;
          }
        }
      } else if (
        checklist.checked &&
        checklist.type === "images" &&
        checklist.images.length === 0
      ) {
        validated = false;
        error = `Please upload atleast one image/pdf regarding ${checklist.title} to continue!!`;
      } else if (checklist.checked && checklist.type === "child") {
        for (const child of checklist.childs) {
          if (child.amount === "") {
            error = `Amount for child ${child.firstName} is required!`;
            validated = false;
            innerLoopbreaked = true;
            break;
          }
        }
      } else if (checklist.checked && checklist.type === "political") {
        let nothingChecked = true;
        for (const el of checklist.typeOfContribution) {
          if (el.checked) nothingChecked = false;
          if (el.checked && el.amount === "") {
            error = `${el.title} Amount is required!`;
            validated = false;
            innerLoopbreaked = true;
            break;
          }
        }
        if (nothingChecked) {
          error = `Selection of any one ${checklist.title}`;
          validated = false;
          innerLoopbreaked = true;
        }
      }

      if (innerLoopbreaked) break;
    }

    return {
      validated,
      error,
    };
  }
  function handleCheckboxPolitical(
    value,
    parentIndex,
    index,
    variable,
    variableName
  ) {
    let newChecklist = Pickup[variableName].slice();
    newChecklist[parentIndex].typeOfContribution[index][variable] = value;
    handleChange(variableName, newChecklist);
  }
  function handleChildValue(value, parentIndex, index, variableName) {
    let newChecklist = Pickup[variableName].slice();
    newChecklist[parentIndex].childs[index].amount = value;
    handleChange(variableName, newChecklist);
  }
  function handleListText(value, parentIndex, index, variableName) {
    let newChecklist = Pickup[variableName].slice();
    newChecklist[parentIndex].inputs[index].value = value;
    handleChange(variableName, newChecklist);
  }

  function handleImage(file, index1, index2, variableName) {
    if (file) {
      let body = new FormData();
      body.append("image", file);
      setIsUploading({
        ...isUploading,
        isUploading: true,
        isUploadingIndex: index2,
        isUploadingParentIndex: index1,
      });
      let res = Helper("image", "POST", body, true);
      res
        .then((response) => {
          if (response.message) {
            message.success("Upload Successfull!!");
            saveImage(response.data, index1, index2, variableName);
          } else if (response.error) message.error(response.error.toString());
        })
        .catch((error) => {
          message.error(
            "Something went wrong while uploading the image, Please try again later!!"
          );
        })
        .finally(() => {
          setIsUploading({
            ...isUploading,
            isUploading: false,
            isUploadingIndex: index1,
            isUploadingParentIndex: index2,
          });
        });
    }
  }
  function saveImage(url, index1, index2, variableName) {
    let newChecklist = Pickup[variableName].slice();
    newChecklist[index2].images.push(url);
    handleChange(variableName, newChecklist);
  }
  function deleteImage(index, ci, variableName) {
    let confirmation = window.confirm("Are you sure you want to delete this?");
    if (confirmation) {
      let newChecklist = Pickup[variableName].slice();
      newChecklist[index].images.splice(ci, 1);
      handleChange(variableName, newChecklist);
    }
  }
}

const mapStateToProps = (state) => ({
  Pickup: state.Pickup,
  slipsChecklist: state.Pickup.slipsChecklist,
  spouseSlipsChecklist: state.Pickup.spouseSlipsChecklist,
  spouseReceiptsChecklist: state.Pickup.spouseReceiptsChecklist,
  receiptsChecklist: state.Pickup.receiptsChecklist,
  spouseOtherDocChecklist: state.Pickup.spouseOtherDocChecklist,
  otherDocChecklist: state.Pickup.otherDocChecklist,
  fillingForSpouse: state.Pickup.fillingForSpouse,
  isEdit: state.Pickup.isEdit
});

const mapDispatchToProps = (dispatch) => ({
  handleChange: (name, value) => dispatch(handleChange(name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckTwo);
