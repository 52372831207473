import React, { useState, useEffect } from "react";
import { Form, Input, Radio, DatePicker, Row, Col, Card, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

//Redux imports
import { connect } from "react-redux";
import {
  handleChange,
  handleMultipleChange,
} from "../../../actions/PickupActions";

//constants
import Helper from "../../../common/consts/Helper";
import moment from "moment";

import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const { TextArea } = Input;

function StepTwo(props) {
  const [form] = Form.useForm();

  const { handleChange, setCurrent } = props;
  const [callsPerSlot, setCallsPerSlot] = useState(25);
  const [slots, setSlots] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const [currentParams, setCurrentParams] = useState({
    timeSlot: "",
    anyOtherInfo: "",
    preferredDate: "",
  });
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const { getFieldValue } = form;

  const orderId = useParams();

  async function saveAppointment(body) {
    try {
      setIsFetching(true);
      let res = await Helper("schedule_appointment_for_oder", "POST", {
        ...body,
      });
      setIsFetching(false);
      if (res.error) {
        toast.error(res.error);
      } else {
        history.goBack();
        toast.success("Appointment Scheduled!!");
      }
    } catch (error) {
      setIsFetching(false);
    }
  }

  async function fetchOrderById(orderId) {
    try {
      setIsLoadingOrder(true);
      const response = await Helper("fetch_order_by_id", "POST", {
        orderId: orderId,
      });
      setIsLoadingOrder(false);
      if (response?.orders && response?.orders[0]) {
        const order = response?.orders[0];
        setCurrentParams({
          ...currentParams,
          timeSlot: order?.timeSlot,
          anyOtherInfo: order?.anyOtherInfo,
          preferredDate: order?.preferredDate,
        });
      }
    } catch (error) {
      setIsLoadingOrder(false);
    }
  }

  const onFinish = (values) => {
    // handleMultipleChange(values);
    saveAppointment({
      ...values,
      orderId: orderId.id,
    });
    console.log(values);
  };

  useEffect(() => {
    fetchOrderById(orderId?.id || "");
    // if (!isEdit) { condition here for not edit
    getPickupSlots(tomorrow);
    // }
  }, []);

  function getPickupSlots(date) {
    setIsFetching(true);
    let res = Helper("slots", "POST", {
      date: moment(date).format("YYYY-MM-DD"),
    });
    res
      .then((response) => {
        handleChange("timeSlot", "");
        form.setFieldsValue({
          timeSlot: "",
        });
        if (response.slots) setSlots(response.slots);
        if (response.maxSlots) setCallsPerSlot(response.maxSlots);
      })
      .catch((error) => {})
      .finally(() => {
        setIsFetching(false);
      });
  }
  const history = useHistory();

  const currentSelectedDate = getFieldValue("preferredDate");

  useEffect(() => {
    if (currentSelectedDate) getPickupSlots(currentSelectedDate);
  }, [currentSelectedDate]);

  return (
    <>
      <Row
        style={{
          marginTop: "50px",
          marginBottom: "50px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Col ld={{ span: 16 }} md={{ span: 20 }}>
          <Card
            title={
              <div>
                <Button
                  className="mr-2 protax-back-btn white-text"
                  size={"large"}
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => {
                    history.goBack();
                  }}
                />
                Select preferred Date and Time to finalize your return
              </div>
            }
            headStyle={{
              background: "#b0000b",
              color: "#fff",
              borderRadius: "12px 12px 0px 0px",
              fontSize: "22px",
            }}
            style={{ borderRadius: "12px" }}
          >
            {currentParams?.timeSlot && currentParams?.preferredDate && (
              <>
                <h5>
                  Your Appointment is Already book for -{" "}
                  {moment(currentParams.preferredDate || moment()).format(
                    "YYYY-MM-DD"
                  )}{" "}
                  ({currentParams.timeSlot})
                </h5>
                <h6>
                  In case you wish to change then please fill the form below.
                </h6>
              </>
            )}
            <Form name="dynamic_rule" onFinish={onFinish} form={form}>
              <Row gutter={24}>
                <Col xs={{ span: 24 }}>
                  <Form.Item
                    name="preferredDate"
                    label="Preferred Date"
                    rules={[
                      {
                        required: true,
                        message: "Preferred Date is required",
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={(d) => !d || d.isBefore(tomorrow)}
                      className="m-0 w-100"
                      onChange={(moment, value) => {
                        handleChange("preferredDate", value);
                        handleChange("timeSlot", "");
                        form.setFieldsValue({
                          timeSlot: "",
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={{ span: 24 }}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Form.Item
                    name="timeSlot"
                    style={{ width: "100%", display: "flex" }}
                    label="Preferred Time Slot"
                    rules={[
                      {
                        required: true,
                        message: "Time slot is required",
                      },
                    ]}
                  >
                    <div style={{ display: "flex", width: "100%" }}>
                      <Radio.Group
                        onChange={(e) => {
                          handleChange("timeSlot", e.target.value);
                        }}
                        name="timeSlot"
                        value={props?.timeSlot}
                      >
                        <Radio
                          disabled={getDisablity("09:00 am - 12:00 pm", 12)}
                          value={"09:00 AM - 12:00 PM"}
                        >
                          09:00 AM - 12:00 PM
                        </Radio>
                        <Radio
                          disabled={getDisablity("12:00 pm - 03:00 pm", 15)}
                          value={"12:00 PM - 03:00 PM"}
                        >
                          12:00 PM - 03:00 PM
                        </Radio>
                        <Radio
                          disabled={getDisablity("03:00 pm - 06:00 pm", 18)}
                          value={"03:00 PM - 06:00 PM"}
                        >
                          03:00 PM - 06:00 PM
                        </Radio>
                        <Radio
                          disabled={getDisablity("06:00 pm - 09:00 pm", 21)}
                          value={"06:00 PM - 09:00 PM"}
                        >
                          06:00 PM - 09:00 PM
                        </Radio>
                      </Radio.Group>
                    </div>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={{ span: 24 }}>
                  <h6>Any Other Information (if any)</h6>
                  <Form.Item name="anyOtherInfo">
                    <TextArea
                      name="anyOtherInfo"
                      showCount
                      maxLength={100}
                      rows={6}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={{ span: 24 }}>
                  <Button
                    type="dashed"
                    className="mr-2 protax-back-btn"
                    onClick={() => {
                      setCurrent((previousCurrent) => previousCurrent - 1);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    className="protax-btn"
                    htmlType="submit"
                    loading={isFetching || isLoadingOrder}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
  function getDisablity(value, slotHour) {
    let disabled = false;
    let index = slots.findIndex((e) => e._id === value);
    const hours = moment(tomorrow).format("HH");
    const prefferedDate = moment(props.preferredDate);
    const diff = prefferedDate.diff(moment(today).startOf("day"), "days");
    if (index !== -1 && slots[index].totalOrders >= callsPerSlot) {
      disabled = true;
    } else if (slotHour <= +hours && diff <= 1) {
      disabled = true;
    }

    return disabled;
  }
}

const mapStateToProps = () => (state) => ({
  timeSlot: state.Pickup.timeSlot,
  preferredDate: state.Pickup.preferredDate,
  anyOtherInfo: state.Pickup.anyOtherInfo,
  isEdit: state?.Pickup?.isEdit,
});
const mapDispatchToProps = () => (dispatch) => ({
  handleChange: (name, value) => dispatch(handleChange(name, value)),
  handleMultipleChange: (state) => dispatch(handleMultipleChange(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
