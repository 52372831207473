import React, { useEffect, useState } from 'react';
import { Upload, message, Button, Form, Row, Col, Card, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

// helper
import Helper from '../../common/consts/Helper'

// toaster
import { toast } from 'react-toastify';

import { connect } from 'react-redux';

function Signup(props) {
  const [mobile, setMobile] = useState(props.history.location.state || "")
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false);

  // signup user
  function signUp(e) {
    e.preventDefault();
    setIsLoading(true);
    let res = Helper('signup', 'POST', {
      email, 
      mobile, 
      name, 
      deviceInfo: {
        deviceToken: props?.notification_token
      }
    });

    res.then(async (response) => {
      if (response.error) toast.error(response?.error?.toString(), 'danger');
      else {
        try {
          localStorage.setItem('protax_web_user_info', JSON.stringify(response.userInfo));
          localStorage.setItem('protax_web_access_token', response.accesstoken)
          props.history.push('/');
        }
        catch (error) {
          toast.error("Something went wrong, Please contact Admin!", 'danger');
        }
      }
    })
      .catch((error) => {
        toast.error("Something went wrong, please try again later!!", 'danger');
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (props.history.location.state) {
    }
    else {
      props.history.push('/login');
    }
  }, []);

  return (
    <>
      <div>
        <Row className="py-5 my-5">
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 9 }}>
            <Card bordered>
              <div className="side_logo_login text-center">
                <img src={process.env.PUBLIC_URL + '/img/signin-image.png'} style={{ width: '200px' }} />
              </div>
              <form onSubmit={signUp}>
                <div className="text-center">
                  <h5>REGISTER</h5>

                  <div className="text-left">
                    <label>Full Name</label>
                    <input
                      type="text"
                      name="name"
                      required
                      value={name}
                      className="form-control browser-default"
                      placeholder="Enter Name"
                      onChange={(e) => { setName(e.target.value) }}
                    />
                  </div>

                  <div className="form-group text-left">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      required
                      value={email}
                      className="form-control browser-default"
                      type="text"
                      placeholder="Enter Email"
                      onChange={(e) => { setEmail(e.target.value) }}
                    />
                  </div>

                  <div className="text-left">
                    <label>Mobile</label>
                    <input
                      type="number"
                      name="mobile"
                      value={mobile}
                      disabled
                      required
                      className="form-control browser-default"
                      type="text"
                      placeholder="Enter mobile"
                      onChange={(e) => { setMobile(e.target.value) }}
                    />
                  </div>

                  <div className="form-group">
                    <Button
                      loading={isLoading}
                      htmlType="submit"
                      type="primary"
                      className="_btn_04 protax-btn"
                    >
                      REGISTER
                  </Button>
                  </div>
                </div>
              </form>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = () => state => ({
  notification_token: state.Pickup.notification_token
});

export default connect(mapStateToProps)(Signup);