import React, { useState, useEffect } from "react";

import { Spin, Button, Row, Col, Modal } from "antd";
import { ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

//consts
import _ from "lodash";
import userImage from "../../assets/user.png";
import { PHOTO_URL } from "../../common/consts/Config";
// import documentImage from "../../assets/document.png";
import documentIcon from "../../assets/documentIcon.svg";
import UploadDocumentIcon from "../../assets/UploadDocumentIcon.svg";
//routing
import { Link } from "react-router-dom";
import Helper from "../../common/consts/Helper";

//loader
import OverviewLoader from "../../common/components/loaders/OverviewLoader";

import { connect } from "react-redux";
import {
  handleChange,
  handleMultipleChange,
} from "../../actions/PickupActions";
import { orderDetailsReset } from "../../actions/OrderActions";
import Prefill from "../reqform/prefill";
import IdProof from "./IdProof";
import LatestTaxForm from "./LatestTaxForm";
import Signature from "./Signature";
import Payment from "./Payment";
import { checkDateRangeMonth } from "../../common/utils";
import Appointment from "./Appointment";

const { confirm } = Modal;

function Dashboard(props) {
  let {
    handleChange,
    handleMultipleChange,
    previousAlert,
    orderDetailsReset,
    history,
  } = props;

  const [orderInfo, setOrderInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingJson, setIsFetchingJson] = useState(false);
  const [year, setYear] = useState("");

  const [prefillActive, setPrefillActive] = useState(false);

  useEffect(() => {
    getLatestOrder();
    fetchYear();
  }, []);

  function fetchYear() {
    let res = Helper("application", "GET");
    res
      .then((response) => {
        if (
          response?.appInfo &&
          response?.appInfo[0] &&
          response?.appInfo[0]?.year
        ) {
          setYear(response?.appInfo[0].year);
        }
      })
      .catch((error) => {})
      .finally(() => {});
    return res;
  }

  function fetchJson() {
    setIsFetchingJson(true);
    let res = Helper("v4/read_json", "GET");
    res
      .then((response) => {
        if (response?.data)
          handleMultipleChange({
            ...response.data,
            isOldData: true,
          });
      })
      .catch((error) => {})
      .finally(() => {
        setIsFetchingJson(false);
      });
    return res;
  }

  function getLatestOrder() {
    setIsLoading(true);
    let res = Helper("latest_order", "POST", {});
    res
      .then((response) => {
        if (response.orderInfo.length > 0) {
          setOrderInfo(response.orderInfo[0]);
        }
      })
      .catch((error) => {
        // console.log(error)
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  let userInfo = localStorage.getItem("protax_web_user_info") || {};
  try {
    userInfo = JSON.parse(userInfo);
  } catch (e) {
    // console.log(e)
  }

  let userInfoDetails;
  try {
    userInfoDetails = JSON.parse(localStorage?.getItem("protax_web_user_info"));
  } catch (error) {}

  const localresult = checkLocalStorageWithoutSet();
  return (
    <>
      <Row className="py-5">
        <Col xs={{ span: 20, offset: 2 }}>
          <h4 className="text-bold text-capitalize">
            {userInfoDetails?.collegeName || ""}Dashboard
          </h4>
        </Col>
        <Col xs={{ span: 20, offset: 2 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <div className="my-card">
                <img
                  width="50"
                  alt=""
                  src={
                    userInfo.profileImage
                      ? { uri: PHOTO_URL + userInfo.profileImage }
                      : userImage
                  }
                />
                <span className="text-bold fa-lg text-capitalize">
                  Hi! {userInfo.name}
                </span>
              </div>
            </Col>
          </Row>
          <Row className="py-5" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            {localresult && localresult?.isOldData ? (
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                <div className="my-card d-flex justify-content-between align-items-center py-5">
                  <div className="d-flex">
                    <img
                      className="mr-1"
                      style={{ width: "40px" }}
                      src={UploadDocumentIcon}
                      alt=""
                    />
                    <div>
                      <div className="text-semibold fa-lg">
                        Continue From where you left
                      </div>
                      <div className="mt-1">
                        <span className="text-bold text-grey-900">
                          Continue with existing form
                        </span>
                      </div>
                    </div>
                  </div>
                  <Button
                    onClick={() => {
                      const result = checkLocalStorage();
                      handleChange("isEdit", false);
                      if (result && result?.isOldData) loadPrevious(result);
                    }}
                    disabled={isFetchingJson}
                    type="primary"
                    className="btn protax-btn text-capitalize btn-round"
                    style={{ paddingRight: 10 }}
                  >
                    <i className="fa fa-chevron-right" />
                  </Button>
                </div>
              </Col>
            ) : null}

            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
              <div className="my-card d-flex justify-content-between align-items-center py-5">
                <div className="d-flex">
                  <img
                    className="mr-1"
                    style={{ width: "40px" }}
                    src={UploadDocumentIcon}
                    alt=""
                  />
                  <div>
                    <div className="text-semibold fa-lg">
                      Upload Tax Documents
                    </div>
                    <div className="mt-1">
                      <span className="text-bold text-grey-900">{year}</span>
                    </div>
                  </div>
                </div>
                <Button
                  onClick={() => {
                    handleChange("isEdit", false);
                    freshStart();
                  }}
                  disabled={isFetchingJson || isLoading}
                  type="primary"
                  className="btn protax-btn text-capitalize btn-round"
                  style={{ paddingRight: 10 }}
                >
                  {isFetchingJson || isLoading ? <Spin /> : <i className="fa fa-plus" />}
                </Button>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 0 }}
              lg={{ span: 8 }}
              className="mt-sm-1"
            >
              <div className="my-card  d-flex justify-content-between align-items-center py-5">
                <span className="text-semibold fa-lg">Tax Return Status</span>
                <Link to="/orders">
                  <Button
                    type="primary"
                    className="btn protax-btn  text-capitalize btn-round"
                  >
                    <i className="fa fa-eye" />
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="py-5" gutter={[24, 32]}>
            {orderInfo && Object.keys(orderInfo)?.length > 0 && !isLoading ? (
              <LatestTaxForm orderInfo={orderInfo} freshStart={resetBasicDetails} />
            ) : null}

            <IdProof orderInfo={orderInfo} getLatestOrder={getLatestOrder} />
            <Payment orderInfo={orderInfo} getLatestOrder={getLatestOrder} />
            <Appointment orderInfo={orderInfo} getLatestOrder={getLatestOrder} />

            {Object.keys(orderInfo).length > 0 && !isLoading ? (
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 8 }}
              >
                <div className="my-card py-5 h-100">
                  <div>
                    <span className="text-bold fa-lg">Tax Return Progress</span>
                  </div>
                  <div>
                    <span className="text-grey fa-md">
                      Tax Filling in Progress
                    </span>
                  </div>
                  <div className="percent-chart-container">
                    <div className="percent-chart">
                      <CircularProgressbar
                        styles={buildStyles({
                          pathColor: "#b0000B",
                          textColor: "#b0000B",
                        })}
                        value={+orderInfo.percentageCompleted}
                        text={`${+orderInfo.percentageCompleted}%`}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            ) : null}
            <Signature orderInfo={orderInfo} getLatestOrder={getLatestOrder} />
            {orderInfo?.finalDocumentTitle &&
            (orderInfo?.orderDocument || orderInfo?.spouseDocument) &&
            !isLoading ? (
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 8 }}
              >
                <div className="my-card py-5">
                  <div>
                    <span className="text-bold fa-lg text-capitalize">
                      {orderInfo?.finalDocumentTitle || "Client's Copy"}
                    </span>
                  </div>
                  <div>
                    <span className="text-grey fa-md">
                      Thank You, We have completed your Tax Return.
                    </span>
                  </div>
                  <div className="percent-chart-container">
                    <div className="percent-chart">
                      <img
                        className="percent-chart"
                        style={{ width: "140px" }}
                        src={documentIcon}
                        alt=""
                      />
                    </div>
                    <div className="text-right d-flex justify-content-between mt-2 btn_flex_direction_column">
                      {orderInfo?.orderDocument && (
                        <a
                          href={`${PHOTO_URL}${orderInfo?.orderDocument}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            type="primary"
                            className="btn protax-btn text-capitalize"
                          >
                            View Your Copy
                          </Button>
                        </a>
                      )}
                      {orderInfo?.fillingForSpouse &&
                        orderInfo?.spouseDocument && (
                          <a
                            href={`${PHOTO_URL}${orderInfo?.spouseDocument}`}
                            target="_blank"
                            rel="noreferrer"
                            className="btn_margin_top"
                          >
                            <Button
                              type="primary"
                              className="btn protax-btn text-capitalize"
                            >
                              View Spouse's Copy
                            </Button>
                          </a>
                        )}
                    </div>
                  </div>
                </div>
              </Col>
            ) : null}

            {isLoading ? (
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <OverviewLoader />
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
      <Prefill active={prefillActive} />
    </>
  );

  function checkLocalStorageWithoutSet() {
    let result = localStorage.getItem("_pickupState");
    if (result) {
      let obj = JSON.parse(result);
      if (
        obj &&
        obj?.isOldData &&
        obj?.timestampForPickupState &&
        checkDateRangeMonth(obj.timestampForPickupState)
      ) {
        return obj;
      }
    }
  }

  function checkLocalStorage() {
    let result = localStorage.getItem("_pickupState");
    if (result) {
      let obj = JSON.parse(result);
      if (
        obj &&
        obj?.isOldData &&
        obj?.timestampForPickupState &&
        checkDateRangeMonth(obj.timestampForPickupState)
      ) {
        handleMultipleChange({
          ...obj,
          isOldData: true,
        });
        return obj;
      }
    }
  }
  function askUserToLoadData(result) {
    confirm({
      title: previousAlert?.title,
      icon: <ExclamationCircleOutlined />,
      content: previousAlert?.description,
      okText: previousAlert?.newButtonTitle,
      cancelText: previousAlert?.oldButtonTitle,
      maskClosable: false,
      closeIcon: <CloseOutlined />,
      onOk() {
        return freshStart();
      },
      onCancel() {
        loadPrevious(result);
      },
    });
  }

  async function resetBasicDetails() {
    await fetchJson();
    orderDetailsReset();
  }
  async function freshStart() {
    await resetBasicDetails();
    if (orderInfo && Object.keys(orderInfo)?.length > 0) {
      setPrefillActive(true);
    } else {
      history.push("/tax_return");
    }
  }
  async function loadPrevious(result) {
    orderDetailsReset();
    history.push("/tax_return", { current: result?.currentFormWeb });
  }
}

const mapStateToProps = () => (state) => ({
  isOldData: state.Pickup.isOldData,
  previousAlert: state.Pickup.previousAlert,
  currentFormWeb: state.Pickup.currentFormWeb,
});
const mapDispatchToProps = () => (dispatch) => ({
  handleChange: (name, value) => dispatch(handleChange(name, value)),
  handleMultipleChange: (state) => dispatch(handleMultipleChange(state)),
  orderDetailsReset: () => dispatch(orderDetailsReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
